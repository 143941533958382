import ky from 'ky'
import io from 'socket.io-client'
import Config from 'react-native-config'
import NetInfo from '@react-native-community/netinfo'

import { Store } from 'src/state'
import { BookedSlot, Space } from 'src/state/models/space'
import { Nap } from 'src/state/models/nap'
import { User } from 'src/state/models/profile'

const socket = io(Config.REACT_APP_API_URL, {
  path: '/harmonyjs-socket',
})

const napSelection = `
      _id
      time { start, end }
      space
      cocoon { _id, room { _id } }
      finished
`

export const graphql = {
  subscribe(models: string[], callback: () => void | Promise<void>) {
    callback()

    models.forEach((m) => {
      socket.on(m + '-updated', callback)
    })

    const stopNetInfoListener = NetInfo.addEventListener((event) => {
      if (event.isConnected && event.isInternetReachable) {
        models.forEach((m) => {
          socket.off(m + '-updated', callback)
          socket.on(m + '-updated', callback)
        })
      } else {
        models.forEach((m) => {
          socket.off(m + '-updated', callback)
        })
      }
    })

    return () => {
      stopNetInfoListener()
      models.forEach((m) => {
        socket.off(m + '-updated', callback)
      })
    }
  },
  async query<T = {}>(query: string) {
    const token = Store.getState().utils.session.token

    const headers: Record<string, string> = {}

    if (token) {
      headers.Authorization = `Bearer ${token}`
    }

    const results = await ky
      .post(Config.REACT_APP_API_URL, {
        headers,
        json: {
          operationName: null,
          variables: {},
          query,
        },
        timeout: false,
      })
      .json<{ data: T; errors?: [Error] }>()

    if (results.errors) {
      throw results.errors[0]
    }

    return results.data
  },
  queries: {
    login(email: string, password: string) {
      return graphql.query<{
        userLogin: {
          error: 'user_not_found' | 'wrong_credentials' | null
          token: string | null
        }
      }>(`{
        userLogin(login: "${email}", password: "${password}") {
          error
          token
        }
      }`)
    },
    async resendSignupEmail(email: string) {
      return (
        await graphql.query<{ userResendSignupConfirmationEmail: boolean }>(`{
        userResendSignupConfirmationEmail(email: "${email}")
      }`)
      ).userResendSignupConfirmationEmail
    },
    async completeSignup(code: string) {
      return (
        await graphql.query<{ userCompleteSignup: boolean }>(`{
        userCompleteSignup(code: "${code}")
      }`)
      ).userCompleteSignup
    },
    async resetPassword(email: string) {
      return (
        await graphql.query<{
          userResetPassword: boolean
        }>(`{
        userResetPassword(email: "${email}")
      }`)
      ).userResetPassword
    },
    getSpaces() {
      return graphql.query<{
        user: { spaces: Space[]; spaceExpiration: Record<string, string> }
      }>(
        `{
            user {
              spaceExpiration
              spaces {
                _id, localizedName, description,
                rooms { _id, localizedName, localizedDescription },
                cocoons { _id, version, localizedName, localizedDescription, room { _id } },
                options {
                  napsPerDay,
                  napDurations { duration10, duration15, duration20, duration25 }
                  napDays { 
                    monday { startHour, endHour }
                    tuesday { startHour, endHour }
                    wednesday { startHour, endHour }
                    thursday { startHour, endHour }
                    friday { startHour, endHour }
                    saturday { startHour, endHour }
                    sunday { startHour, endHour }
                  }
                }
                subscription { 
                  start, end, premium,
                  disabled { audio, profile, booking }
                  audio {
                    themes { _id, slug, short, title, description, image, cover, situations { slug, title }, tracks }
                    books { _id, slug, category, language, title { short, long }, description, image, cover, chapters { _id, title, tracks }, backgrounds }
                    backgrounds { _id, slug, short, long, mode, image, tracks }
                  }
                }
              }
            }
          }`,
      )
    },
    getBookedSlots() {
      return graphql.query<{
        user: {
          spaces: {
            _id: string
            cocoons: { _id: string; optimizedBookedSlots: BookedSlot[] }[]
          }[]
        }
      }>(
        `{
            user {
              spaces {
                _id
                cocoons { _id, optimizedBookedSlots }
              }
            }
          }`,
      )
    },
    getNaps(timeLimit: string) {
      return graphql.query<{ napList: Nap[] }>(`{
        napList(filter: { _operators: { time: { match: { start: { gte: "${timeLimit}" } } } } }) {
          ${napSelection}
        }
      }`)
    },
    getProfile() {
      return graphql.query<{
        user: User
      }>(
        `{
            user {
              _id
              email
              level
              totalNaps
              totalMinutes
            }
          }`,
      )
    },
  },
  mutations: {
    signup(email: string, password: string, accessCode: string) {
      return graphql.query<{
        userCreate: {
          _id: string
        }
      }>(`mutation {
        userCreate(record: { email: "${email}", password: "${password}", initialSpaceCode: "${accessCode}" }) {
          _id
        }
      }`)
    },
    async deleteAccount(password: string) {
      return (
        await graphql.query<{ userDelete: boolean }>(`mutation {
        userDelete(password: "${password}")
      }`)
      ).userDelete
    },
    async setNewPassword(code: string, password: string) {
      return (
        await graphql.query<{ userSetNewPassword: boolean }>(`mutation {
        userSetNewPassword(code: "${code}", password: "${password}")
      }`)
      ).userSetNewPassword
    },
    async selectSpace(space: string) {
      return (
        await graphql.query<{ userSetSelectedSpace: string }>(`mutation {
        userSetSelectedSpace(space: "${space}")
      }`)
      ).userSetSelectedSpace
    },
    async joinSpace(code: string) {
      return (
        await graphql.query<{
          userAddSpace: null | {
            name: string
          }
        }>(`mutation {
        userAddSpace(code: "${code}") { name }
      }`)
      ).userAddSpace
    },
    leaveSpace(space: string) {
      return graphql.query(`mutation { userQuitSpace(space: "${space}") }`)
    },
    bookNap(
      time: { start: string; end: string },
      cocoon: string,
      nap: string | null = null,
    ) {
      return graphql.query<{ book: Nap }>(`mutation {
        book(
          time: { start: "${time.start}", end: "${time.end}" }
          cocoon: "${cocoon}"
          space: "${Store.getState().utils.session.claims.space}"
          _id: ${nap ? `"${nap}"` : 'null'}
        ) { 
          ${napSelection}
        }
      }`)
    },
    cancelNap(napId: string) {
      return graphql.query<{ napDelete: { _id: string } }>(`mutation {
        napDelete(_id: "${napId}") { _id }
      }`)
    },
    finishNap(napId: string) {
      return graphql.query<{ napUpdate: { _id: string } }>(`mutation {
        napUpdate(record: { _id: "${napId}", finished: true }) { _id }
      }`)
    },
  },
}
