import React from 'react'
import { StatusBar } from 'react-native'

import { buildComponent } from 'src/components/factory'
import { useStoreState } from 'src/hooks/state'

import * as UI from 'native-base'

export const StatusBarSync = buildComponent<{ theme: 'day' | 'night' }>()
  .withLifecycle(({ props }) => {
    // Trigger a re-render on onboarded flag to reset the status-bar color
    useStoreState(
      (store) =>
        store.profile.preferences &&
        store.profile.preferences?.onboarded !== true,
    )

    const statusBarColor = {
      day: '#98a7cb',
      night: '#1a224d',
    }[props.theme]

    return {
      statusBarColor,
    }
  })
  .withRender(({ lifecycle }) => (
    <UI.View backgroundColor={lifecycle.statusBarColor}>
      <StatusBar
        key={lifecycle.statusBarColor}
        barStyle="light-content"
        backgroundColor={lifecycle.statusBarColor}
      />
    </UI.View>
  ))
