import React, { useEffect, useState } from 'react'
import { Platform } from 'react-native'
import { RouteProp, useRoute } from '@react-navigation/native'

import { buildComponent } from 'src/components/factory'

import { StoreDownload } from 'src/screens/connected/store-download/store-download'

import * as UI from 'native-base'
import * as Components from './components'

export function withLayout(
  Screen: React.ComponentType<any>,
  {
    theme = 'day',
    tabBar = false,
    topBar = true,
    connected = true,
  }: {
    theme?: 'night' | 'day'
    tabBar?: boolean
    topBar?: boolean
    connected?: boolean
  } = {},
) {
  return buildComponent()
    .withLifecycle(() => {
      const [loading, setLoading] = useState(!tabBar)
      const route = useRoute<RouteProp<{ route: { m?: null } }, 'route'>>()
      const showStoreDownload = route.params?.m !== undefined

      useEffect(() => {
        setTimeout(() => setLoading(false), 0)
      }, [])

      return { loading, showStoreDownload }
    })
    .withRender(({ props, lifecycle }) => {
      const contentFragment = (
        <>
          {topBar && <Components.TopBar theme={theme} />}
          {connected && <Components.NoSpaceDetection />}
          {connected && <Components.ExpiredSpaceDetection />}

          {lifecycle.loading && (
            <UI.Box
              width="100%"
              height="66%"
              alignItems="center"
              justifyContent="center"
            >
              <UI.Spinner
                size={32}
                color={theme === 'day' ? 'secondary.500' : 'primary.500'}
              />
            </UI.Box>
          )}
          {!lifecycle.loading && <Screen {...props} />}
        </>
      )

      return (
        <>
          <Components.StatusBarSync theme={theme} />
          {Platform.OS === 'ios' && <UI.Box safeAreaTop />}
          <Components.Background
            theme={theme}
            tabBar={tabBar && !lifecycle.showStoreDownload}
            topBar={topBar && !lifecycle.showStoreDownload}
          />

          {lifecycle.showStoreDownload && <StoreDownload theme={theme} />}
          {!lifecycle.showStoreDownload && contentFragment}
        </>
      )
    })
}
