import React from 'react'

import { colors, fonts } from 'src/theme'

import downloadAndroidFr from 'src/resources/images/download-android-fr.png'
import downloadAndroidEn from 'src/resources/images/download-android-en.png'
import downloadAppleFr from 'src/resources/images/download-apple-fr.png'
import downloadAppleEn from 'src/resources/images/download-apple-en.png'

import { buildComponent } from 'src/components/factory'

import * as UI from 'native-base'
import * as Semantics from 'src/components/semantics'

const downloadAndroid = {
  fr: downloadAndroidFr,
  en: downloadAndroidEn,
}

const downloadApple = {
  fr: downloadAppleFr,
  en: downloadAppleEn,
}

export const StoreDownload = buildComponent<{
  theme: 'day' | 'night'
  audio?: boolean
  continue?: () => void
}>()
  .withLocale('screens.connected.store-download.screen')
  .withLifecycle(() => ({
    downloadAndroid: Semantics.Localized.localized(downloadAndroid) as number,
    downloadApple: Semantics.Localized.localized(downloadApple) as number,
  }))
  .withRender(({ props, lifecycle, locale }) => (
    <UI.ScrollView>
      <UI.Text
        textAlign="center"
        fontSize={28}
        style={fonts.bold}
        color={props.theme === 'day' ? colors.dark['700'] : 'white'}
        mt={16}
        mb={8}
        mx={4}
      >
        {locale('title')}
      </UI.Text>

      <Semantics.Card marginX={4}>
        <UI.Text textAlign="center" my={4}>
          {props.audio ? locale('audio') : locale('main')} {locale('download')}
        </UI.Text>
        <UI.Link href="https://play.google.com/store/apps/details?id=com.napandup.mobile">
          <UI.Box mx="auto" my={2}>
            <UI.Button variant="ghost" p={0}>
              <UI.Image
                height={76}
                width={220}
                resizeMode="contain"
                resizeMethod="resize"
                source={lifecycle.downloadAndroid}
              />
            </UI.Button>
          </UI.Box>
        </UI.Link>
        <UI.Link href="https://apps.apple.com/app/id1490471518">
          <UI.Box mx="auto" my={2}>
            <UI.Button variant="ghost" p={0}>
              <UI.Image
                height={76}
                width={220}
                resizeMode="contain"
                resizeMethod="resize"
                source={lifecycle.downloadApple}
              />
            </UI.Button>
          </UI.Box>
        </UI.Link>
        <UI.Box mx="auto" my={4}>
          {props.continue ? (
            <Semantics.Button onPress={props.continue} px={8}>
              {locale('continueOnWeb')}
            </Semantics.Button>
          ) : (
            <Semantics.LinkButton popToTop px={8}>
              {locale('continueOnWeb')}
            </Semantics.LinkButton>
          )}
        </UI.Box>
      </Semantics.Card>
    </UI.ScrollView>
  ))
