import React, { MutableRefObject, useEffect, useState } from 'react'
import { Platform } from 'react-native'

import MusicControl, { Command } from 'react-native-music-control'
import Player from 'react-native-video'

import { PlayerState } from 'src/screens/connected/player/state'
import { buildComponent } from 'src/components/factory'

import * as Elements from 'src/components/elements'
import AsyncStorage from '@react-native-async-storage/async-storage'

export const AudioPlayer = buildComponent<{
  main: string
  background: string
  cover: string
  title?: string
  album?: string
}>()
  .withStyles({
    player: {
      main: {
        display: 'none',
      },
    },
  })
  .withLifecycle(({ props }) => {
    const Store = PlayerState.useStore()
    const seeked = PlayerState.useStoreState((store) => store.seeked)
    const paused = PlayerState.useStoreState((store) => store.paused)
    const currentTime = PlayerState.useStoreState((store) => store.currentTime)
    const totalTime = PlayerState.useStoreState((store) => store.totalTime)
    const backgroundVolume = PlayerState.useStoreState(
      (store) => store.backgroundVolume,
    )

    const [coverImage, setCoverImage] = useState('')

    const started = currentTime > 0
    useEffect(() => {
      if (!started) {
        return
      }

      MusicControl.enableControl('play', true)
      MusicControl.enableControl('pause', true)
      MusicControl.enableControl('closeNotification', true, { when: 'paused' })
      MusicControl.enableControl('seek', true)
      MusicControl.enableControl('changePlaybackPosition', true)
      MusicControl.enableControl('setRating', false)
      MusicControl.enableControl('stop', false)
      MusicControl.enableControl('skipForward', false)
      MusicControl.enableControl('skipBackward', false)

      MusicControl.setNowPlaying({
        title: props.title,
        artist: props.album,
        album: 'Nap&Up',
        duration: totalTime,
        artwork: coverImage.includes('://')
          ? coverImage
          : 'file://' + coverImage,
        colorized: true,
      })
    }, [started, props.title, props.album, totalTime, coverImage])

    useEffect(() => {
      if (!started) {
        return
      }

      MusicControl.updatePlayback({
        elapsedTime: currentTime,
        state: paused ? MusicControl.STATE_PAUSED : MusicControl.STATE_PLAYING,
        speed: paused ? 0 : 1,
      })
      // We specifically don't want currentTime to trigger the effect here, only paused state,
      // but we still need to provide currentTime to updatePlayback
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [started, paused])

    useEffect(() => {
      Elements.Image.preloadImage(props.cover).then(setCoverImage)
    }, [props.cover])

    useEffect(() => {
      MusicControl.enableBackgroundMode(true)
      if (Platform.OS === 'ios') {
        MusicControl.handleAudioInterruptions(true)
      }

      MusicControl.on(Command.play, () => {
        Store.getActions().setPaused(false)
      })
      MusicControl.on(Command.pause, () => {
        Store.getActions().setPaused(true)
      })
      MusicControl.on(Command.togglePlayPause, () => {
        Store.getActions().toggle(undefined)
      })
      MusicControl.on(Command.seek, (value: number) => {
        MusicControl.updatePlayback({
          elapsedTime: value,
        })

        Store.getActions().seek(value)
      })
      MusicControl.on(Command.changePlaybackPosition, (value: string) => {
        Store.getActions().seek(Number(value))
      })

      return () => {
        MusicControl.enableBackgroundMode(false)
        MusicControl.resetNowPlaying()
      }
    }, [Store])

    useEffect(() => {
      AsyncStorage.getItem('backgroundVolume')
        .then((v) => v && Store.getActions().setBackgroundVolume(+v))
        .catch(() => {})
    }, [Store])

    return {
      paused,
      currentTime,
      totalTime,
      backgroundVolume,
      setCurrentTime: (value: number) => {
        if (seeked) {
          Store.getActions().setSeeked(false)
        } else {
          Store.getActions().setCurrentTime(value)
        }
      },
      setTotalTime: Store.getActions().setTotalTime,
      setMainPlayer: Store.getActions().setMainPlayer,
      setBackgroundPlayer: Store.getActions().setBackgroundPlayer,
      toggle: Store.getActions().toggle,
      stop: () => {
        Store.getActions().setCurrentTime(0)
        Store.getActions().setPaused(true)
        MusicControl.resetNowPlaying()
      },
    }
  })
  .withRender(({ props, lifecycle, styles }) => (
    <>
      <Player
        audioOnly
        ref={lifecycle.setMainPlayer as unknown as MutableRefObject<Player>}
        source={{ uri: props.main }}
        paused={lifecycle.paused}
        playInBackground
        ignoreSilentSwitch="ignore"
        playWhenInactive
        style={styles.player()}
        onProgress={(data) => {
          lifecycle.setCurrentTime(data.currentTime)
          lifecycle.setTotalTime(data.seekableDuration)
        }}
        onEnd={lifecycle.stop}
      />
      <Player
        audioOnly
        ref={
          lifecycle.setBackgroundPlayer as unknown as MutableRefObject<Player>
        }
        paused={lifecycle.paused}
        source={{ uri: props.background }}
        playInBackground
        ignoreSilentSwitch="ignore"
        playWhenInactive
        style={styles.player()}
        volume={lifecycle.backgroundVolume}
      />
    </>
  ))
