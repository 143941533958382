import { useEffect, useState } from 'react'
import * as DateFn from 'date-fns'

import { Computed, Set, Thunk } from 'src/state/types'
import { BookedSlot, Cocoon, Room, Space } from 'src/state/models/space'
import { Nap } from 'src/state/models/nap'

import { computed, createContextStore, thunk } from 'easy-peasy'
import { set } from 'src/state/utils'
import { graphql } from 'src/services/graphql'

import { useNowListener } from 'src/hooks/use-now'
import { useStoreState } from 'src/hooks/state'

import * as Book from 'src/helpers/book'

const emptyArray: [] = []
export const slots = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55] as const

interface BookStateModel {
  // State
  constraintNow: Date
  constraintNapDurations?: NonNullable<Space['options']>['napDurations']
  constraintNapDays?: NonNullable<Space['options']>['napDays']
  constraintNapsPerDay?: NonNullable<Space['options']>['napsPerDay']
  constraintBookedSlots?: Record<string, BookedSlot[]>
  constraintRooms: Space['rooms']
  constraintCocoons: Space['cocoons']
  constraintNapId?: string

  room: string | null
  cocoon: string | null
  duration: 10 | 15 | 20 | 25
  slot: Date
  seekToAvailableSlot: boolean
  isBooking: boolean
  error: '' | 'alreadyBooked' | 'generic'

  // Computed
  combinedBookedSlots: Computed<this, Record<string, BookedSlot[]>>
  durations: Computed<this, (10 | 15 | 20 | 25)[]>

  slotDayString: Computed<this, string>
  slotDay: Computed<this, Date>
  slotHour: Computed<this, number>
  cocoons: Computed<this, Space['cocoons']>
  rooms: Computed<this, Space['rooms']>
  hoursInfo: Computed<this, { startHour: Date; endHour: Date; hours: number[] }>
  autoCocoon: Computed<this, string | undefined>
  bookableCocoons: Computed<this, string[]>

  isDayValid: Computed<this, (day: Date) => boolean>

  slotFreeParametersFirst: Computed<
    this,
    {
      now: Date
      slot: Date
      duration: 10 | 15 | 20 | 25
    }
  >
  slotFreeParametersSecond: Computed<
    this,
    {
      isDayValid: (d: Date) => boolean
      hoursInfo: { startHour: Date; endHour: Date; hours: number[] }
      combinedBookedSlots: Record<string, BookedSlot[]>
      bookableCocoons: string[]
    }
  >

  isSlotFree: Computed<this, (minutes: number) => boolean>
  isSlotPickerFree: Computed<this, (minutes: number) => boolean>
  isSpecificSlotFree: Computed<this, (slot: Date) => boolean>
  slotsFree: Computed<this, boolean[]>
  noSlotAvailable: Computed<this, boolean>
  hourSlots: Computed<
    this,
    Record<typeof slots[number], 'active' | 'free' | 'taken'>
  >

  showLocation: Computed<this, boolean>
  canBook: Computed<this, boolean>

  // Actions
  setConstraintNow: Set<this, 'constraintNow'>
  setConstraintNapDurations: Set<this, 'constraintNapDurations'>
  setConstraintNapDays: Set<this, 'constraintNapDays'>
  setConstraintNapsPerDay: Set<this, 'constraintNapsPerDay'>
  setConstraintBookedSlots: Set<this, 'constraintBookedSlots'>
  setConstraintRooms: Set<this, 'constraintRooms'>
  setConstraintCocoons: Set<this, 'constraintCocoons'>
  setConstraintNapId: Set<this, 'constraintNapId'>
  setRoom: Set<this, 'room'>
  setCocoon: Set<this, 'cocoon'>
  setDuration: Set<this, 'duration'>
  setSlot: Set<this, 'slot'>
  setSeekToAvailableSlot: Set<this, 'seekToAvailableSlot'>
  setIsBooking: Set<this, 'isBooking'>
  setError: Set<this, 'error'>

  // Thunks
  updateSlot: Thunk<this, Date | ((date: Date) => Date)>
  updateSlotHour: Thunk<this, number>
  updateSlotMinutes: Thunk<this, number>
  updateDuration: Thunk<this, this['duration']>
  updateRoom: Thunk<this, Room | null>
  updateCocoon: Thunk<this, Cocoon | null>
  clearError: Thunk<this>
  goToNextSlot: Thunk<this>
  book: Thunk<this, never, Promise<Nap | null>>
}

export const BookState = createContextStore<BookStateModel>({
  // State
  constraintNow: new Date(),
  constraintRooms: [],
  constraintCocoons: [],
  room: null,
  cocoon: null,
  duration: 10,
  slot: Book.roundUpToFiveMinutes(new Date()),
  seekToAvailableSlot: false,
  isBooking: false,
  error: '',

  // Computed
  combinedBookedSlots: computed(
    [(store) => store.constraintBookedSlots, (store) => store.constraintNapId],
    (bookedSlots, napId) => Book.combineBookedSlots(bookedSlots || {}, napId),
  ),
  durations: computed(
    [(store) => store.constraintNapDurations],
    (napDurations) =>
      Book.durations.filter(
        (d) => !napDurations || !!napDurations[`duration${d}` as const],
      ),
  ),

  slotDayString: computed([(store) => store.slot], (slot) =>
    DateFn.startOfDay(slot).toISOString(),
  ),
  slotDay: computed(
    [(store) => store.slotDayString],
    (slotDayString) => new Date(slotDayString),
  ),
  slotHour: computed([(store) => store.slot], (slot) => slot.getHours()),
  cocoons: computed(
    [(store) => store.constraintCocoons, (store) => store.room],
    (cocoons, room) => cocoons.filter((c) => !room || c.room._id === room),
  ),
  rooms: computed([(store) => store.constraintRooms], (rooms) => rooms),
  hoursInfo: computed(
    [(store) => store.constraintNapDays, (store) => store.slotDay],
    (napDays, slotDay) => Book.getHoursForDay(napDays, slotDay),
  ),
  autoCocoon: computed(
    [
      (store) => store.cocoon,
      (store) => store.cocoons,
      (store) => store.slot,
      (store) => store.duration,
      (store) => store.combinedBookedSlots,
    ],
    (cocoon, cocoons, slot, duration, combinedBookedSlots) => {
      if (cocoon && cocoons.find((c) => c._id === cocoon)) {
        return cocoon
      }

      const start = slot.toISOString()
      const end = DateFn.addMinutes(slot, duration).toISOString()

      const candidates = cocoons.filter((c) => {
        const cocoonSlots = combinedBookedSlots[c._id]

        return !cocoonSlots?.some(
          (booked) => booked.start < end && booked.end > start,
        )
      })

      const index = Math.floor(Math.random() * candidates.length)

      return candidates[index]?._id
    },
  ),
  bookableCocoons: computed(
    [(store) => store.cocoon, (store) => store.cocoons],
    (cocoon, cocoons) => (cocoon ? [cocoon] : cocoons.map((c) => c._id)),
  ),

  isDayValid: computed(
    [
      (store) => store.constraintNapDays,
      (store) => store.constraintNapsPerDay ?? 0,
      (store) => store.cocoons,
      (store) => store.constraintBookedSlots ?? {},
      (store) => store.combinedBookedSlots,
      (store) => store.bookableCocoons,
    ],
    (
        napDays,
        napsPerDay,
        cocoons,
        bookedSlots,
        combinedBookedSlots,
        bookableCocoons,
      ) =>
      (day) => {
        const dayHours = Book.getHoursForDay(napDays, day)

        if (dayHours.hours.length < 1) {
          return false
        }

        // Check if the day already contains N naps
        const start = DateFn.startOfDay(day).toISOString()
        const end = DateFn.endOfDay(day).toISOString()

        if (napsPerDay > 0) {
          const nbNaps = cocoons
            .map((c) => bookedSlots[c._id])
            .filter((b) => !!b)
            .flatMap((b) => b.filter((s) => s.nap !== '-'))
            .filter(Book.overlaps({ start, end }))
            .map((b) => b.nap)
            .filter((e, i, a) => a.indexOf(e) === i).length

          if (nbNaps >= napsPerDay) {
            return false
          }
        }

        // Check if the day is fully booked
        return bookableCocoons.every(
          (c) =>
            !combinedBookedSlots[c] ||
            !combinedBookedSlots[c].some(
              (booked) => booked.start <= start && booked.end >= end,
            ),
        )
      },
  ),
  slotFreeParametersFirst: computed(
    [
      (store) => store.constraintNow,
      (store) => store.slot,
      (store) => store.duration,
    ],
    (now, slot, duration) => ({ now, slot, duration }),
  ),
  slotFreeParametersSecond: computed(
    [
      (store) => store.isDayValid,
      (store) => store.hoursInfo,
      (store) => store.combinedBookedSlots,
      (store) => store.bookableCocoons,
    ],
    (isDayValid, hoursInfo, combinedBookedSlots, bookableCocoons) => ({
      isDayValid,
      hoursInfo,
      combinedBookedSlots,
      bookableCocoons,
    }),
  ),
  isSlotFree: computed(
    [
      (store) => store.slotFreeParametersFirst,
      (store) => store.slotFreeParametersSecond,
    ],
    (
        { now, slot, duration },
        { isDayValid, hoursInfo, combinedBookedSlots, bookableCocoons },
      ) =>
      (minutes) => {
        const candidateStart = DateFn.setMinutes(slot, minutes)
        const candidateEnd = DateFn.addMinutes(candidateStart, duration)

        return Book.checkCandidateSlot(
          now,
          candidateStart,
          candidateEnd,
          hoursInfo,
          combinedBookedSlots,
          bookableCocoons,
          isDayValid,
        )
      },
  ),
  isSlotPickerFree: computed(
    [(store) => store.slotsFree],
    (slotsFree) => (minutes: number) => slotsFree[Math.floor(minutes / 5)],
  ),
  isSpecificSlotFree: computed(
    [
      (store) => store.constraintNow,
      (store) => store.isDayValid,
      (store) => store.constraintNapDays,
      (store) => store.combinedBookedSlots,
      (store) => store.bookableCocoons,
      (store) => store.duration,
    ],
    (
      now,
      isDayValid,
      napDays,
      combinedBookedSlots,
      bookableCocoons,
      duration,
    ) => {
      return (slot) => {
        const candidateStart = slot
        const candidateEnd = DateFn.addMinutes(candidateStart, duration)
        const hoursInfo = Book.getHoursForDay(napDays, slot)

        return Book.checkCandidateSlot(
          now,
          candidateStart,
          candidateEnd,
          hoursInfo,
          combinedBookedSlots,
          bookableCocoons,
          isDayValid,
        )
      }
    },
  ),

  slotsFree: computed([(store) => store.isSlotFree], (isSlotFree) =>
    slots.map((minutes) => isSlotFree(minutes)),
  ),
  noSlotAvailable: computed([(store) => store.slotsFree], (slotsFree) =>
    slotsFree.every((s) => !s),
  ),
  hourSlots: computed(
    [(store) => store.slot, (store) => store.isSlotPickerFree],
    (slot, isSlotPickerFree) =>
      Object.fromEntries(
        slots.map((minutes) => {
          if (!isSlotPickerFree(minutes)) {
            return [minutes, 'taken']
          }

          if (slot.getMinutes() === minutes) {
            return [minutes, 'active']
          }

          return [minutes, 'free']
        }),
      ) as Record<typeof slots[number], 'active' | 'taken' | 'free'>,
  ),

  showLocation: computed(
    [(store) => store.rooms, (store) => store.cocoons],
    (rooms, cocoons) => rooms.length > 1 || cocoons.length > 1,
  ),
  canBook: computed(
    [
      (store) => store.isBooking,
      (store) => store.autoCocoon,
      (store) => store.isSlotFree,
      (store) => store.slot,
    ],
    (isBooking, autoCocoon, isSlotFree, slot) =>
      !isBooking && !!autoCocoon && isSlotFree(new Date(slot).getMinutes()),
  ),

  // Actions
  setConstraintNow: set('constraintNow'),
  setConstraintBookedSlots: set('constraintBookedSlots'),
  setConstraintNapDays: set('constraintNapDays'),
  setConstraintNapDurations: set('constraintNapDurations'),
  setConstraintNapsPerDay: set('constraintNapsPerDay'),
  setConstraintCocoons: set('constraintCocoons'),
  setConstraintRooms: set('constraintRooms'),
  setConstraintNapId: set('constraintNapId'),

  setRoom: set('room'),
  setCocoon: set('cocoon'),
  setDuration: set('duration'),
  setSlot: set('slot'),
  setSeekToAvailableSlot: set('seekToAvailableSlot'),
  setIsBooking: set('isBooking'),
  setError: set('error'),

  // Thunks
  updateSlot: thunk((actions, updater, helpers) => {
    const slot = helpers.getState().slot
    if (typeof updater === 'function') {
      actions.setSlot(updater(slot))
    } else {
      actions.setSlot(updater)
    }
  }),
  updateSlotHour: thunk((actions, hour) =>
    actions.updateSlot((s) => DateFn.setHours(s, hour)),
  ),
  updateSlotMinutes: thunk((actions, minutes) =>
    actions.updateSlot((s) => DateFn.setMinutes(s, minutes)),
  ),
  updateDuration: thunk((actions, duration) => actions.setDuration(duration)),
  updateRoom: thunk((actions, room) => {
    requestAnimationFrame(() => actions.setRoom(room?._id ?? null))
  }),
  updateCocoon: thunk((actions, cocoon) => {
    requestAnimationFrame(() => actions.setCocoon(cocoon?._id ?? null))
  }),
  clearError: thunk((actions) => actions.setError('')),
  goToNextSlot: thunk((actions, _, helpers) => {
    const {
      slot,
      duration,
      combinedBookedSlots,
      bookableCocoons,
      constraintNapDays,
      isSpecificSlotFree,
    } = helpers.getState()

    let candidate = slot

    // Stop searching after 2.5s
    const start = Date.now()
    while (!isSpecificSlotFree(candidate) && Date.now() - start < 2500) {
      const { startHour, endHour } = Book.getHoursForDay(
        constraintNapDays,
        candidate,
      )

      // Find a booked slot blocking our current selection
      const bookedSlot = Book.overlappingSlot(
        [candidate, DateFn.addMinutes(candidate, duration)],
        combinedBookedSlots,
        bookableCocoons,
      )

      if (bookedSlot) {
        candidate = Book.roundUpToFiveMinutes(new Date(bookedSlot.end))
      } else if (DateFn.isBefore(candidate, startHour)) {
        candidate = DateFn.startOfHour(
          DateFn.setHours(candidate, startHour.getHours()),
        )
      } else if (DateFn.isBefore(candidate, endHour)) {
        candidate = DateFn.startOfHour(DateFn.addHours(candidate, 1))
      } else {
        candidate = DateFn.startOfDay(DateFn.addDays(candidate, 1))
      }
    }

    actions.setSlot(candidate)
  }),
  book: thunk(async (actions, _, helpers) => {
    const autoCocoon = helpers.getState().autoCocoon
    const slot = helpers.getState().slot
    const duration = helpers.getState().duration
    const napId = helpers.getState().constraintNapId

    if (!autoCocoon) {
      return null
    }

    actions.setIsBooking(true)

    const start = slot.toISOString()
    const end = DateFn.addMinutes(slot, duration).toISOString()

    return graphql.mutations
      .bookNap({ start, end }, autoCocoon, napId)
      .then((results) => {
        return results.book
      })
      .catch((err) => {
        if (err?.message?.includes('Slot already booked')) {
          actions.setError('alreadyBooked')
        } else {
          actions.setError('generic')
        }

        return null
      })
      .finally(() => {
        actions.setIsBooking(false)
      })
  }),
})

function useConstraint<T>(action: (value: T) => void, value: T) {
  useEffect(() => {
    action(value)
  }, [action, value])
}

export const useStoreSync = (napId?: string) => {
  const napDurations = useStoreState(
    (store) => store.space.info?.options?.napDurations,
  )
  const napDays = useStoreState((store) => store.space.info?.options?.napDays)
  const napsPerDay = useStoreState(
    (store) => store.space.info?.options?.napsPerDay ?? -1,
  )
  const bookedSlots = useStoreState((store) => store.space.bookedSlots)
  const allRooms = useStoreState(
    (store) => store.space.info?.rooms ?? emptyArray,
  )
  const allCocoons = useStoreState(
    (store) => store.space.info?.cocoons ?? emptyArray,
  )

  const [_nap, setNap] = useState<Nap | null>(null)
  const nap = useStoreState(
    (store) => store.nap.list.find((n) => n._id === _nap?._id) ?? _nap,
  )

  const actions = BookState.useStore().getActions()

  useNowListener(actions.setConstraintNow)
  useConstraint(actions.setConstraintNapDurations, napDurations)
  useConstraint(actions.setConstraintNapDays, napDays)
  useConstraint(actions.setConstraintNapsPerDay, napsPerDay)
  useConstraint(actions.setConstraintBookedSlots, bookedSlots)
  useConstraint(actions.setConstraintRooms, allRooms)
  useConstraint(actions.setConstraintCocoons, allCocoons)
  useConstraint(actions.setConstraintNapId, napId)

  return {
    actions,
    nap,
    setNap,
  }
}
