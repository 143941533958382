import { Set } from 'src/state/types'
import { set } from 'src/state/utils'

interface ModelDynamicContent {
  // State
  legalNotices: null | {
    fr: string
    en: string
  }
  privacyPolicy: null | {
    fr: string
    en: string
  }
  faq: null | {
    fr: { question: string; answer: string }[]
    en: { question: string; answer: string }[]
  }

  // Actions
  setLegalNotices: Set<this, 'legalNotices'>
  setPrivacyPolicy: Set<this, 'privacyPolicy'>
  setFaq: Set<this, 'faq'>
}

export const modelDynamicContent: ModelDynamicContent = {
  // State
  legalNotices: null,
  privacyPolicy: null,
  faq: null,

  // Actions
  setLegalNotices: set('legalNotices'),
  setPrivacyPolicy: set('privacyPolicy'),
  setFaq: set('faq'),
}
