import {
  getDay,
  getMilliseconds,
  getMinutes,
  getSeconds,
  isAfter,
  isBefore,
  setHours,
  setMinutes,
  startOfMinute,
} from 'date-fns'
import { BookedSlot, Space } from 'src/state/models/space'

export const durations = [10, 15, 20, 25] as const

const dayMap = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
] as const

/**
 * Take a date and round it up to the next multiple of five minutes,
 * which coincides with a slot candidate
 * @param date - the date to round up to
 * @return - the rounded-up date
 */
export function roundUpToFiveMinutes(date: Date) {
  const milliseconds = getMilliseconds(date)
  const seconds = getSeconds(date) + milliseconds / 1000
  const minutes = getMinutes(date) + seconds / 60

  return startOfMinute(setMinutes(date, Math.ceil(minutes / 5) * 5))
}

/**
 * Curried function for checking overlap between two slots
 * @param a - first slot to compare, expressed as ISO strings
 * @return - comparison function taking the second slot to compare, returning if they overlap
 */
export function overlaps(a: { start: string; end: string }) {
  return function (b: { start: string; end: string }) {
    return a.start < b.end && a.end > b.start
  }
}

/**
 * Extract the start and end opening hours for a given date
 * @param napDays - the opening hours definition
 * @param date - the date to check
 * @return - start and end opening hours, and list of opened hours
 */
export function getHoursForDay(
  napDays: NonNullable<Space['options']>['napDays'] | undefined,
  date: Date,
) {
  const info = napDays?.[dayMap[getDay(date)]]

  const startHour = Math.floor(info?.startHour ?? 0)
  const endHour = Math.ceil(info?.endHour ?? 0)
  const startMinutes = ((info?.startHour ?? 0) * 60) % 60
  const endMinutes = ((info?.endHour ?? 0) * 60) % 60

  return {
    startHour: startOfMinute(
      setHours(setMinutes(date, startMinutes), info?.startHour ?? 0),
    ),
    endHour: startOfMinute(
      setHours(setMinutes(date, endMinutes), info?.endHour ?? 0),
    ),
    hours: [...new Array(endHour - startHour)]
      .map((_, i) => startHour + i)
      .filter((h) =>
        isAfter(
          setHours(setMinutes(date, 0), h + 1),
          roundUpToFiveMinutes(new Date()),
        ),
      ),
  }
}

/**
 * Combine booked slots, filtering out the currently edited nap if any
 * @param bookedSlots - record of booked slots by cocoon
 * @param napId - potential in-edition nap
 */
export function combineBookedSlots(
  bookedSlots: Record<string, BookedSlot[]>,
  napId: string | undefined,
) {
  const lowest = (a: string, b: string) => (a < b ? a : b)
  const greatest = (b: string, a: string) => (a < b ? a : b)

  return Object.fromEntries(
    Object.entries(bookedSlots).map(([cocoon, cocoonSlots]) => {
      const combinedSlots: { start: string; end: string }[] = []

      cocoonSlots
        // Filter out slots related to the currently edited nap
        .filter((slot) => !napId || slot.nap !== napId)
        // Sort slots in ascending time order
        .sort((a, b) => {
          return a.start.localeCompare(b.start) || a.end.localeCompare(b.end)
        })
        // Combine overlapping slots together
        .forEach((slot) => {
          const combinedSlot = combinedSlots[combinedSlots.length - 1]

          if (combinedSlot && overlaps(combinedSlot)(slot)) {
            combinedSlot.start = lowest(combinedSlot.start, slot.start)
            combinedSlot.end = greatest(combinedSlot.end, slot.end)
          } else {
            combinedSlots.push({
              start: slot.start,
              end: slot.end,
            })
          }
        })

      return [cocoon, combinedSlots]
    }),
  )
}

/**
 * Get, for a given list of cocoons, the first booked slot overlapping
 * a candidate slot
 * @param slot - candidate slot to check against
 * @param bookedSlots - record of booked slots
 * @param cocoons - list of cocoons
 */
export function overlappingSlot(
  slot: [Date, Date],
  bookedSlots: Record<string, BookedSlot[]>,
  cocoons: string[],
) {
  const start = slot[0].toISOString()
  const end = slot[1].toISOString()

  const findOverlaps = overlaps({ start, end })

  const slots = cocoons
    .map((id) => bookedSlots[id] ?? [])
    .map((cocoonSlots) => cocoonSlots.find(findOverlaps))

  if (slots.every((s) => !!s)) {
    return slots[0]
  }

  return null
}

/**
 * Check, for a given slot and list of cocoons, if the candidate slot is free
 * @param slot - candidate slot to check
 * @param bookedSlots - record of booked slots
 * @param cocoons - list of cocoons
 */
export function isSlotFree(
  slot: [Date, Date],
  bookedSlots: Record<string, BookedSlot[]>,
  cocoons: string[],
) {
  return !overlappingSlot(slot, bookedSlots, cocoons)
}

/**
 * Check if a candidate slot is completely free, according
 * to all known constraints
 * @param now - the current date
 * @param candidateStart - the candidate slot start point
 * @param candidateEnd - the candidate slot end point
 * @param hoursInfo - information about the day's opening hours
 * @param combinedBookedSlots - slot information for the cocoons
 * @param bookableCocoons - list of cocoons that can be booked
 * @param isDayValid - helper function to see if a day is already fully booked
 */
export function checkCandidateSlot(
  now: Date,
  candidateStart: Date,
  candidateEnd: Date,
  hoursInfo: { hours: number[]; startHour: Date; endHour: Date },
  combinedBookedSlots: Record<string, BookedSlot[]>,
  bookableCocoons: string[],
  isDayValid: (day: Date) => boolean,
) {
  if (!isDayValid(candidateStart)) {
    return false
  }

  if (hoursInfo.hours.length < 0) {
    return false
  }

  if (isBefore(candidateStart, hoursInfo.startHour)) {
    return false
  }

  if (isAfter(candidateEnd, hoursInfo.endHour)) {
    return false
  }

  if (isBefore(candidateStart, now)) {
    return false
  }

  return isSlotFree(
    [candidateStart, candidateEnd],
    combinedBookedSlots,
    bookableCocoons,
  )
}
