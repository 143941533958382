import React, { useCallback, useEffect } from 'react'

import { colors } from 'src/theme'
import * as Book from 'src/helpers/book'
import * as DateFn from 'date-fns'

import { buildComponent } from 'src/components/factory'
import { useNowListener } from 'src/hooks/use-now'
import { useAppearance } from 'src/hooks/use-appearance'

import * as UI from 'native-base'
import * as Icons from 'src/components/icons'
import * as Semantics from 'src/components/semantics'
import * as Components from './components'

import { BookState, useStoreSync } from './state'

const SectionSyncSlotWithNow = buildComponent().withLifecycle(() => {
  const store = BookState.useStore()

  useNowListener(
    useCallback(
      (now) => {
        if (DateFn.isBefore(store.getState().slot, now)) {
          store.getActions().setSlot(Book.roundUpToFiveMinutes(now))
        }
      },
      [store],
    ),
  )
})

const SectionSyncSlotWithDayHours = buildComponent().withLifecycle(() => {
  const store = BookState.useStore()

  // Prepare booking info
  const napDays = BookState.useStoreState((s) => s.constraintNapDays)
  const hours = BookState.useStoreState((s) => s.hoursInfo.hours)
  const slotHour = BookState.useStoreState((s) => s.slotHour)

  useEffect(() => {
    if (napDays) {
      if (!hours.length) {
        store
          .getActions()
          .updateSlot((s) => DateFn.startOfDay(DateFn.addDays(s, 1)))
      } else if (!hours.includes(slotHour)) {
        store.getActions().updateSlotHour(hours[0])
      }
    }
  }, [store, slotHour, hours, napDays])
})

const SectionSyncSlotDuration = buildComponent().withLifecycle(() => {
  const store = BookState.useStore()

  // Prepare booking info
  const duration = BookState.useStoreState((s) => s.duration)
  const durations = BookState.useStoreState((s) => s.durations)

  useEffect(() => {
    if (!durations.includes(duration) && durations.length) {
      store.getActions().setDuration(durations[0])
    }
  }, [store, durations, duration])
})

const SectionSyncSlot = buildComponent().withRender(() => (
  <>
    <SectionSyncSlotDuration />
    <SectionSyncSlotWithDayHours />
    <SectionSyncSlotWithNow />
  </>
))

const SectionLocation = buildComponent()
  .withLifecycle(() => {
    const actions = BookState.useStore().getActions()

    // Prepare booking info
    const rooms = BookState.useStoreState((store) => store.rooms)
    const cocoons = BookState.useStoreState((store) => store.cocoons)

    const room = BookState.useStoreState((s) => s.room)
    const cocoon = BookState.useStoreState((s) => s.cocoon)
    const roomCocoons = cocoons.filter((c) => !room || c.room._id === room)

    const showLocation = BookState.useStoreState((s) => s.showLocation)

    // Callbacks
    const updateRoom = actions.updateRoom
    const updateCocoon = actions.updateCocoon

    return {
      room,
      cocoon,
      rooms,
      cocoons,
      roomCocoons,

      showLocation,

      updateRoom,
      updateCocoon,
    }
  })
  .withRender(({ lifecycle }) => {
    if (!lifecycle.showLocation) {
      return null
    }

    return (
      <UI.Box
        flexDirection={['column', 'row']}
        marginTop={2}
        marginBottom={4}
        marginX={1}
      >
        {lifecycle.rooms.length > 1 && (
          <Components.RoomPicker
            rooms={lifecycle.rooms}
            room={lifecycle.room}
            onRoomChange={lifecycle.updateRoom}
            alone={lifecycle.roomCocoons.length < 2}
          />
        )}
        {lifecycle.cocoons.length > 1 && (
          <Components.CocoonPicker
            cocoons={lifecycle.cocoons}
            cocoon={lifecycle.cocoon}
            onCocoonChange={lifecycle.updateCocoon}
            alone={lifecycle.rooms.length < 2}
          />
        )}
      </UI.Box>
    )
  })

const SectionDuration = buildComponent()
  .withLifecycle(() => {
    const actions = BookState.useStore().getActions()

    // Prepare booking info
    const duration = BookState.useStoreState((s) => s.duration)
    const durations = BookState.useStoreState((s) => s.durations)

    // Callbacks
    const updateDuration = actions.updateDuration

    return {
      durations,
      duration,
      updateDuration,
    }
  })
  .withRender(({ lifecycle }) => {
    return (
      <UI.Row marginTop={2} marginBottom={1} marginX={1}>
        {lifecycle.durations.map((duration, index) => (
          <Components.Duration
            key={duration}
            first={!index}
            last={index > 2}
            active={duration === lifecycle.duration}
            duration={duration}
            onPress={lifecycle.updateDuration}
          />
        ))}
      </UI.Row>
    )
  })

const SectionDate = buildComponent()
  .withLifecycle(() => {
    const actions = BookState.useStore().getActions()

    // Prepare booking info
    const hours = BookState.useStoreState((s) => s.hoursInfo.hours)
    const slotDay = BookState.useStoreState((s) => s.slotDay)
    const slotHour = BookState.useStoreState((s) => s.slotHour)

    const isDayValid = BookState.useStoreState((s) => s.isDayValid)

    // Callbacks
    const updateSlot = actions.updateSlot
    const updateSlotHour = actions.updateSlotHour

    return {
      hours,
      slotHour,

      slotDay,
      isDayValid,

      updateSlot,
      updateSlotHour,
    }
  })
  .withRender(({ lifecycle }) => {
    return (
      <>
        <Components.DatePicker
          date={lifecycle.slotDay}
          onChange={lifecycle.updateSlot}
          isDayValid={lifecycle.isDayValid}
        />

        <Components.HourPicker
          values={lifecycle.hours}
          value={lifecycle.slotHour}
          onValueChange={lifecycle.updateSlotHour}
        />
      </>
    )
  })

const SectionSlot = buildComponent()
  .withLifecycle(() => {
    const actions = BookState.useStore().getActions()

    // Prepare booking info
    const slotHour = BookState.useStoreState((s) => s.slotHour)
    const seekToAvailableSlot = BookState.useStoreState(
      (s) => s.seekToAvailableSlot,
    )

    const noSlotAvailable = BookState.useStoreState((s) => s.noSlotAvailable)

    // Callbacks
    const goToNextSlot = actions.goToNextSlot

    return {
      slotHour,
      noSlotAvailable,
      seekToAvailableSlot,
      goToNextSlot,
    }
  })
  .withRender(({ lifecycle }) => {
    return (
      <Components.SlotPicker
        hour={lifecycle.slotHour}
        noSlotAvailable={lifecycle.noSlotAvailable}
        goToNextSlot={
          !lifecycle.seekToAvailableSlot ? lifecycle.goToNextSlot : undefined
        }
      />
    )
  })

const SectionBook = buildComponent<{ book: () => Promise<void | null> }>()
  .withLocale('screens.connected.book.screen')
  .withLifecycle(() => {
    const actions = BookState.useStore().getActions()

    // Prepare booking info
    const error = BookState.useStoreState((s) => s.error)
    const canBook = BookState.useStoreState((s) => s.canBook)

    // Callbacks
    const clearError = actions.clearError

    return {
      canBook,
      error,
      clearError,
    }
  })
  .withRender(({ props, lifecycle, locale }) => {
    return (
      <UI.Column width="90%" marginX="auto" marginTop={4} marginBottom={8}>
        <Components.NoSlotWarning />
        <Components.CannotBookWarning
          error={lifecycle.error}
          clearError={lifecycle.clearError}
        />
        <Semantics.Button
          colorScheme="secondary"
          isDisabled={!lifecycle.canBook}
          onPress={props.book}
          leftIcon={
            <Icons.FontAwesomeIcon
              color={colors.dark['700']}
              icon={Icons.Solid.faCheck}
              size="1x"
            />
          }
        >
          {locale('actions.book')}
        </Semantics.Button>
      </UI.Column>
    )
  })

const BookImplementation = buildComponent<{
  route: { params?: { napId?: string } }
}>()
  .withLocale('screens.connected.book.screen')
  .withLifecycle(({ props }) => {
    const { napId } = props.route.params ?? {}

    // Prepare booking constraints
    const { nap, actions, setNap } = useStoreSync(napId)

    // Prepare booking info
    const cocoons = BookState.useStoreState((store) => store.cocoons)
    const durations = BookState.useStoreState((s) => s.durations)

    // Callbacks
    const book = useCallback(
      () => actions.book().then((n) => n && setNap(n)),
      [actions, setNap],
    )

    // Effects
    useAppearance({
      action: nap ? Components.NapBookedIcon : 'back',
    })

    return {
      nap,
      cocoons,
      durations,
      book,
    }
  })
  .withRender(({ lifecycle, locale }) => {
    if (!lifecycle.durations.length || !lifecycle.cocoons.length) {
      return null
    }

    if (lifecycle.nap) {
      return <Components.NapBooked nap={lifecycle.nap} />
    }

    return (
      <UI.ScrollView
        padding={3}
        width="100%"
        scrollIndicatorInsets={{ right: 1 }}
      >
        <UI.View mx={[0, 'auto']} maxWidth={['100%', 600]}>
          {lifecycle.cocoons.length > 1 && (
            <UI.Text color="white">{locale('titles.location')}</UI.Text>
          )}

          <SectionLocation />

          <UI.Text color="white">{locale('titles.slot')}</UI.Text>

          <SectionDuration />

          <Semantics.Card opaque paddingBottom={4} marginX={1}>
            <SectionDate />
            <SectionSlot />
          </Semantics.Card>

          <SectionBook book={lifecycle.book} />

          <SectionSyncSlot />
        </UI.View>
      </UI.ScrollView>
    )
  })

export const ScreenConnectedBook = (props: {
  route: { params?: { napId?: string } }
}) => (
  <BookState.Provider>
    <BookImplementation {...props} />
  </BookState.Provider>
)
