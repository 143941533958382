import React from 'react'
import { Platform, NativeModules } from 'react-native'
import { computed, thunk, RecursiveState, action } from 'easy-peasy'
import base64 from 'base-64'
import AsyncStorage from '@react-native-async-storage/async-storage'
import VersionNumber from 'src/services/version-number'

import { Set, Computed, Thunk, Action } from 'src/state/types'
import { set } from 'src/state/utils'

const osMap = {
  windows: 'web',
  macos: 'web',
  web: 'web',
  ios: 'ios',
  android: 'android',
} as const

interface SessionUtils {
  // State
  token: string | null
  claims: Computed<this, { _id: string; space: string }>

  // Actions
  setToken: Set<this, 'token'>

  // Thunk
  connectWithToken: Thunk<this, string>
  disconnect: Thunk<this>
  restore: Thunk<this, never, Promise<this['claims']['result']>>
}

interface AppearanceUtils {
  // State
  title: string
  action: null | 'back' | React.FunctionComponent

  // Actions
  setTitle: Set<this, 'title'>
  setAction: Set<this, 'action'>
  setAll: Action<this, RecursiveState<Omit<this, 'setAll'>>>
}

interface DeviceUtils {
  // State
  connected: boolean
  platform: Computed<this, 'web' | 'ios' | 'android'>
  appVersion: Computed<this, string>
  locale: Computed<this, 'fr' | 'en'>

  // Actions
  setConnected: Set<this, 'connected'>
}

export interface ModelUtils {
  session: SessionUtils
  appearance: AppearanceUtils
  device: DeviceUtils
}

export const modelUtils: ModelUtils = {
  session: {
    // State
    token: null,
    claims: computed((state) => {
      const decoded = JSON.parse(
        base64.decode(state.token?.split('.')[1] ?? '') || '{}',
      )
      return decoded || { _id: '', space: '' }
    }),

    // Actions
    setToken: set('token'),

    // Thunks
    connectWithToken: thunk(async (actions, token) => {
      if (token) {
        await AsyncStorage.setItem('@token', token)
      }
      actions.setToken(token)
    }),
    disconnect: thunk(async (actions) => {
      await AsyncStorage.removeItem('@token')
      actions.setToken(null)
    }),
    restore: thunk(async (actions, _, helpers) => {
      const token = await AsyncStorage.getItem('@token')

      if (token) {
        actions.setToken(token)
      }

      return helpers.getState().claims
    }),
  },
  appearance: {
    // State
    title: '',
    action: null,

    // Actions
    setTitle: set('title'),
    setAction: set('action'),
    setAll: action((state, payload) => {
      state.title = payload.title
      state.action = payload.action
    }),
  },
  device: {
    // State
    connected: false,
    platform: computed(() => osMap[Platform.OS]),
    appVersion: computed(() => VersionNumber.appVersion),
    locale: computed(
      [(_, store) => store.profile.preferences?.locale],
      (userLocale) => {
        if (['en', 'fr'].includes(userLocale!)) {
          return userLocale
        }

        return Platform.select({
          ios:
            NativeModules.SettingsManager?.settings?.AppleLocale ??
            NativeModules.SettingsManager?.settings?.AppleLanguages?.[0] ??
            'fr',
          android: NativeModules.I18nManager?.localeIdentifier ?? 'fr',
          web: (typeof navigator !== 'undefined' && navigator.language) || 'fr',
        }).slice(0, 2)
      },
    ),

    // Actions
    setConnected: set('connected'),
  },
}
