import { useRef, useEffect } from 'react'

export function useWebOnScroll({ onScroll, onScrollEnd }: any) {
  const lastScrollEvent = useRef<number | null>(null)
  const scrollEndTimeout = useRef<any | null>(null)

  const handleWebScroll = (event: any) => {
    onScroll(event)

    const timestamp = Date.now()

    if (scrollEndTimeout.current) {
      clearTimeout(scrollEndTimeout.current)
    }

    if (lastScrollEvent.current) {
      // Scroll ended
      scrollEndTimeout.current = setTimeout(() => {
        if (lastScrollEvent.current === timestamp) {
          lastScrollEvent.current = null
          onScrollEnd && onScrollEnd(event)
        }
      }, 200)
    }

    lastScrollEvent.current = timestamp
  }

  useEffect(() => {
    return () => {
      scrollEndTimeout.current && clearTimeout(scrollEndTimeout.current)
    }
  }, [])

  return handleWebScroll
}
