import { buildComponent } from 'src/components/factory'

import { useStoreState } from 'src/hooks/state'
import { usePendingRedirect } from 'src/hooks/use-pending-redirect'
import { useRoute } from '@react-navigation/native'

export const NoSpaceDetection = buildComponent().withLifecycle(() => {
  const route = useRoute()
  const loaded = useStoreState((store) => store.space.loaded)
  const spaces = useStoreState((store) => store.space.all)

  usePendingRedirect(
    loaded && !spaces.length && route.name !== '/account/spaces',
    '/account/spaces',
    {},
    true,
  )
})
