import { action, Action, State } from 'easy-peasy'

/**
 * Helper function for creating a pure setter action
 * @param key - key of the model's property to set
 */
export function set<Model extends object, Key extends keyof State<Model>>(
  key: Key,
): Action<Model, State<Model>[Key]> {
  return action<Model, State<Model>[Key]>((state, payload) => {
    state[key] = payload
  })
}
