import { useStore } from './state'
import { useFocusEffect } from '@react-navigation/native'
import { ModelUtils } from 'src/state/models/utils'
import { useCallback } from 'react'

export function useAppearance(
  config: Partial<Pick<ModelUtils['appearance'], 'title' | 'action'>>,
) {
  const Store = useStore()

  useFocusEffect(
    useCallback(() => {
      Store.getActions().utils.appearance.setAll({
        action: config.action ?? null,
        title: config.title ?? '',
      })
    }, [Store, config.action, config.title]),
  )
}
