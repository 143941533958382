import React from 'react'

import { preloadImage } from 'src/helpers/image'
import { imageFactory } from './image.shared'

export const Image = imageFactory.withRender(({ props, lifecycle }) => {
  if (!lifecycle.url) {
    return null
  }

  return (
    <div
      // eslint-disable-next-line react-native/no-inline-styles
      style={{
        backgroundImage: 'url(' + lifecycle.url + ')',
        backgroundSize: props.resizeMode,
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
      }}
    />
  )
}) as typeof imageFactory & { preloadImage: typeof preloadImage }

Image.preloadImage = preloadImage
