import React from 'react'
import { Box, IBoxProps } from 'native-base'

import { buildComponent } from 'src/components/factory'
import * as UI from 'native-base'

export const Card = buildComponent<
  IBoxProps & {
    containerStyle?: IBoxProps['style']
    flat?: boolean
    opaque?: boolean
  }
>()
  .withStyles({
    container: {
      main: {
        position: 'relative',
        padding: 0,
        marginVertical: 8,
        marginHorizontal: 4,
      },
    },
    shadow: {
      main: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: -4,
        borderRadius: 16,
        backgroundColor: '#FFFFFFBB',
      },
      custom: {
        flat: {
          bottom: 0,
        },
      },
    },
    card: {
      main: {
        paddingVertical: 8,
        paddingHorizontal: 16,
        borderRadius: 16,
        backgroundColor: '#FFFFFFBB',
        flex: 1,
      },
      custom: {
        opaque: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
  })
  .withLifecycle(({ props }) => {
    const innerProps: UI.IBoxProps = { ...props }
    const boxProps: Partial<UI.IBoxProps> = {}
    const shadowProps: Partial<UI.IBoxProps> = {}
    Object.keys(innerProps).forEach((prop) => {
      if (prop.startsWith('margin') || prop.startsWith('flex')) {
        boxProps[prop as keyof UI.IBoxProps] =
          innerProps[prop as keyof UI.IBoxProps]
        delete innerProps[prop as keyof UI.IBoxProps]
      }

      if (prop.startsWith('border')) {
        shadowProps[prop as keyof UI.IBoxProps] =
          innerProps[prop as keyof UI.IBoxProps]
      }
    })

    return {
      innerProps,
      boxProps,
      shadowProps,
    }
  })
  .withRender(({ props, styles, lifecycle }) => {
    return (
      <Box
        {...lifecycle.boxProps}
        flexDirection="row"
        style={[styles.container(), props.containerStyle]}
      >
        <Box
          {...lifecycle.shadowProps}
          style={styles.shadow({ flat: props.flat })}
        />
        <Box
          {...lifecycle.innerProps}
          style={[
            styles.card({ opaque: props.opaque }),
            lifecycle.innerProps.style,
          ]}
        >
          {props.children}
        </Box>
      </Box>
    )
  })
