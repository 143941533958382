// we don't care about this rule in hook utils files
/* eslint-disable react-hooks/exhaustive-deps */
import { useStoreState } from './state'
import { useCallback, useEffect, useLayoutEffect, useRef } from 'react'

export function useOnConnected(
  effect: () => void | (() => void),
  deps: unknown[] = [],
) {
  const connected = useStoreState((store) => store.utils.device.connected)

  // Use a ref for the callback to keep it updated, but avoid
  // causing a re-run of the effect if it changes
  const effectRef = useRef(effect)
  useLayoutEffect(() => {
    effectRef.current = effect
  })

  // Update the effect callback reference based on its dependencies
  const effectCallback = useCallback(() => effectRef.current(), deps)

  // When connected or when the effect changes, call the effect
  useEffect(() => {
    if (connected) {
      return effectCallback()
    }
  }, [connected, effectCallback])
}
