import React, { useCallback, useEffect, useState } from 'react'
import { Animated, Easing, StatusBar } from 'react-native'

import { colors } from 'src/theme'

import * as Firestore from 'src/helpers/firestore'

import { buildComponent } from 'src/components/factory'
import { useResponsive } from 'src/hooks/use-responsive'
import { useStore, useStoreState } from 'src/hooks/state'

import * as UI from 'native-base'
import * as Semantics from 'src/components/semantics'

const mobileSteps = [
  {
    slug: 'welcome',
    top: { percent: 50, static: 0 },
    left: {
      percent: 50,
      static: 0,
    },
    offsetText: 0,
  },
  {
    slug: 'book',
    top: { percent: 100, static: -32 },
    left: {
      percent: 25,
      static: 0,
    },
    offsetText: 0,
  },
  {
    slug: 'audio',
    top: { percent: 100, static: -32 },
    left: {
      percent: 75,
      static: 0,
    },
    offsetText: 0,
  },
  {
    slug: 'bookings',
    top: { percent: 0, static: 380 },
    left: {
      percent: 50,
      static: 0,
    },
    offsetText: 0,
  },
  {
    slug: 'profile',
    top: { percent: 0, static: 32 },
    left: {
      percent: 100,
      static: -32,
    },
    offsetText: -32,
  },
] as const

const desktopSteps = [
  {
    slug: 'welcome',
    top: { percent: 50, static: 0 },
    left: {
      percent: 50,
      static: 0,
    },
    offsetText: 0,
  },
  {
    slug: 'book',
    top: { percent: 100, static: -64 },
    left: {
      percent: 50,
      static: -148,
    },
    offsetText: 0,
  },
  {
    slug: 'audio',
    top: { percent: 100, static: -64 },
    left: {
      percent: 50,
      static: 148,
    },
    offsetText: 0,
  },
  {
    slug: 'bookings',
    top: { percent: 0, static: 364 },
    left: {
      percent: 50,
      static: 0,
    },
    offsetText: 32,
  },
  {
    slug: 'profile',
    top: { percent: 0, static: 32 },
    left: {
      percent: 100,
      static: -48,
    },
    offsetText: -32,
  },
] as const

const OnboardingCircle = buildComponent<{
  width: number
  height: number
}>()
  .withStyles({
    border: {
      main: {
        borderRadius: 64,
        borderWidth: 4,
        borderColor: colors.secondary['500'],
      },
    },
  })
  .withLifecycle(({ props }) => {
    const max = Math.max(props.width, props.height)

    const [animation] = useState(new Animated.Value(1.05))
    useEffect(() => {
      const loop = Animated.loop(
        Animated.sequence([
          Animated.timing(animation, {
            toValue: 0.95,
            duration: 800,
            useNativeDriver: true,
          }),
          Animated.timing(animation, {
            toValue: 1.05,
            duration: 800,
            useNativeDriver: true,
          }),
        ]),
      )

      loop.start()

      return () => loop.stop()
    }, [animation])

    return {
      max,
      scaleAnimation: {
        transform: [{ scale: animation }],
      },
    }
  })
  .withRender(({ props, lifecycle, styles }) => {
    // View
    return (
      <Animated.View
        style={[
          {
            width: lifecycle.max * 4 + 128,
            height: lifecycle.max * 4 + 128,
            top: -lifecycle.max * 2 + props.height / 2 - 64,
            left: -lifecycle.max * 2 + props.width / 2 - 64,
            borderRadius: lifecycle.max * 4,
            borderWidth: lifecycle.max * 2,
            borderColor: colors.dark['500']
              .replace('rgb', 'rgba')
              .replace(')', ',0.8)'),
          },
          lifecycle.scaleAnimation,
        ]}
      >
        <UI.View width={128} height={128} style={styles.border()} />
      </Animated.View>
    )
  })

const OnboardingMask = buildComponent<{
  height: number
  topStatic: number
  leftStatic: number
  topPercent: number
  leftPercent: number
}>()
  .withLifecycle(({ props }) => {
    // Props
    const { topStatic, leftStatic, topPercent, leftPercent } = props

    // State
    const [topAnimation] = React.useState(new Animated.Value(0))
    const [leftAnimation] = React.useState(new Animated.Value(0))

    const dimensions = useResponsive()
    const height = props.height

    const staticTop = (topStatic * 100) / height
    const staticLeft = (leftStatic * 100) / dimensions.width

    const top = topPercent + staticTop
    const left = leftPercent + staticLeft

    useEffect(() => {
      const topTiming = Animated.timing(topAnimation, {
        toValue: top,
        duration: 450,
        easing: Easing.bezier(0.25, 0.1, 0.25, 1),
        useNativeDriver: true,
      })
      const bottomTiming = Animated.timing(leftAnimation, {
        toValue: left,
        duration: 450,
        easing: Easing.bezier(0.25, 0.1, 0.25, 1),
        useNativeDriver: true,
      })

      topTiming.start()
      bottomTiming.start()

      return () => {
        topTiming.stop()
        bottomTiming.stop()
      }
    }, [topAnimation, leftAnimation, top, left])

    return {
      width: dimensions.width,
      height: height,
      wrapperAnimation: {
        position: 'absolute' as const,
        transform: [
          {
            translateX: leftAnimation.interpolate({
              inputRange: [0, 100],
              outputRange: [-dimensions.width / 2, dimensions.width / 2],
            }),
          },
          {
            translateY: topAnimation.interpolate({
              inputRange: [0, 100],
              outputRange: [-dimensions.height / 2, dimensions.height / 2],
            }),
          },
        ],
      },
    }
  })
  .withRender(({ lifecycle }) => {
    // View
    return (
      <Animated.View style={[lifecycle.wrapperAnimation]}>
        <OnboardingCircle width={lifecycle.width} height={lifecycle.height} />
      </Animated.View>
    )
  })

export const ConnectedOnboarding = buildComponent()
  .withStyles({
    wrapper: {
      main: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1000,
        overflow: 'hidden',
        paddingBottom: 0,
      },
    },
    textContainer: {
      main: {
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    text: {
      main: {
        textAlign: 'center',
        color: 'white',
      },
    },
  })
  .withLocale('components.elements.onboarding')
  .withLifecycle(() => {
    // State
    const Store = useStore()
    const { isDesktop, height } = useResponsive()
    const [stepNumber, setStepNumber] = React.useState(0)
    const [containerHeight, setContainerHeight] = React.useState(height)

    const needsOnboarding = useStoreState(
      (store) =>
        store.profile.preferences && !store.profile.preferences.onboarded,
    )

    const steps = isDesktop ? desktopSteps : mobileSteps
    const step = steps[stepNumber]

    const hideOnboarding = useCallback(() => {
      setStepNumber(0)
      Firestore.upsertDocument(Firestore.getDocumentReference('preferences'), {
        onboarded: true,
      })
      Store.getActions().profile.updatePreferences({
        onboarded: true,
      })
    }, [Store])

    const handleFirstButtonPress = useCallback(() => {
      if (stepNumber < steps.length - 1) {
        setStepNumber(stepNumber + 1)
      } else {
        hideOnboarding()
      }
    }, [stepNumber, steps, hideOnboarding])

    const handleSecondButtonPress = useCallback(() => {
      if (stepNumber > 0) {
        setStepNumber(stepNumber - 1)
      } else {
        hideOnboarding()
      }
    }, [stepNumber, hideOnboarding])

    return {
      needsOnboarding,
      steps,
      step,
      stepNumber,
      setStepNumber,
      containerHeight,
      setContainerHeight,

      handleFirstButtonPress,
      handleSecondButtonPress,
    }
  })
  .withRender(({ styles, locale, lifecycle }) => {
    // View
    if (!lifecycle.needsOnboarding) {
      return null
    }

    return (
      <UI.View style={styles.wrapper()}>
        <StatusBar backgroundColor="#333c67" />

        <UI.Box safeAreaTop />
        <UI.Box
          flex={1}
          onLayout={(e) =>
            lifecycle.setContainerHeight(e.nativeEvent.layout.height)
          }
        >
          <OnboardingMask
            height={lifecycle.containerHeight}
            topStatic={lifecycle.step.top.static || 0}
            leftStatic={lifecycle.step.left.static || 0}
            topPercent={lifecycle.step.top.percent || 0}
            leftPercent={lifecycle.step.left.percent || 0}
          />

          <UI.View
            flex={1}
            style={styles.textContainer()}
            mx={[8, 'auto']}
            width={[null, '100%']}
            maxWidth={['100%', 600]}
          >
            <UI.Text
              fontSize="lg"
              style={styles.text()}
              marginTop={-lifecycle.step.offsetText}
            >
              {locale(`steps.${lifecycle.step.slug}`)}
            </UI.Text>
          </UI.View>

          <UI.Column
            flex={1}
            justifyContent="center"
            mx={[16, 'auto']}
            width={[null, '100%']}
            maxWidth={['100%', 320]}
          >
            <Semantics.Button
              onPress={lifecycle.handleFirstButtonPress}
              colorScheme="secondary"
            >
              {lifecycle.stepNumber
                ? lifecycle.stepNumber === lifecycle.steps.length - 1
                  ? locale('buttons.finish')
                  : locale('buttons.next')
                : locale('buttons.start')}
            </Semantics.Button>
            <UI.Box marginX={4}>
              <Semantics.Button
                onPress={lifecycle.handleSecondButtonPress}
                colorScheme={lifecycle.stepNumber ? 'primary' : 'dark'}
                py={0}
                height={40}
              >
                {lifecycle.stepNumber
                  ? locale('buttons.previous')
                  : locale('buttons.skip')}
              </Semantics.Button>
            </UI.Box>
          </UI.Column>
        </UI.Box>
      </UI.View>
    )
  })
