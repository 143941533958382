import { StyleSheet } from 'react-native'
import { useResponsive } from './use-responsive'
import { useStoreState } from './state'

export type StylesDefinitions = {
  [key in string]: {
    main: StyleSheet.NamedStyles<any>[string] | { _web?: any }
    mobile?: StyleSheet.NamedStyles<any>[string] | { _web?: any }
    desktop?: StyleSheet.NamedStyles<any>[string] | { _web?: any }
    custom?: Record<
      string,
      StyleSheet.NamedStyles<any>[string] | { _web?: any }
    >
  } & {
    [k in 'mobile' | 'desktop' | 'night']?:
      | StyleSheet.NamedStyles<any>[string]
      | { _web?: any }
  }
}

export type StylesGetters<T extends StylesDefinitions> = {
  [key in keyof T]: (
    params?: [keyof T[key]['custom']] extends [never]
      ? never
      : {
          [k in keyof T[key]['custom']]?: boolean
        },
  ) => (
    | false
    | Omit<T[key]['main'], '_web'>
    | Omit<T[key]['custom'][keyof T[key]['custom']], '_web'>
  )[]
}

function genericStyleHookGenerator<T extends StylesDefinitions>(
  definitions: T,
  useDefaultParams: () => Record<string, boolean>,
): () => StylesGetters<T> {
  const wrapped: {
    [key in keyof T]: {
      main: T[key]['main']
      mobile: T[key]['mobile']
      desktop: T[key]['desktop']
    } & {
      [k in keyof T[key]['custom']]: T[key]['custom'][k]
    }
  } = {} as any

  Object.entries(definitions).forEach(([key, styles]: [keyof T, any]) => {
    const toWrap = {
      main: { ...styles.main },
      mobile: { ...styles.mobile },
      desktop: { ...styles.desktop },
      ...styles.custom,
    }
    Object.keys(toWrap).forEach((k) => delete toWrap[k]._web)
    wrapped[key] = StyleSheet.create(toWrap)
  })

  return function useComputedStyles() {
    const defaultParams = useDefaultParams()

    const styles: {
      [key in keyof T]: (
        params?: [keyof T[key]['custom']] extends [never]
          ? never
          : {
              [k in keyof T[key]['custom']]?: boolean
            },
      ) => (
        | false
        | Omit<T[key]['main'], '_web'>
        | Omit<T[key]['custom'][keyof T[key]['custom']], '_web'>
      )[]
    } = {} as any

    Object.keys(wrapped).forEach((key) => {
      styles[key as keyof T] = function computedStyles(params: any) {
        const allParams: { [k in string]?: boolean } = {
          ...defaultParams,
          ...params,
          main: true,
        }

        return Object.entries(wrapped[key]).flatMap(([p, s]) => {
          const source: any =
            p === 'main' ? definitions[key].main : definitions[key].custom?.[p]
          const web = allParams.web && source?._web

          return !!allParams[p] && (web ? [s, web] : s)
        })
      } as any
    })

    return styles
  }
}

export function createStyleHook<T extends StylesDefinitions>(definitions: T) {
  return genericStyleHookGenerator(definitions, () => {
    const { isMobile, isDesktop } = useResponsive()
    const platform = useStoreState((store) => store.utils.device.platform)

    return {
      mobile: isMobile,
      desktop: isDesktop,
      web: platform === 'web',
    }
  })
}
