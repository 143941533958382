import { useEffect, useState } from 'react'

let nowInterval: null | ReturnType<typeof setInterval> = null
const nowListeners: ((date: Date) => void)[] = []
const startOrStopListeners = () => {
  if (nowInterval && !nowListeners.length) {
    clearInterval(nowInterval)
  }

  if (!nowInterval && nowListeners.length) {
    nowInterval = setInterval(() => {
      const now = new Date()
      nowListeners.forEach((listener) => listener(now))
    }, 5000)
  }
}
const registerNowListener = (listener: (date: Date) => void) => {
  if (!nowListeners.includes(listener)) {
    nowListeners.push(listener)
  }

  startOrStopListeners()
}
const unregisterNowListener = (listener: (date: Date) => void) => {
  if (nowListeners.includes(listener)) {
    nowListeners.splice(nowListeners.indexOf(listener), 1)
  }

  startOrStopListeners()
}

export function useNow() {
  const [, setNow] = useState(new Date())
  useNowListener(setNow)

  return new Date()
}

export function useNowListener(listener: (now: Date) => void) {
  useEffect(() => {
    registerNowListener(listener)
    return () => unregisterNowListener(listener)
  }, [listener])
}
