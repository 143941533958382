import { useEffect } from 'react'

import { Nap } from 'src/state/models/nap'

import { firestore, firebase } from 'src/services/firebase'
import { graphql } from 'src/services/graphql'
import * as Firestore from 'src/helpers/firestore'

import { useStore } from 'src/hooks/state'
import { useOnConnected } from 'src/hooks/use-on-connected'

function getTimeLimit() {
  const timeLimit = new Date()
  timeLimit.setHours(0, 0, 0, 0)
  timeLimit.setDate(timeLimit.getDate() - 30)

  return timeLimit.toISOString()
}

async function purgeOlderNaps(
  naps: Nap[],
  doc: firebase.firestore.DocumentReference,
) {
  const timeLimit = getTimeLimit()

  const toDelete = naps.filter((nap) => nap.time.start < timeLimit)
  const toKeep = naps.filter((n) => !toDelete.includes(n))

  if (toDelete.length > 0) {
    const deleteUpdate = toDelete.reduce<
      Record<string, firebase.firestore.FieldValue>
    >((acc, nap) => {
      acc[nap._id] = firestore.FieldValue?.delete()
      return acc
    }, {})

    await doc.update(deleteUpdate).catch(() => {})
  }

  return toKeep
}

function convertNapsToFirestoreUpdate(naps: Nap[]) {
  const napsMap = naps.reduce<Record<string, any>>((acc, nap) => {
    Object.keys(nap).map((key) => {
      acc[nap._id + '.' + key] = nap[key as keyof typeof nap]
    })
    return acc
  }, {})
  const napsUpsertMap = naps.reduce<Record<string, Nap>>((acc, nap) => {
    acc[nap._id] = nap
    return acc
  }, {})

  return {
    update: napsMap,
    upsert: napsUpsertMap,
  }
}

export function useDatasourceNap() {
  const Store = useStore()

  // Keep a reference to the naps document
  const napsDoc = Firestore.useDocumentReference('naps', true)

  // When connected, subscribe to API to get naps and sync to Firestore
  // Re-run the effect when space changes to sync correct list of naps
  useOnConnected(
    () =>
      graphql.subscribe(['nap'], async () => {
        const { napList } = await graphql.queries
          .getNaps(getTimeLimit())
          .catch(() => ({ napList: null }))

        if (napList) {
          const { update, upsert } = convertNapsToFirestoreUpdate(napList)

          Firestore.upsertDocument(napsDoc, update, upsert)
        }
      }),
    [napsDoc],
  )

  // On space change, clean-up naps
  useEffect(() => {
    return () => Store.getActions().nap.setList([])
  }, [Store, napsDoc])

  // On space change, subscribe to Firestore to get spaces
  useEffect(() => {
    return napsDoc.onSnapshot(async (doc) => {
      const data = doc?.data()

      if (data) {
        const naps = await purgeOlderNaps(Object.values(data), napsDoc)
        Store.getActions().nap.setList(naps)
      }
    })
  }, [Store, napsDoc])
}
