import { extendTheme } from 'native-base'

export const fonts = {
  normal: {
    fontFamily: 'Rubik-Regular',
    fontWeight: '400',
  },
  bold: {
    fontFamily: 'Rubik-Medium',
    fontWeight: '500',
  },
  bolder: {
    fontFamily: 'Rubik-Bold',
    fontWeight: '700',
  },
} as const

export const colors = {
  primary: {
    50: 'rgb(241,243,249)',
    100: 'rgb(221,226,239)',
    200: 'rgb(199,207,229)',
    300: 'rgb(176,187,218)',
    400: 'rgb(159,173,210)',
    500: 'rgb(142,158,202)',
    600: 'rgb(134,150,197)',
    700: 'rgb(123,140,189)',
    800: 'rgb(113, 130, 183)',
    900: 'rgb(95,112,171)',
  },
  secondary: {
    50: 'rgb(255, 249, 235)',
    100: 'rgb(254, 241, 204)',
    200: 'rgb(253, 232, 171)',
    300: 'rgb(252, 223, 137)',
    400: 'rgb(252, 216, 111)',
    500: 'rgb(251,209,86)',
    600: 'rgb(250, 204, 79)',
    700: 'rgb(250, 198, 69)',
    800: 'rgb(249, 192, 60)',
    900: 'rgb(248, 181, 43)',
  },
  text: {
    50: 'rgb(228, 228, 234)',
    100: 'rgb(186, 189, 202)',
    200: 'rgb(141, 145, 166)',
    300: 'rgb(95, 100, 130)',
    400: 'rgb(60, 67, 104)',
    500: 'rgb(26, 34, 77)',
    600: 'rgb(23, 30, 70)',
    700: 'rgb(19, 25, 61)',
    800: 'rgb(15, 20, 52)',
    900: 'rgb(8, 12, 37)',
  },
  dark: {
    50: 'rgb(228, 228, 234)',
    100: 'rgb(186, 189, 202)',
    200: 'rgb(141, 145, 166)',
    300: 'rgb(95, 100, 130)',
    400: 'rgb(60, 67, 104)',
    500: 'rgb(26, 34, 77)',
    600: 'rgb(23, 30, 70)',
    700: 'rgb(19, 25, 61)',
    800: 'rgb(15, 20, 52)',
    900: 'rgb(8, 12, 37)',
  },
  success: {
    50: 'rgb(224, 249, 245)',
    100: 'rgb(179, 241, 230)',
    200: 'rgb(128, 232, 214)',
    300: 'rgb(77, 223, 198)',
    400: 'rgb(38, 216, 185)',
    500: 'rgb(0,209,173)',
    600: 'rgb(0, 204, 166)',
    700: 'rgb(0, 198, 156)',
    800: 'rgb(0, 192, 147)',
    900: 'rgb(0, 181, 131)',
  },
  danger: {
    50: 'rgb(252, 234, 232)',
    100: 'rgb(248, 202, 197)',
    200: 'rgb(243, 166, 158)',
    300: 'rgb(238, 130, 119)',
    400: 'rgb(234, 104, 90)',
    500: 'rgb(230, 77, 61)',
    600: 'rgb(227, 70, 55)',
    700: 'rgb(223, 61, 47)',
    800: 'rgb(219, 52, 39)',
    900: 'rgb(213, 37, 26)',
  },
  warning: {
    50: 'rgb(245, 242, 236)',
    100: 'rgb(230, 223, 209)',
    200: 'rgb(213, 202, 178)',
    300: 'rgb(196, 180, 147)',
    400: 'rgb(183, 164, 123)',
    500: 'rgb(170, 148, 100)',
    600: 'rgb(163, 140, 92)',
    700: 'rgb(153, 129, 82)',
    800: 'rgb(144, 119, 72)',
    900: 'rgb(127, 101, 54)',
  },
} as const

// Allow passing width and height in pixel as props
// for quicker prototyping
const sizes: Record<string, number> = {}
;[...Array(500)].map((_, i) => {
  sizes['' + i] = i
})

export const theme = extendTheme({
  colors,
  sizes,
  breakpoints: {
    base: 0,
    sm: 620,
    md: 800,
    lg: 1080,
    xl: 1640,
    '2xl': 1920,
  },
  fontSizes: {
    '3xs': 11,
    '2xs': 12,
    xs: 14,
    sm: 16,
    md: 18,
    lg: 20,
    xl: 24,
    '2xl': 30,
    '3xl': 36,
    '4xl': 48,
    '5xl': 60,
    '6xl': 72,
    '7xl': 96,
    '8xl': 128,
    '9xl': 160,
  },
  fontConfig: {
    'Rubik-Regular': {
      100: {
        normal: 'Rubik-Regular',
        italic: 'Rubik-Italic',
      },
      200: {
        normal: 'Rubik-Regular',
        italic: 'Rubik-Italic',
      },
      300: {
        normal: 'Rubik-Regular',
        italic: 'Rubik-Italic',
      },
      400: {
        normal: 'Rubik-Regular',
        italic: 'Rubik-Italic',
      },
      500: {
        normal: 'Rubik-Medium',
        italic: 'Rubik-MediumItalic',
      },
      600: {
        normal: 'Rubik-Medium',
        italic: 'Rubik-MediumItalic',
      },
      700: {
        normal: 'Rubik-Bold',
        italic: 'Rubik-BoldItalic',
      },
      800: {
        normal: 'Rubik-Bold',
        italic: 'Rubik-BoldItalic',
      },
      900: {
        normal: 'Rubik-Bold',
        italic: 'Rubik-BoldItalic',
      },
    },
    'Rubik-Medium': {
      100: {
        normal: 'Rubik-Regular',
        italic: 'Rubik-Italic',
      },
      200: {
        normal: 'Rubik-Regular',
        italic: 'Rubik-Italic',
      },
      300: {
        normal: 'Rubik-Regular',
        italic: 'Rubik-Italic',
      },
      400: {
        normal: 'Rubik-Regular',
        italic: 'Rubik-Italic',
      },
      500: {
        normal: 'Rubik-Medium',
        italic: 'Rubik-MediumItalic',
      },
      600: {
        normal: 'Rubik-Medium',
        italic: 'Rubik-MediumItalic',
      },
      700: {
        normal: 'Rubik-Bold',
        italic: 'Rubik-BoldItalic',
      },
      800: {
        normal: 'Rubik-Bold',
        italic: 'Rubik-BoldItalic',
      },
      900: {
        normal: 'Rubik-Bold',
        italic: 'Rubik-BoldItalic',
      },
    },
  },
  fonts: {
    heading: 'Rubik-Regular',
    body: 'Rubik-Regular',
    mono: 'Rubik-Regular',
  },
  components: {
    Text: {
      defaultProps: {
        style: {
          fontFamily: 'Rubik-Regular',
        },
      },
    },
    Button: {
      variants: {
        solid(props: any) {
          const textColor = ['secondary', 'white', 'grey'].includes(
            props.colorScheme,
          )
            ? 'dark.500'
            : 'white'

          const buttonColor = {
            [props.colorScheme]: `${props.colorScheme}.500`,
            dark: 'dark.400',
            'dark-inverted': '#242c57',
            white: props.opaque ? '#FFFFFF' : '#FFFFFFBB',
            faded: '#00000022',
            grey: '#f4f5f9',
          }[props.colorScheme]

          const pressedColor =
            props.colorScheme === 'faded' ? '#00000044' : buttonColor

          const statuses = {
            bg: buttonColor,
            _hover: {
              bg: pressedColor,
            },
            _pressed: {
              bg: pressedColor,
            },
          }

          return {
            _text: { color: textColor, maxWidth: '100%' },
            _light: statuses,
            _dark: statuses,
            borderRadius: 32,
            borderWidth: 0,
          }
        },
      },
      defaultProps: {
        _text: {
          numberOfLines: 1,
          style: {
            fontWeight: '500',
            fontFamily: 'Rubik-Medium',
          },
        },
      },
    },
    Input: {
      baseStyle() {
        return {
          borderRadius: 16,
          borderColor: 'transparent',
          px: 5,
          py: 3,
          height: 48,
          backgroundColor: '#FFFFFFBB',
          _light: {
            placeholderTextColor: 'dark.100',
          },
        }
      },
      defaultProps: {
        size: 'sm',
        paddingX: 12,
      },
    },
    Checkbox: {
      sizes: {
        sm: {
          _icon: {
            size: 12,
          },
        },
      },
      baseStyle() {
        return {
          borderWidth: 0,
          backgroundColor: 'primary.200',
          width: '98%',
          _icon: {
            size: 12,
          },
          _web: {
            width: 'auto',
          },
          _checked: {
            backgroundColor: 'primary.500',
          },
        }
      },
    },
    Modal: {
      baseStyle: {
        _fade: { entryDuration: 100, exitDuration: 100 },
        _backdropFade: { entryDuration: 100, exitDuration: 100 },
      },
      defaultProps: {},
    },
  },
})
