export const PRIVACY_POLICY = {
  fr: `
Nap&Up a pour ambition de vous permettre de bénéficier de ses services, tout en veillant au respect de votre vie privée et de vos données personnelles conformément à la législation en vigueur concernant la protection des données.

## Politique de protection des données

Nap&Up limite la collecte de vos données à ce qui est strictement nécessaire pour le fonctionnement de son activité.

Nap&Up s’engage aussi à respecter les principes généraux concernant la protection des données personnelles prévus, notamment, par le Règlement UE n°2016/679 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données (dit RGPD ci-après).

## Définitions

D’après le législateur européen, une donnée à caractère personnel se définit comme «\u00A0toute information se rapportant à une personne physique identifiée ou identifiable\u00A0». (article 4 RGPD)

Le traitement quant à lui vise «\u00A0toute opération ou tout ensemble d'opérations effectuées ou non à l'aide de procédés automatisés et appliquées à des données ou des ensembles de données à caractère personnel, telles que la collecte, l'enregistrement, l'organisation, la structuration, la conservation, l'adaptation ou la modification, l'extraction, la consultation, l'utilisation, la communication par transmission, la diffusion ou toute autre forme de mise à disposition, le rapprochement ou l'interconnexion, la limitation, l'effacement ou la destruction\u00A0». (article 4 RGPD)

## Responsables du traitement

La Société Nap&Up, SAS au capital de 7 905 € dont le siège social se situe au 103 rue Jean Jaurès 92800 Puteaux. Immatriculée au RCS 824 004 485. En sa qualité de prestataire de service.

## Types de données collectées

*   Données relatives à votre identité (Nom, Prénom, Date de naissance)
*   Données de contact (Email, Mot de passe)
*   Données relatives à l’entreprise dans laquelle vous travaillez (Code d'accès)
*   Informations relatives à la réservation d’un créneau de sieste (Date, Heure, Nom du cocon)
*   Informations relatives à vos favoris (Audio)
*   Informations relatives à l’utilisation de la plateforme (Connexion, Durée de connexion, Moment de la connexion).

## Données sensibles

Nap&Up ne collecte, ni ne traite de données sensibles.

## Moment de la collecte des données

Vos données sont collectées par Nap&Up

*   lors de la création de votre compte
*   lorsque vous utilisez ses services pour la réservation d’un créneau de sieste,
*   lorsque vous mettez en favoris les pistes audio proposées.

## Licéité du traitement des données

Nap&Up, conformément à l’article 6.1 RGPD, est amenée à traiter vos informations à caractère personnel pour une ou plusieurs finalités spécifiques auxquelles vous avez consenti.

Elle peut collecter vos informations personnelles dans le cadre du contrat qui vous lie à elle.

Elle peut aussi être invitée à collecter vos données personnelles dans le cas où ce traitement serait nécessaire en vue d’une obligation légale à laquelle elle est soumise.

## Finalités de la collecte et fondements juridiques

| **Finalités de la collecte des données personnelles**                                                                                      |**Fondements juridiques à la collecte des données**                                                                                   |
| ------------------------------------------------------------------------------------------------------------------------------------------ | ------------------------------------------------------------------------------------------------------------------------------------ |
| Les informations collectées permettent la création d’un compte Nap&Up afin d’accéder aux différents services proposés par la plateforme    | **Article 6.1, a) RGPD :** le traitement des données est nécessaire à l’exécution du contrat, de la prestation de service.           |
| Les informations collectées permettent de réserver un créneau de sieste                                                                    | **Article 6.1, a) RGPD :** le traitement des données est nécessaire à l’exécution du contrat, de la prestation de service.           |
| Les informations collectées permettent d’assurer la mise en favoris des pistes audio                                                       | **Article 6.1, a) RGPD :** le traitement des données est nécessaire à l’exécution du contrat, de la prestation de service.           |
| Les informations collectées permettent l’amélioration de nos services et de notre plateforme                                               | **Article 6.1, f) RGPD :** le traitement est nécessaire en raison d’intérêts légitimes poursuivis par le responsable du traitement.  |

## Destinataire des données

Les données personnelles ainsi collectées sont uniquement destinées à la Société Nap&Up.

## Transfert de données à l'étranger

La Société Nap&Up ne transfert pas vos données dans un pays tiers à l’Union européenne.

Vos données sont hébergées en Belgique mais ne font l’objet d’un traitement que par nos services et notre sous-traitant en France.

## Cookies

Nap&Up n’utilise pas de cookies sur son site.

## Sécurité

Nap&Up assure la sécurité de vos données contre la divulgation, la perte, la destruction, l’altération et l’accès non autorisé. Ainsi que l’anonymisation de vos données à caractère personnel.  À cet effet, Nap&Up utilise les moyens suivants :

*   conservation de vos données sur une base de données non publique.
*   sécurisation de vos données par l’utilisation du modèle HTTPS sur toutes ses applications.
*   cryptage des mots de passes utilisateur

## Temps de conservation des données

Nap&Up s’engage à conserver vos données seulement pendant un temps strictement nécessaire aux finalités précédemment énoncées.

Ainsi, Nap&Up conservera vos données un an après la suppression de votre compte. Ensuite, les informations seront effacées de nos serveurs.

## Droits des personnes concernées

*   _Droit d’accès_ : toute personne concernée peut demander au responsable du traitement si ses données à caractère personnel sont belles et biens traitées (article 15 du RGPD).
  
*   _Droit à rectification_ : toute personne concernée peut demander au responsable du traitement que les données à caractère personnel qui la concernent et qui sont inexactes soient rectifiées dans les meilleurs délais (article 16 du RGPD).
  
*   _Droit à effacement_ : toute personne concernée peut demander au responsable du traitement à ce que ses données à caractère personnel soient effacées dans les meilleurs délais. Celui-ci ne pouvant s’y opposer (article 17 du RGPD).
  
*   _Droit à la limitation du traitement_ : toute personne peut demander au responsable du traitement à ce que ses données soient limitées dans le cas où celles-ci renverraient à l’une des situations énoncées à l'article 18 du règlement.
  
*   _Droit à la portabilité des données_ : toute personne concernée peut obtenir les données à caractère personnel la concernant et les communiquer à un autre responsable du traitement.
  
*   _Droit d’opposition_ : toute personne concernée peut en raison de sa situation personnelle s’opposer à tout moment à ce que des données à caractère personnel la concernant soient traitées, dans deux cas prévus aux points e et f de l'article 6 §1 du RGPD. Sauf exceptions prévues à l’article 21 du RGPD.
  
*   _Décision individuelle automatisée_ : toute personne concernée peut décider de ne pas avoir à faire l’objet d’une décision fondée sur un traitement automatisé comprenant également le profilage (article 22 RGDP).

Vous pouvez retrouver de plus amples informations sur le site de la CNIL, [https://www.cnil.fr/fr/les-droits-pour-maitriser-vos-donnees-personnelles]("https://www.cnil.fr/fr/les-droits-pour-maitriser-vos-donnees-personnelles")

  

Si vous souhaitez exercer l’un de ces droits, vous avez la possibilité de contacter la Société Nap&Up à l’adresse suivant: [contact@napandup.com]("mailto:contact@napandup.com")

Ou par voie postale: Nap&Up, 103 rue Jean Jaurès 92800 Puteaux

## Mise à jour de la politique de confidentialité

Nap&Up vous informe de sa possibilité de mettre à jour, à tout moment, sa politique de confidentialité pour se mettre en conformité avec la législation en cours.

La Société Nap&Up s’assurera que vous êtes bien informés de ces éventuelles modifications.

## Contact

Pour tout renseignement, signalement de contenus ou d’activités illicites, vous pouvez contacter Nap&Up par email à l’adresse suivante: [contact@napandup.com]("mailto:contact@napandup.com"), ou par voie postale à l’adresse suivante: Nap&Up, 103 rue Jean Jaurès 92800 Puteaux
`,
  en: `
Nap&Up aims at offering you its services while respecting your privacy and your personal data regarding the data protection law in force.

## Data Protection Policy

Nap&Up limits data collection strictly to what is necessary for the purposes of its activity.

Nap&Up commits itself to respect the personal data protection general terms provided by the REGULATION (EU) 2016/679 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (General Data Protection Regulation).

## Definitions

According to the European legislator, a “‘personal data’ means any information relating to an identified or identifiable natural person.” (article 4 GDPR)

And the ‘processing’ means “any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.” (article 4 GDPR)

## Processing responsible parties

Nap&Up, Simplified Joint Stock Company with a solvency capital of 7 796 € and whose head office is at 45 rue des Pavillons, 92800, Puteaux, RCS 824 004 485, is the service provider.

## Type of data collected

*   Data related to your identity (First name, Last name, Birthdate)
*   Contact data (Email, Password)
*   Data related to the company you are working in (Access code)
*   Data related to the nap time slots bookings (Day, Time, Cocoon name)
*   Data related to your favourites (Audio guide)
*   Data related to the platform use (Login, login duration, login time).

## Sensitive data

Nap&Up does not collect, neither process any sensitive data.

## Data collection time

Your data are collected by Nap&Up:

*   when signing in
*   when using the nap booking services
*   when you add as favourites the proposed audio guides.

## Data processing lawfulness

According to GDPR article 6.1, Nap&Up can process your personal data for one or more specific purposes for which you have given consent. Nap&Up can collect your personal data for the performance of a contract to which your are party. Nap&Up can also collect your personal data if processing is necessary for compliance with a legal obligation to which the controller is subject.

## Collection purposes and legitimate grounds

Collection purposes for data collection

Legitimate grounds for data collection

The data collected are used for Nap&Up account creation in order to access the different platform services offered.

Article 6.1, b) RPGD: processing is necessary for the performance of a contract.

The data collected are used to book a nap time slot.

Article 6.1, b) RPGD: processing is necessary for the performance of a contract.

The data collected are used to add audio guides to the favorites.

Article 6.1, b) RPGD: processing is necessary for the performance of a contract.

The data collected are used to improve our platform and services.

Article 6.1, b) RPGD: processing is necessary for the performance of a contract.

## Data recipients

Personal data collected are only addressed to Nap&Up, its web hosting and customer relations platform.

## Data transfer abroad

Nap&Up’s company does not transfer any of your data to any UE foreign country.

Your data are hosted in Belgium but are processed only by our services and our subcontractor in France.

## Cookies

Nap&Up does not use any cookie on its website.

## Security

Nap&Up ensures your data security against disclosure, loss, alteration and unauthorised access as well as the anonymity of your personal data. For this purpose, Nap&Up uses:

*   data storing on a non public database
*   security of data by using HTTPS for all its apps
*   user passwords encryption

## Data storing time

Nap&Up commits to store your data only for the time needed for the above exposed purposes.

Thus, Nap&Up will keep your data for one year after your account deletion. After this period, your data will be deleted from our servers.

## Right of access by the data subject

*   _Right of access_: the data subject shall have the right to obtain from the controller confirmation as to whether or not personal data concerning him or her are being processed (RPGD article 15).
  
*   _Right of rectification_: the data subject shall have the right to obtain from the controller without undue delay the rectification of inaccurate personal data concerning him or her. (RPGD article 16).
  
*   _Right of erasure_: the data subject shall have the right to obtain from the controller the erasure of personal data concerning him or her without undue delay and the controller shall have the obligation to erase personal data without undue delay (RPGD article 17).
  
*   _Right to restriction of processing_: the data subject shall have the right to obtain from the controller restriction of processing where one of the RPGD article 18 situation applies.
  
*   _Right to data portability_: the data subject shall have the right to receive the personal data concerning him or her, and have the right to transmit those data to another controller (RPGD article 20)
  
*   _Right to object_: the data subject shall have the right to object, on grounds relating to his or her particular situation, at any time to processing of personal data concerning him or her which is based on point (e) or (f) of Article 6(1). Except on RPGD article 21 situations.
  
*   _Automated individual decision-making, including profiling_: the data subject shall have the right not to be subject to a decision based solely on automated processing, including profiling (RPGD article 22).

You will find more information on the CNIL website, [https://www.cnil.fr/en/home](\\"https://www.cnil.fr/en/home\\")

  

If you want to exercise this right, you can contact Nap&Up’s company by email: [contact@napandup.com](\\"mailto:contact@napandup.com\\")

Or by post: Nap&Up, 103 rue Jean Jaurès 92800 Puteaux

## Confidentiality policy update

Nap&Up’s company may update its confidentiality policy at any time, to be in accordance with the law in force. Nap&Up’s company will ensure you are properly inform of theses possible updates.

## Contact

For any information needed, content report or unlawful activity, you can contact Nap&Up by email: [contact@napandup.com](\\"mailto:contact@napandup.com\\"), or by post: Nap&Up, 103 rue Jean Jaurès 92800 Puteaux
  `,
}
