import { useMemo } from 'react'

import { Store } from 'src/state'
import { useStoreState } from 'src/hooks/state'

import { firestore, firebase } from 'src/services/firebase'
import VersionNumber from 'src/services/version-number'

const pathPrefix = 'offline-first/offline-first'

export function getDocumentReferenceWithClaims(
  claims: { _id: string; space: string },
  collection: string,
  withSpace: boolean = false,
) {
  const parts = [pathPrefix]

  if (claims._id) {
    parts.push(collection, claims._id)
  }

  if (withSpace && claims.space) {
    parts.push('space', claims.space)
  }

  return firestore().doc(parts.join('/'))
}

export function getDocumentReference(
  collection: string,
  withSpace: boolean = false,
) {
  const claims = Store.getState().utils.session.claims

  return getDocumentReferenceWithClaims(claims, collection, withSpace)
}

export function getCollectionReference(collection: string) {
  return firestore().collection([pathPrefix, collection].join('/'))
}

export function useDocumentReference(
  collection: string,
  withSpace: boolean = false,
) {
  const claims = useStoreState((store) => store.utils.session.claims)

  return useMemo(
    () => getDocumentReferenceWithClaims(claims, collection, withSpace),
    [claims, collection, withSpace],
  )
}

export function useCollectionReference(collection: string) {
  return useMemo(() => getCollectionReference(collection), [collection])
}

export function upsertDocument(
  doc: firebase.firestore.DocumentReference,
  update: firebase.firestore.UpdateData,
  upsert = update,
) {
  return doc.update(update).catch((err) => {
    if (err.code.includes('not-found')) {
      return doc.set(upsert)
    }
    throw err
  })
}

export function getDateDocument(collection: string, date: Date) {
  const year = date.getFullYear() + ''
  const month = year + '-' + (date.getMonth() + 1)
  const part = Math.floor(date.getDate() / 3)

  const collectionReference = getCollectionReference(collection)

  return collectionReference
    .doc(month)
    .collection('parts')
    .doc('part' + part)
}

export function sendEvent(
  eventKind: 'audio-play' | 'audio-download' | 'no-slot-warning',
  event: Record<string, any>,
) {
  const date = new Date()

  event.user = Store.getState().utils.session.claims._id
  event.space = Store.getState().utils.session.claims.space
  event.date = date.toISOString()
  event.deviceInfo = VersionNumber

  const update = {
    [eventKind]: firestore.FieldValue.arrayUnion(
      JSON.parse(JSON.stringify(event)),
    ),
  }

  const document = getDateDocument('events', date)

  upsertDocument(document, update).catch((err) => console.error(err))
}
