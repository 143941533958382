import { useMemo } from 'react'
import { useStoreState } from './state'
import { Nap } from 'src/state/models/nap'
import {
  AudioBackground,
  AudioBook,
  AudioFavorite,
  AudioTheme,
  DurationRecord,
} from 'src/state/models/audio'

import * as Naps from 'src/helpers/naps'
import { useAudioConfiguration } from './use-audio-configuration'

const baseDurations = [10, 15, 20, 25, 30]

function trackDurations(tracks?: DurationRecord<unknown>) {
  return Object.keys(tracks ?? {})
    .map(Number)
    .filter(Number.isInteger)
}

function napDuration(nap: Nap | undefined) {
  const duration = Naps.getDuration(nap)
  return baseDurations.filter((d) => !duration || d <= duration)
}

function backgroundsDuration(backgrounds: AudioBackground[]) {
  return backgrounds.flatMap((bg) => trackDurations(bg.tracks))
}

function backgroundDuration(background: AudioBackground | undefined) {
  return baseDurations.filter(
    (d) => !background || trackDurations(background.tracks).includes(d),
  )
}

function themeDuration(theme: AudioTheme | undefined, locale: 'fr' | 'en') {
  return baseDurations.filter(
    (d) =>
      !theme ||
      trackDurations(
        theme.tracks[locale] || theme.tracks.fr || theme.tracks.en,
      ).includes(d),
  )
}

function bookDuration(bookChapter: AudioBook['chapters'][number] | undefined) {
  return baseDurations.filter((d) => {
    return !bookChapter || trackDurations(bookChapter.tracks).includes(d)
  })
}

function intersection(first: number[], second: number[]) {
  return first.filter((d) => second.includes(d))
}

function reduceThemes(
  themes: AudioTheme[],
  nap: Nap | undefined,
  locale: 'fr' | 'en',
  backgrounds: AudioBackground[],
) {
  const backgroundsDurations = backgroundsDuration(backgrounds)
  const napDurations = napDuration(nap)

  return themes.filter((theme) => {
    const themeTracks =
      theme.tracks[locale] || theme.tracks.fr || theme.tracks.en
    const themeDurations = trackDurations(themeTracks)

    return themeDurations.some(
      (d) => napDurations.includes(d) && backgroundsDurations.includes(d),
    )
  })
}

function reduceBooks(
  books: AudioBook[],
  nap: Nap | undefined,
  locale: 'fr' | 'en',
  backgrounds: AudioBackground[],
) {
  const backgroundsDurations = backgroundsDuration(backgrounds)
  const napDurations = napDuration(nap)

  return books.filter((book) => {
    return (
      book.language === locale &&
      book.chapters.some((chapter) => {
        const bookDurations = trackDurations(chapter.tracks)

        return bookDurations.some(
          (d) => napDurations.includes(d) && backgroundsDurations.includes(d),
        )
      })
    )
  })
}

function reduceBackgrounds(
  backgrounds: AudioBackground[],
  nap: Nap | undefined,
  locale: 'fr' | 'en',
  theme: AudioTheme | undefined,
  book: AudioBook | undefined,
  bookChapter: AudioBook['chapters'][number] | undefined,
) {
  const napDurations = napDuration(nap)
  const themeDurations = themeDuration(theme, locale)
  const bookDurations = bookDuration(bookChapter)
  const availableDurations = intersection(themeDurations, bookDurations)

  return backgrounds
    .filter((b) => !book?.backgrounds || book.backgrounds.includes(b.slug))
    .filter((b) => (!book && !theme) || !['music-only'].includes(b.mode!))
    .filter((b) =>
      trackDurations(b.tracks).some(
        (d) => napDurations.includes(d) && availableDurations.includes(d),
      ),
    )
}

function reduceFavorites(
  favorites: AudioFavorite[],
  themes: AudioTheme[],
  backgrounds: AudioBackground[],
) {
  return favorites.filter(
    (fav) =>
      (!fav.theme || themes.includes(fav.theme)) &&
      backgrounds.includes(fav.background),
  )
}

function reduceDurations(
  nap: Nap | undefined,
  locale: 'fr' | 'en',
  theme: AudioTheme | undefined,
  bookChapter: AudioBook['chapters'][number] | undefined,
  background: AudioBackground | undefined,
) {
  const napDurations = napDuration(nap)
  const themeDurations = themeDuration(theme, locale)
  const bookDurations = bookDuration(bookChapter)
  const availableDurations = intersection(themeDurations, bookDurations)
  const backgroundDurations = backgroundDuration(background)

  return backgroundDurations
    .filter((d) => napDurations.includes(d) && availableDurations.includes(d))
    .filter((d) => d !== 30 || bookChapter)
}

export function useAudioConstraints(
  forceConfiguration?: ReturnType<typeof useAudioConfiguration>,
) {
  const computedConfiguration = useAudioConfiguration()
  const configuration = forceConfiguration ?? computedConfiguration

  const locale = configuration.locale
  const wrongLocale = useStoreState(
    (store) => configuration.locale !== store.utils.device.locale,
  )
  const nap = useStoreState((store) =>
    store.nap.list.find((n) => n._id === configuration.napId),
  )
  const allThemes = useStoreState((store) => store.audio.themes)
  const allBooks = useStoreState((store) => store.audio.books)
  const allBackgrounds = useStoreState((store) => store.audio.backgrounds)
  const allFavorites = useStoreState((store) => store.audio.favorites)

  const theme = useMemo(
    () => allThemes.find((t) => t.slug === configuration.theme),
    [allThemes, configuration.theme],
  )
  const book = useMemo(
    () => allBooks.find((b) => b.slug === configuration.book),
    [allBooks, configuration.book],
  )
  const bookChapter = useMemo(
    () =>
      configuration.bookChapter! > -1
        ? book?.chapters[configuration.bookChapter!]
        : undefined,
    [book, configuration.bookChapter],
  )
  const background = useMemo(
    () => allBackgrounds.find((b) => b.slug === configuration.background),
    [allBackgrounds, configuration.background],
  )

  const nonMusicOnly = allBackgrounds.filter(
    (bg) => !['music-only'].includes(bg.mode!),
  )

  const themes = reduceThemes(allThemes, nap, locale, nonMusicOnly)
  const books = reduceBooks(allBooks, nap, locale, nonMusicOnly)
  const bookChapters = book?.chapters ?? []
  const backgrounds = reduceBackgrounds(
    allBackgrounds,
    nap,
    locale,
    theme,
    book,
    bookChapter,
  )
  const favorites = reduceFavorites(allFavorites, themes, backgrounds)
  const durations = reduceDurations(nap, locale, theme, bookChapter, background)
  const valid = durations.includes(configuration.duration as number)

  return {
    // Constraints
    themes,
    books,
    bookChapters,
    backgrounds,
    favorites,
    durations,

    // Selection
    nap,
    locale,
    theme,
    book,
    bookChapter,
    bookChapterIndex: configuration.bookChapter,
    background,
    duration: configuration.duration,
    valid,
    wrongLocale,
  }
}
