import React from 'react'

import { useWindowDimensions } from 'react-native'

import BackgroundLight from 'src/resources/backgrounds/light-bottom.webp'
import BackgroundDark from 'src/resources/backgrounds/dark-bottom.webp'
import BackgroundDesktopLight from 'src/resources/backgrounds/light-bottom-desktop.webp'
import BackgroundDesktopDark from 'src/resources/backgrounds/dark-bottom-desktop.webp'
import MoonLight from 'src/resources/backgrounds/light-top.webp'
import MoonDark from 'src/resources/backgrounds/dark-top.webp'
import MoonDesktopLight from 'src/resources/backgrounds/light-top-desktop.webp'
import MoonDesktopDark from 'src/resources/backgrounds/dark-top-desktop.webp'

import { buildComponent } from 'src/components/factory'
import { useResponsive } from 'src/hooks/use-responsive'

import * as UI from 'native-base'
import * as Semantics from 'src/components/semantics'

export const Background = buildComponent<{
  theme?: 'night' | 'day'
  tabBar?: boolean
  topBar?: boolean
}>()
  .withStyles({
    container: {
      main: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#98a7cb',
      },
      custom: {
        dark: {
          backgroundColor: '#1a224d',
        },
      },
    },
    background: {
      main: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        maxWidth: '100%',
        resizeMode: 'contain',
      },
    },
    moon: {
      main: {
        position: 'absolute',
        width: '100%',
        maxWidth: '100%',
        resizeMode: 'contain',
      },
    },
  })
  .withLifecycle(({ props }) => {
    const { width } = useWindowDimensions()
    const { isDesktop, isDesktopWidth } = useResponsive()

    const backgroundHeight = isDesktopWidth
      ? (width / 1920) * 941
      : (width / 794) * 834
    const moonHeight = isDesktopWidth ? 96 : (width / 595) * 113

    const backgroundImage = {
      day: BackgroundLight,
      night: BackgroundDark,
    }[props.theme ?? 'day']
    const backgroundImageDesktop = {
      day: BackgroundDesktopLight,
      night: BackgroundDesktopDark,
    }[props.theme ?? 'day']
    const moonImage = {
      day: MoonLight,
      night: MoonDark,
    }[props.theme ?? 'day']
    const moonImageDesktop = {
      day: MoonDesktopLight,
      night: MoonDesktopDark,
    }[props.theme ?? 'day']

    return {
      key: isDesktopWidth ? 'desktop' : 'mobile',
      backgroundImage: isDesktopWidth
        ? backgroundImageDesktop
        : backgroundImage,
      moonImage: isDesktopWidth ? moonImageDesktop : moonImage,
      backgroundStyle: {
        height: backgroundHeight,
        bottom: !isDesktop && props.tabBar ? 86 : 0,
      },
      moonStyle: {
        height: moonHeight,
        width: isDesktopWidth ? 256 : '100%',
        top: props.topBar ? 64 : 32,
        right: isDesktopWidth ? 0 : undefined,
      },
    }
  })
  .withRender(({ props, styles, lifecycle }) => {
    return (
      <UI.Box style={styles.container({ dark: props.theme === 'night' })}>
        <UI.Box safeAreaTop />
        <UI.Box flex={1}>
          <Semantics.Image
            key={lifecycle.key + '-background'}
            alt=" "
            style={[styles.background(), lifecycle.backgroundStyle]}
            source={lifecycle.backgroundImage}
          />
          <Semantics.Image
            key={lifecycle.key + '-moon'}
            alt=" "
            style={[styles.moon(), lifecycle.moonStyle]}
            source={lifecycle.moonImage}
          />
        </UI.Box>
      </UI.Box>
    )
  })
