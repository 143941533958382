export const LEGAL_NOTICES = {
  fr: `
Conditions générales d’utilisation du site internet Nap&Up, ainsi que des prestations offertes.

La société Nap & Up SAS propose de la location d’espaces de siestes, ainsi que des prestations liées au bien être. Une liste exhaustive des prestations est proposée sur le site internet Nap&Up. [www.nap-and-up.com](\\"www.nap-and-up.com\\").

Les bénéficiaires des services sont des entreprises souhaitant offrir aux collaborateurs un moment de bien être. Les utilisateurs sont les personnes usant des services Nap&Up, les salariés des entreprises par exemple.

## ARTICLE 1 : Objet

Les présentes « conditions générales d’utilisation » (ci-après « CGU ») ont pour objet l’encadrement juridique des modalités de mise à disposition des services du site nap-and-up.com (ci-après « le Site ») et leur utilisation par « l’Utilisateur ».

L’accès et l’utilisation du Site sont soumis à l’acceptation et au respect des présentes CGU par l’Utilisateur, quel que soit l’objet de sa visite. En visitant ce Site et/ou en l’utilisant, l’Utilisateur sera présumé avoir lu les présentes CGU, les avoir entièrement comprises et en accepter pleinement l’ensemble des dispositions sans restriction ni réserve.

*   En cas de non-acceptation des conditions générales d’utilisation stipulées dans le présent contrat, l’Utilisateur se doit de renoncer à l’accès des services proposés par le site.
*   nap-and-up.com se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes conditions générales d’utilisation. Pour être informé des éventuelles modifications, l’Utilisateur doit se référer avant toute utilisation du Site à la dernière version des CGU accessible à tout moment sur le Site. L’utilisation du Site sera soumise aux CGU en vigueur au moment de cette utilisation.

Dans l’hypothèse où l’une des clauses des présentes CGU serait nulle du fait d’un changement de législation ou de réglementation ou déclarée comme telle par une décision de justice définitive, cela ne saurait en aucun cas affecter la validité et le respect des autres clauses des CGU.

Nap&Up ne saurait être tenu pour responsable de tout dommage, quelle qu’en soit la nature, résultant de son indisponibilité. L’Internaute reconnait expressément que les interruptions, suspensions ou limitations susmentionnées pourront intervenir à tout moment, sans qu’il ait été préalablement averti, et qu’elles n’ouvriront droit à aucune obligation, ni indemnisation, à son profit.

## ARTICLE 2 : Mentions légales

L’édition et l'hébergement du site napandup.com est assurée par la Société Nap&Up, SAS au capital de 6000€ dont le siège social est situé au 103 rue Jean Jaurès 92800 Puteaux

RCS 824 004 485

La Directrice de la publication est Madame Desclée de Maredsous Camille.

## ARTICLE 3 : Définitions

La présente clause a pour objet de définir les différents termes essentiels du contrat :

*   Utilisateur : ce terme désigne toute personne qui utilise le site ou l’un des services proposés par le site.
*   Contenu utilisateur : ce sont les données transmises par l’Utilisateur au sein du site

## ARTICLE 4 : Accès aux services

Le site permet à l’Utilisateur un accès gratuit aux services suivants :

*   pouvoir réserver des siestes
*   pouvoir annuler des siestes
*   avoir accès aux audios

Le Site est accessible gratuitement à tout internaute, personne physique ou morale accédant, visitant et/ou utilisant le Site, quel que soit le réseau ou le moyen utilisé (ci-après l’ « Utilisateur »). L’ensemble des coûts liés à l’accès au Site et à ses contenus, ainsi qu’à leur utilisation, qu’il s’agisse des frais matériels, logiciels ou d’accès à internet sont exclusivement à la charge de l’Internaute.

Le site met en œuvre tous les moyens mis à sa disposition pour assurer un accès de qualité à ses services. L’obligation étant de moyens, le site ne s’engage pas à atteindre ce résultat.

Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du réseau ou du serveur n’engage pas la responsabilité de Nap&Up

L’accès aux services du site peut à tout moment faire l’objet d’une interruption, d’une suspension, d’une modification sans préavis pour une maintenance ou pour tout autre cas. L’Utilisateur s’oblige à ne réclamer aucune indemnisation suite à l’interruption, à la suspension ou à la modification du présent contrat.

Il est rappelé que le fait d’accéder ou de se maintenir frauduleusement dans un système informatique, d’entraver ou de fausser le fonctionnement d’un tel système, d’introduire ou de modifier frauduleusement des données dans un système informatique constitue des délits passibles de sanctions pénales.

L’Utilisateur a la possibilité de contacter le site par messagerie électronique à l’adresse [contact@napandup.com](\\"mailto:contact@napandup.com\\")

## ARTICLE 5 : Récupération des données de l’utilisateur

L’inscription au service de réservation d’une sieste Nap&Up est une inscription gratuite, qui, dès l’accord de son utilisateur permet à celui-ci de :

*   pouvoir réserver sa sieste
*   pouvoir annuler sa sieste
*   avoir accès aux audios

Nap&Up s’engage à ne pas utiliser l’adresse mail pour toute autre action de type newsletter, mail, prospects et à ne pas communiquer les informations à d’autres tiers.

## ARTICLE 6 : Propriété intellectuelle

L’ensemble de ce site relève de la législation internationale sur le droit d’auteur, le droit des marques et, de façon générale, sur la propriété intellectuelle, en ce qui concerne chacun des éléments de son contenu (textes, images, données, dessins, graphiques, photos et bandes sonores, balises métas, codes sources…) qu’en ce qui concerne sa forme (choix, plan, disposition des matières, moyens d’accès aux données, organisation des données…). Ces contenus, figurant sur les pages de ce site, sont la propriété exclusive de Nap&Up.

La dénomination Nap&Up est une marque déposée par Nap&Up. Toute représentation et/ou reproduction et/ou exploitation partielle ou totale de cette marque, de quelque nature que ce soit, est totalement prohibée.

La reproduction ou représentation, intégrale ou partielle, des pages, des données, des balises HTML et de tout autre élément constitutif au site, par quelque procédé ou support que ce soit, est interdite et constitue, sans autorisation expresse et préalable de l’éditeur, une contrefaçon sanctionnée par les articles L335-2 et suivants du Code de Propriété Intellectuelle.

L’utilisation du Site ne confère en aucune façon à l’Internaute un droit de propriété ou un droit de propriété intellectuelle sur le Site et/ou les contenus et éléments se trouvant sur le Site, à l’exception d’un droit personnel d’accès, gratuit et non exclusif, limité exclusivement à la consultation du Site et de ses contenus et éléments. Toute reproduction totale ou partielle de cette marque ou de ces logos, effectuée à partir des éléments du site sans l’autorisation expresse et préalable de Nap&Up est donc prohibée, au sens de l’article L713-2 du Code de la Propriété Intellectuelle. De même, toute utilisation du contenu et du site à des fins illégales fera l’objet de poursuites judiciaires à l’égard des contrevenants.

Toute reproduction, représentation, diffusion ou rediffusion, en tout ou partie, du contenu de ce site sur quelque support ou par tout procédé que ce soit, de même que toute vente, revente, retransmission ou mise à disposition de tiers de quelque manière que ce soit sont interdites. Le non-respect de cette interdiction constitue une contrefaçon susceptible d’engager la responsabilité civile et pénale du contrefacteur.

## ARTICLE 7 : Responsabilité et force majeure

Les sources des informations diffusées sur le site sont réputées fiables. Toutefois, le site se réserve la faculté d’une non-garantie de la fiabilité des sources. Les informations données sur le site le sont à titre purement informatif. Ainsi, l’Utilisateur assume seul l’entière responsabilité de l’utilisation des informations et contenus du présent site.

Tout usage du service par l’Utilisateur ayant directement ou indirectement pour conséquence des dommages doit faire l’objet d’une indemnisation au profit du site.

Une garantie optimale de la sécurité et de la confidentialité des données transmises n’est pas assurée par le site. Toutefois, le site s’engage à mettre en œuvre tous les moyens nécessaires afin de garantir au mieux la sécurité et la confidentialité des données.

La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d’un tiers.

## ARTICLE 8 : Liens hypertextes

Le Site peut contenir des liens hypertextes vers des sites internet exploités par des tiers. Ces liens sont fournis à simple titre d’information, ils ne sont pas régis par la présente Politique de protection des données à caractère personnel. Nap&Up vous invite par conséquent à examiner les règles relatives aux données personnes applicables à ces sites internet. Nap&Up n’exerce aucun contrôle quant au contenu de ces sites tiers et décline toute responsabilité quant à l’accès, à la consultation des contenus, publicités, produits, services ou toute autre information ou donnée, disponibles sur ou à partir de ces sites, ou quant à l’utilisation de ces sites. La décision d’activer ces liens relève en conséquence de votre pleine et entière responsabilité. Nap&Up ne pourra être tenue responsable de tous dommages ou pertes avérés ou allégués consécutifs ou en relation avec l’utilisation ou le fait d’avoir fait confiance aux contenus, à des biens ou des services disponibles sur ces sites.

Vous n’êtes pas autorisé à créer de lien hypertexte vers le Site. La mise en place de liens hypertextes d’un site tiers vers le Site nécessite l’accord préalable et exprès de Nap&Up, sollicité par email à l’adresse suivante : [contact@napandup.com](\\"mailto:contact@napandup.com\\").

## ARTICLE 9 : Réseaux sociaux

Le Site utilise les plug-in des réseaux sociaux suivants :

*   Facebook®, exploité par la société Facebook Inc., dont le siège social est sis 1601 S. California Ave, Palo Alto, CA 94304, États-Unis (« Facebook ») ;
*   Twitter®, exploité par la société Twitter Inc., dont le siège social est sis 1355 Market Street, Suite 900, San Francisco, CA 94103, États-Unis (« Twitter »).
*   Instagram®, exploité par la société Instagram LLC, dont le siège social est sis 1 Hacker Way, Menlo Park, CA 94025, Etats-Unis (« Instagram).
*   LinkedIn®, exploité par la société LinkedIn Ireland, Wilton Plaza, Wilton Place, Dublin 2, Irlande

Lorsque vous interagissez au moyen de ces plug-in, votre navigateur établit une connexion directe avec les serveurs du réseau social correspondant. Le contenu du plug-in est aussitôt transmis par votre navigateur au réseau social et enregistré sur ses serveurs. Par l’intégration de ce plug-in, le réseau social est informé que vous avez consulté notre Site. Il peut ainsi associer votre navigation sur le Site à votre compte utilisateur de ce réseau social. Si vous ne souhaitez pas que le réseau social collecte des données vous concernant par l’intermédiaire du Site et les relie à votre compte utilisateur, vous devez vous déconnecter du réseau social avant de visiter le Site. Si vous n’êtes pas membre du réseau social, il est néanmoins possible qu’il récupère et enregistre des données personnelles vous concernant et, notamment, votre adresse IP.

En tout état de cause, Nap&Up n’a aucun contrôle sur le contenu exact des données ainsi collectées. L’utilisation des plug-in est exclusivement opérée respectivement par Facebook, Twitter et Instagram et régie par les conditions générales d’utilisation de ces réseaux sociaux, disponibles aux adresses suivantes :

*   Pour Facebook : [https://fr-fr.facebook.com/policies/](\\"https://fr-fr.facebook.com/policies/\\")
*   Pour Twitter : [https://twitter.com/tos?lang=fr](\\"https://twitter.com/tos?lang=fr\\")
*   Pour Instagram : [https://help.instagram.com/478745558852511](\\"https://help.instagram.com/478745558852511\\")
*   Pour Linkedin : [https://www.linkedin.com/legal/user-agreement](\\"https://www.linkedin.com/legal/user-agreement\\")

Facebook® est une marque déposée et appartient exclusivement à la société Facebook Inc.

Twitter® est une marque déposée et appartient exclusivement à la société Twitter Inc.

Instagram® est une marque déposée et appartient exclusivement à la société Instagram LLC.

Linkedin® est une marque déposée et appartient exclusivement à la société Linkedin Corporation.

## ARTICLE 10 : Évolution du contrat

Le site se réserve à tout moment le droit de modifier les clauses stipulées dans le présent contrat.

## ARTICLE 11 : Durée

La durée du présent contrat est indéterminée. Le contrat produit ses effets à l’égard de l’Utilisateur à compter de l’utilisation du service.

## ARTICLE 12 : Droit applicable et juridiction compétente

Les présentes CGU sont régies exclusivement par la loi française.

## ARTICLE 13 : Contact

Pour tout renseignement, signalement de contenus ou d’activités illicites, l’Utilisateur peut contacter Nap&Up par email à l’adresse suivante : [contact@napandup.com](\\"mailto:contact@napandup.com\\"), ou par voie postale à l’adresse suivante : Nap&Up, 103 rue Jean Jaurès 92800 Puteaux
`,
  en: `
General terms and conditions of use - Nap&Up

## ARTICLE 1: Object

he purpose of these General Terms and Conditions of Use (onwards referred to as GTCU) is to have a legal regulation regarding the terms in which the services of the website nap-and-up.com (onwards referred to as «\u00A0the Website\u00A0») are available and their usage by «\u00A0the User\u00A0».

The access to and the usage of the Website are subject to the acceptation and respect of these GTCU by the User, whatever the purpose of his visit may be. By visiting this Website and/or by using it, the User is presumed to have read these GTCU, to fully understand them and to accept the dispositions entirely, without limitations and unreservedly.

*   In case one does not accept the general terms and conditions defined in this contract, the User must give up having access to the services provided by the Website.
*   nap-and-up.com reserves the right to change the content of these GTCU unilaterally and at any point given in time. To be informed of potential modifications, the User must refer to the newest version of the GTCU available on the Website before using the Website services. The usage of the Website will be subject the GTCU in effect at that time.

If an alteration of legislation or regulation results in the annulment of one of the clauses of these GTCU or if a definite justice decision declares one of the clauses as null, it will by no means affect the validity and the respect of the other clauses of the GTCU.

Nap&Up will not be held responsible for any damage, whatever its nature may be, caused by its unavailability. The web user acknowledges that the interruptions, suspensions or limitations mentioned above could occur at any moment, without him having prior notice, and that they will not give way to any obligation or compensation in his favor.

## ARTICLE 2: Legal notices

napandup.com is edited and hosted by Nap&Up, Simplified Joint Stock Company with a solvency capital of 6 000€ whose head office is at 45 rue des Pavillons, 92800, Puteaux.

RCS\u00A0: 824 004 485

The director of publication is Mrs Desclée de Maredsous Camille.

## ARTICLE 3: Definitions

The purpose of this clause is to define the different terms that are essential to the contract:

*   User: this term designates any person who uses the Website or one of the services offered by the Website.
*   User content: the data transmitted by the user within the Website.

## ARTICLE 4: Access to the services

The Website gives the User free access to the following services:

*   book naps
*   cancel naps
*   get access to the audios

The Website is freely accessible to any web user, natural or legal person who accesses, visits and/or uses the Website, regardless of the network or the means used (onward referred to as the «\u00A0User\u00A0»). All costs associated with accessing the Website and its contents, as well as their use, let it be equipment, software or internet costs are exclusively the User’s responsibility.

The Website does its best to assure a quality access to its services. The Website is obliged to implement the necessary measured to assure a quality access but not to succeed.

Nap&Up shall not be held liable for any event due to force majeure resulting in the malfunction of the network or the server.

The access to the services can be interrupted, suspended or amended at any time and without prior notice for maintenance or any other reason. The User complies to not asking any indemnity following the interruption, suspension or amendment of this contract.

We remind you that fraudulently accessing and staying in a computer system, to impede and distort the system’s functioning, to fraudulently introduce or modify data are criminal offenses punishable by law.

The User can contact the Website by e-mail at [contact@napandup.com](\\"mailto:contact@napandup.com\\")

## ARTICLE 5: Customer data recovery

Registering to the booking service of a Nap&Up nap is free and allows the User to:

*   book a nap
*   cancel a nap
*   get access to the audios

Nap&Up commits to not using the e-mail address for any other purpose such as a newsletter, e-mails, flyers and to not communicating the information to a third party.

## ARTICLE 6: Intellectual property

This entire Website is conditioned by international copyright legislation, trademark rights and in broad terms intellectual property with regard to each element of its content (texts, pictures, information, drawings, graphics, photos and soundtracks, meta tags, source codes…), with regard to its form (choice, plan, arrangement of its contents, means of accessing data, organization of data…). These contents, featured on the web page, are Nap&Up’s exclusive property.

The name Nap&Up is a registered trademark by Nap&Up. Any representation and/or copying and/or partial or complete exploitation of this brand, of whatever nature, is entirely prohibited.

Any representation and/or copying, partially or completely, of pages, data, HTML tags and of any other constituent element of the web page, by whatever procedure or medium, is prohibited. Without clear prior consent by the editor, this is defined as counterfeiting and is punishable by the articles L335-2 and onwards of the Code de Propriété Intellectuelle.

Using the Website does by no means give the User a right of property or a right of intellectual property on the Website and/or on the contents and elements present on the Website with the exception of having a personal access right, free and non exclusive, exclusively limited to consulting the Website and its contents and elements. Any partial or complete reproduction of this brand and its logos, based on elements of the website and without clear prior consent by Nap&Up is prohibited, under the article L713-2 of the French Intellectual Property Code.\u00A0Furthermore, using the content of the Website, or the Website itself for illegal purposes will be subject to legal proceedings of the offenders.

Any reproduction, representation, diffusion or rediffusion, partially or completely, of the contents of the Website by whatever procedure or medium, as well as any selling, reselling, retransmission or putting at someone’s disposal the contents of the Website are prohibited.\u00A0Not respecting this prohibition is considered counterfeit prone to engage the civil and penal liability of the offenders.

## ARTICLE 7: Responsibility and force majeure

The information sources shared on the Website are reputed to be reliable. Nonetheless, the Website reserves the right to not guarantee the reliability of the sources. The information given on the Website is for informative purposes only. Thus, the User takes responsibility for the information and content of the Website he/she uses.

If the User’s use of the service causes direct or indirect damages, the User must compensate Nap&Up.

An optimum guarantee of data security and confidentiality transmitted is not provided by the Website. Nonetheless, the Website commits to implementing the necessary measures to guarantee the best data safety and confidentiality.

The Website can not be held responsible in case of force majeure or of an unpredictable or insurmountable occurrence by a third party.

## ARTICLE 8: Hyperlinks

The Website can contain hyperlinks to websites operated by a third party. These links are given for informative purposes only, they are not governed by the present Personal information protection policy. Nap&Up therefore invites you to examine the rules regarding personal data applicable to those websites. Nap&Up has no control over the contents of those third party websites and declines all responsibility regarding the access, the consultation of contents, advertisements, products, services or any other information or data available on or from those websites as well as the use of those websites. The decision to activate those hyperlinks is your own responsibility. Nap&Up will not be held responsible for any proven or alleged damages or loss consequential of or related to using or having trusted the contents, the services or products available on those websites.

You are not authorized to create a hyperlink towards the Website. The establishment of hyperlinks from a third party website towards the Website needs Nap&Up’s prior express consent, which can be solicited by e-mail at the following address: [contact@napandup.com](\\"mailto:contact@napandup.com\\").

## ARTICLE 9: Social networks

The Website uses the following social networks’ plug-ins:

*   Facebook®, operated by Facebok Inc., whose head office is at 1601 S. California Ave, Palo Alto, CA 94304, United States («\u00A0Facebook\u00A0»)\u00A0;
*   Twitter®, operated by Twitter Inc.\u00A0; whose head office is at 1355 Market Street, Suite 900, San Fransisco, CA 94103, United States («\u00A0Twitter\u00A0»)\u00A0;
*   Instagram®, operated by Instagram Inc., whose head office is at 1 Hacker Way, Menlo Park, CA 94025, United States («\u00A0Instagram\u00A0») ;
*   LinkedIn®, operated by LinkedIn Ireland, Wilton Plaza, Wilton Place, Dublin 2, Ireland.

When you interact by using these plug-ins, your web browser establishes a direct connection with the web server of the corresponding social network. The content of the plug-in is instantly transmitted by your web browser to the social network and saved in its web servers. By integrating this plug-in, the social network is informed that you have visiting our Website. It can thereby associate your web browsing on the Website to your account on that social network. If you do not want the social network to collect your data through the Website, you must sign out of that social network before visiting the Website. If you are not a member of the social network, it is nonetheless possible that it can recover and save personal data about you, such as your IP address.\u00A0

Either way, Nap&Up has no control over the exact content of the collected data. The use of the plug-ins is exclusively operated by Facebook, Twitter and Instagram and governed by the general terms and conditions of these social networks available at the following addresses:

*   For Facebook : [https://fr-fr.facebook.com/policies/](\\"https://fr-fr.facebook.com/policies/\\")
*   For Twitter : [https://twitter.com/tos?lang=fr](\\"https://twitter.com/tos?lang=fr\\")
*   For Instagram : [https://help.instagram.com/478745558852511](\\"https://help.instagram.com/478745558852511\\")
*   For Linkedin : [https://www.linkedin.com/legal/user-agreement](\\"https://www.linkedin.com/legal/user-agreement\\")

Facebook®\u00A0is a registered trademark and belongs exclusively to Facebook Inc.

Twitter®\u00A0is a registered trademark and belongs exclusively to Twitter Inc.

Instagram®\u00A0is a registered trademark and belongs exclusively to Instagram Inc.

LinkedIn®\u00A0is a registered trademark and belongs exclusively to LinkedIn Corporation.

## ARTICLE 10: Evolution of the contrat

The Website reserves the right to modify the clauses stipulated in this contract at any given time.

## ARTICLE 11: Duration

The duration of this contract is undetermined. The contract takes effect the moment the User starts using the service.

## ARTICLE 12: Applicable law and competent jurisdiction

These GTCU are exclusively governed by the French law.

## ARTICLE 13 : Contact

For any information or reporting of illicit contents or activities, the User can contact Nap&Up by\u00A0e-mail at the following address:\u00A0[contact@napandup.com](\\"mailto:contact@napandup.com\\"), or by post at the following address: Nap&Up, 103 rue Jean Jaurès 92800 Puteaux
  `,
}
