import React, { useCallback, useRef, useState } from 'react'

import {
  StackActions,
  useFocusEffect,
  useLinkProps,
  useNavigation,
  useRoute,
} from '@react-navigation/native'
import { buildComponent } from 'src/components/factory'
import { Button as NBButton } from 'native-base'
import { Button } from './button'

export const LinkButton = buildComponent<
  Parameters<typeof Button>[0] &
    (
      | {
          to: string
          reset?: boolean
          toAction?: 'push' | 'replace'
          params?: Record<string, any>
          back?: never
          popToTop?: never
          mergeParams?: boolean
        }
      | {
          to?: never
          reset?: never
          toAction?: never
          params?: never
          back: true
          popToTop?: never
          mergeParams?: never
        }
      | {
          to?: never
          reset?: never
          toAction?: never
          params?: never
          back?: never
          popToTop: true
          mergeParams?: never
        }
    )
>().withRender(
  ({
    props: {
      to,
      toAction,
      params,
      mergeParams,
      back,
      popToTop,
      children,
      reset,
      ...rest
    },
    ref,
  }) => {
    const navigation = useNavigation()
    const state = useRoute().params

    const [canGoBack, setCanGoBack] = useState(navigation.canGoBack())

    useFocusEffect(
      useCallback(() => {
        setCanGoBack(navigation.canGoBack())
      }, [navigation]),
    )

    const linkProps = useLinkProps({
      to: to ?? '/',
      action:
        back || popToTop
          ? canGoBack
            ? popToTop
              ? StackActions.popToTop()
              : StackActions.pop()
            : StackActions.replace('/')
          : StackActions[toAction ?? 'push'](
              to ?? '/',
              mergeParams ? { ...state, ...params } : params,
            ),
    })

    const wasClicked = useRef(false)

    const handlePress = (e: any) => {
      if (wasClicked.current) {
        return
      }
      wasClicked.current = true
      setTimeout(() => {
        wasClicked.current = false
      }, 1000)

      if (reset && canGoBack) {
        navigation.dispatch(StackActions.popToTop())
      }

      rest.onPress?.(e)
      linkProps.onPress(e)
    }
    useFocusEffect(
      useCallback(() => {
        wasClicked.current = false
      }, []),
    )

    if (['unstyled', 'ghost', 'solid'].includes(rest.variant as string)) {
      return (
        <NBButton
          ref={ref as any}
          {...linkProps}
          {...rest}
          onPress={handlePress}
        >
          {children}
        </NBButton>
      )
    }

    return (
      <Button ref={ref} {...linkProps} {...rest} onPress={handlePress}>
        {children}
      </Button>
    )
  },
)
