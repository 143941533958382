import { Action, Set } from 'src/state/types'
import { set } from 'src/state/utils'
import { action } from 'easy-peasy'

export interface User {
  _id: string
  email: string
  level: number
  totalNaps: number
  totalMinutes: number
}

export interface Preferences {
  locale?: 'fr' | 'en' | 'auto'
  hideAudioHelp?: boolean
  onboarded?: boolean
}

interface ModelProfile {
  // State
  user: User | null
  preferences: Preferences | null

  // Actions
  setUser: Set<this, 'user'>
  setPreferences: Set<this, 'preferences'>
  updatePreferences: Action<this, Partial<NonNullable<this['preferences']>>>
}

export const modelProfile: ModelProfile = {
  // State
  user: null,
  preferences: null,

  // Actions
  setUser: set('user'),
  setPreferences: set('preferences'),
  updatePreferences: action((state, payload) => {
    if (!state.preferences) {
      state.preferences = payload as ModelProfile['preferences']
    } else {
      Object.keys(payload).forEach((k) => {
        const key = k as keyof ModelProfile['preferences']
        state.preferences![key] = payload[key]
      })
    }
  }),
}
