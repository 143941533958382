import * as Firestore from 'src/helpers/firestore'

import { useStore } from 'src/hooks/state'
import { useOnMount } from 'src/hooks/use-on-mount'

export function useDatasourceDynamicContent() {
  const Store = useStore()

  // Keep references to the content documents
  const dynamicContent = Firestore.useCollectionReference('dynamic-content')

  // On mount, load dynamic content and sync to store
  useOnMount(() =>
    dynamicContent.doc('legalNotices').onSnapshot((doc) => {
      const legalNotices = doc?.data()

      if (legalNotices) {
        Store.getActions().dynamicContent.setLegalNotices(legalNotices as any)
      } else {
        Store.getActions().dynamicContent.setLegalNotices(null)
      }
    }),
  )
  useOnMount(() =>
    dynamicContent.doc('privacyPolicy').onSnapshot((doc) => {
      const privacyPolicy = doc?.data()

      if (privacyPolicy) {
        Store.getActions().dynamicContent.setPrivacyPolicy(privacyPolicy as any)
      } else {
        Store.getActions().dynamicContent.setPrivacyPolicy(null)
      }
    }),
  )
  useOnMount(() =>
    dynamicContent.doc('faq').onSnapshot((doc) => {
      const faq = doc?.data()

      if (faq) {
        Store.getActions().dynamicContent.setFaq(faq as any)
      } else {
        Store.getActions().dynamicContent.setFaq(null)
      }
    }),
  )
}
