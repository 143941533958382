import { useRef, useCallback } from 'react'

export function useStableCallback<P extends unknown[], R>(
  cb: (...params: P) => R,
  forceReload: unknown[] = [],
) {
  const cbRef = useRef(cb)
  cbRef.current = cb

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback((...params: P) => cbRef.current(...params), forceReload)
}
