import { computed } from 'easy-peasy'
import { Set, Computed } from 'src/state/types'
import { set } from 'src/state/utils'

import { AudioBook, AudioTheme, AudioBackground } from './audio'

export type Days =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'

export interface Room {
  _id: string
  localizedName?: {
    fr: string
    en: string
  }
  localizedDescription?: {
    fr: string
    en: string
  }
}

export interface Cocoon {
  _id: string
  version: number
  localizedName?: {
    fr: string
    en: string
  }
  localizedDescription?: {
    fr: string
    en: string
  }
  room: {
    _id: string
  }
}

export interface Space {
  _id: string
  localizedName: {
    fr: string
    en: string
  }
  description: string
  rooms: Room[]
  cocoons: Cocoon[]
  options?: {
    napsPerDay?: number
    napDurations?: {
      duration10?: boolean
      duration15?: boolean
      duration20?: boolean
      duration25?: boolean
    }
    napDays: Record<Days, { startHour?: number; endHour?: number }>
  }
  subscription?: {
    start?: string
    end?: string
    premium?: boolean
    disabled?: {
      audio?: boolean
      profile?: boolean
      booking?: boolean
    }
    audio: {
      themes: AudioTheme[]
      backgrounds: AudioBackground[]
      books: AudioBook[]
    }
  }
}

export interface BookedSlot {
  start: string
  end: string
  nap?: string
}

interface ModelSpace {
  // State
  loaded: boolean
  all: Space[]
  id: string
  info: Computed<this, Space | null>
  bookedSlots: Record<string, BookedSlot[]>
  expiration: Record<string, number>
  bookingDisabled: Computed<this, boolean>
  profileDisabled: Computed<this, boolean>

  // Actions
  setLoaded: Set<this, 'loaded'>
  setAll: Set<this, 'all'>
  setId: Set<this, 'id'>
  setBookedSlots: Set<this, 'bookedSlots'>
  setExpiration: Set<this, 'expiration'>
}

export const modelSpace: ModelSpace = {
  // State
  loaded: false,
  all: [],
  id: '',
  info: computed(
    (state) => state.all.find((space) => space._id === state.id) ?? null,
  ),
  bookedSlots: {},
  expiration: {},
  bookingDisabled: computed(
    (state) =>
      !!state.info?.subscription?.disabled?.booking ||
      !!state.info?.subscription?.disabled?.profile ||
      (state.loaded && !!state.info && !state.info?.cocoons?.length),
  ),
  profileDisabled: computed(
    (state) => !!state.info?.subscription?.disabled?.profile,
  ),

  // Actions
  setLoaded: set('loaded'),
  setAll: set('all'),
  setId: set('id'),
  setBookedSlots: set('bookedSlots'),
  setExpiration: set('expiration'),
}
