import React, { MutableRefObject, useCallback, useState } from 'react'

import { colors } from 'src/theme'
import { buildComponent } from 'src/components/factory'

import * as UI from 'native-base'

export const Button = buildComponent<
  UI.IButtonProps & {
    stacked?: boolean | 'pressed' | 'rest'
    opaque?: boolean
    shadowProps?: UI.IBoxProps
  }
>()
  .withStyles({
    shadow: {
      main: {},
      custom: {
        pressed: {
          top: 2,
        },
        disabled: {
          opacity: 0.75,
        },
        stacked: {
          bottom: 0,
        },
        stackedPressed: {
          top: 0,
        },
        primary: {
          backgroundColor: colors.primary['800'],
        },
        secondary: {
          backgroundColor: '#dbb136',
        },
        danger: {
          backgroundColor: '#c62d1d',
        },
        dark: {
          backgroundColor: '#242c57',
        },
        'dark-inverted': {
          backgroundColor: colors.dark['400'],
        },
        white: {
          backgroundColor: '#FFFFFFBB',
        },
        grey: {
          backgroundColor: '#8e9eca4d',
        },
      },
    },
    pressed: {
      main: {
        transform: [{ translateY: 2 }],
      },
    },
  })
  .withLifecycle(({ props }) => {
    const { onPressIn, onPressOut } = props
    const [pressed, setPressed] = useState(false)

    const handlePressIn = useCallback(
      (...args: Parameters<NonNullable<UI.IButtonProps['onPressIn']>>) => {
        setPressed(true)
        onPressIn?.(...args)
      },
      [onPressIn],
    )

    const handlePressOut = useCallback(
      (...args: Parameters<NonNullable<UI.IButtonProps['onPressOut']>>) => {
        setPressed(false)
        onPressOut?.(...args)
      },
      [onPressOut],
    )

    const buttonProps: UI.IButtonProps = { ...props }
    const boxProps: Partial<UI.IBoxProps> = {}
    const shadowProps: Partial<UI.IBoxProps> = {}
    Object.keys(buttonProps).forEach((prop) => {
      if (prop.startsWith('margin') || prop.startsWith('flex')) {
        boxProps[prop as keyof UI.IBoxProps] =
          buttonProps[prop as keyof UI.IButtonProps]
        delete buttonProps[prop as keyof UI.IButtonProps]
      }

      if (prop.startsWith('border')) {
        shadowProps[prop as keyof UI.IBoxProps] =
          buttonProps[prop as keyof UI.IButtonProps]
      }
    })

    return {
      buttonProps,
      boxProps,
      shadowProps,
      pressed,
      handlePressIn,
      handlePressOut,
    }
  })
  .withRender(({ props, lifecycle, styles, ref }) => (
    <UI.Box
      position="relative"
      padding={0}
      marginLeft={0}
      marginRight={-0.1}
      marginY={2}
      {...lifecycle.boxProps}
    >
      <UI.Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={-4}
        borderRadius={32}
        backgroundColor="primary.700"
        {...lifecycle.shadowProps}
        {...props.shadowProps}
        style={styles.shadow({
          [props.colorScheme as 'primary']: true,
          pressed: lifecycle.pressed,
          disabled: props.isDisabled,
          stacked: props.stacked && [true, 'rest'].includes(props.stacked),
          stackedPressed:
            props.stacked &&
            [true, 'pressed'].includes(props.stacked) &&
            lifecycle.pressed,
        })}
      />
      <UI.Button
        px={3}
        py={3}
        {...lifecycle.buttonProps}
        ref={ref as MutableRefObject<any>}
        onPressIn={lifecycle.handlePressIn}
        onPressOut={lifecycle.handlePressOut}
        _pressed={{
          ...lifecycle.buttonProps._pressed,
          style: [
            styles.pressed(),
            lifecycle.buttonProps._pressed?.style as any,
          ],
        }}
        _text={props.leftIcon ? { ml: 2, mr: 4, ...props._text } : props._text}
      />
    </UI.Box>
  ))
