import React from 'react'

import { fonts } from 'src/theme'
import DropShadowTopBarDay from 'src/resources/backgrounds/drop-shadow-topbar-day.webp'
import DropShadowTopBarNight from 'src/resources/backgrounds/drop-shadow-topbar-night.webp'

import { buildComponent } from 'src/components/factory'

import { useStoreState } from 'src/hooks/state'
import { useNavigationState, useRoute } from '@react-navigation/native'
import { BackAction } from './back-action'

import * as UI from 'native-base'
import * as Semantics from 'src/components/semantics'

export const TopBar = buildComponent<{ theme: 'day' | 'night' }>()
  .withLocale('layout.components.top-bar')
  .withLifecycle(({ locale }) => {
    const route = useRoute()
    const routes = useNavigationState((state) => state.routes)
    const previousRoute = routes[routes.length - 2]
    const title = useStoreState(
      (store) =>
        store.utils.appearance.title ||
        locale(('titles.' + route.name) as 'titles./'),
    )
    const action = useStoreState((store) => store.utils.appearance.action)

    const fallbackAction = routes.length > 1 ? BackAction : () => null
    const ActionComponent =
      action === 'back' ? BackAction : action || fallbackAction

    return {
      route,
      previousRoute,
      title,
      ActionComponent,
      action,
    }
  })
  .withRender(({ props, lifecycle }) => {
    const { theme } = props

    return (
      <>
        <UI.Row
          zIndex={100}
          justifyContent="space-between"
          alignItems="center"
          height={56}
        >
          <lifecycle.ActionComponent />

          <UI.Text
            flex={1}
            color="white"
            fontSize="20"
            style={fonts.bold}
            marginLeft={2}
            _ios={{
              textAlign: 'center',
              paddingRight: 71,
            }}
          >
            {lifecycle.title}
          </UI.Text>

          <UI.Row
            position="absolute"
            left={0}
            right={0}
            bottom={-16}
            height={16}
            pointerEvents="none"
            zIndex={100}
          >
            <Semantics.Image
              alt=" "
              key={theme}
              accessibilityRole="none"
              accessibilityElementsHidden={true}
              source={
                theme === 'day' ? DropShadowTopBarDay : DropShadowTopBarNight
              }
              resizeMethod="scale"
              resizeMode="stretch"
              height={16}
              width="100%"
            />
          </UI.Row>
        </UI.Row>
      </>
    )
  })
