import React, {
  ForwardedRef,
  ForwardRefExoticComponent,
  FunctionComponent,
  PropsWithChildren,
  RefAttributes,
} from 'react'
import { createLocaleHook } from 'src/hooks/use-locale'
import {
  createStyleHook,
  StylesDefinitions,
  StylesGetters,
} from 'src/hooks/use-style'
import {
  PrefixedLocaleFunction,
  TranslationPrefix,
} from 'src/resources/locale/locale'

type GetProps<Props, DefaultProps> = Omit<Props, keyof DefaultProps> & {
  [key in keyof DefaultProps]: key extends keyof Props
    ? NonNullable<Props[key]>
    : never
} extends infer U
  ? { [key in keyof U]: U[key] }
  : never

interface Factory<
  Props extends object = {},
  DefaultProps extends Partial<Props> = {},
  Lifecycle extends object | void = void,
  Prefix extends TranslationPrefix | undefined = undefined,
  Styles extends StylesDefinitions = {},
> extends ForwardRefExoticComponent<
    PropsWithChildren<Props> & RefAttributes<Props>
  > {
  withLocale: <_Locale extends TranslationPrefix>(
    prefix: _Locale,
  ) => Factory<Props, DefaultProps, Lifecycle, _Locale, Styles>
  withStyles: <_Styles extends StylesDefinitions>(
    styles: _Styles,
  ) => Factory<Props, DefaultProps, Lifecycle, Prefix, _Styles>
  withLifecycle: <_Lifecycle extends object | void>(
    lifecycle: (params: {
      props: GetProps<Props, DefaultProps>
      locale: Prefix extends TranslationPrefix
        ? PrefixedLocaleFunction<Prefix>
        : undefined
      styles: StylesGetters<Styles>
    }) => _Lifecycle,
  ) => Factory<Props, DefaultProps, _Lifecycle, Prefix, Styles>
  withRender: (
    render: FunctionComponent<{
      props: PropsWithChildren<GetProps<Props, DefaultProps>>
      lifecycle: Lifecycle
      locale: Prefix extends TranslationPrefix
        ? PrefixedLocaleFunction<Prefix>
        : undefined
      styles: StylesGetters<Styles>
      ref: ForwardedRef<FunctionComponent<Props>>
    }>,
  ) => Factory<Props, DefaultProps, Lifecycle, Prefix, Styles>
  withDefaultProps: <_DefaultProps extends Partial<Props>>(
    defaultProps: _DefaultProps,
  ) => Factory<Props, _DefaultProps, Lifecycle, Prefix, Styles>
}

function extendComponent<
  Props extends object = {},
  DefaultProps extends Partial<Props> = {},
  Lifecycle extends object | void = void,
  Prefix extends TranslationPrefix | undefined = undefined,
  Styles extends StylesDefinitions = {},
>(
  render: FunctionComponent<{
    props: PropsWithChildren<Props>
    lifecycle: Lifecycle
    locale: Prefix extends TranslationPrefix
      ? PrefixedLocaleFunction<Prefix>
      : undefined
    styles: StylesGetters<Styles>
    ref: ForwardedRef<FunctionComponent<Props>>
  }>,
  useLifecycle: (params: {
    props: Props
    locale: Prefix extends TranslationPrefix
      ? PrefixedLocaleFunction<Prefix>
      : undefined
    styles: StylesGetters<Styles>
  }) => Lifecycle,
  prefix: Prefix,
  stylesDefinitions: Styles,
  defaultProps: DefaultProps,
): Factory<Props, DefaultProps, Lifecycle, Prefix, Styles> {
  const useLocale = prefix ? createLocaleHook(prefix) : () => {}
  const useStyles = createStyleHook(stylesDefinitions)

  const Component = React.memo(
    React.forwardRef<FunctionComponent<Props>, Props>((props, ref) => {
      const locale = useLocale() as Prefix extends TranslationPrefix
        ? PrefixedLocaleFunction<Prefix>
        : undefined
      const styles = useStyles()
      const lifecycle = useLifecycle({ props, locale, styles })

      return render({
        props,
        locale,
        styles,
        lifecycle,
        ref,
        children: props.children,
      })
    }),
  ) as unknown as Factory<Props, DefaultProps, Lifecycle, Prefix, Styles>
  Component.defaultProps = defaultProps

  Component.withStyles = (_styles) =>
    extendComponent(
      render as any,
      useLifecycle as any,
      prefix,
      _styles,
      defaultProps,
    )
  Component.withLocale = (_locale) =>
    extendComponent(
      render as any,
      useLifecycle as any,
      _locale,
      stylesDefinitions,
      defaultProps,
    )
  Component.withLifecycle = (_lifecycle) =>
    extendComponent(
      render as any,
      _lifecycle as any,
      prefix,
      stylesDefinitions,
      defaultProps,
    )
  Component.withRender = (_render) =>
    extendComponent(
      _render as any,
      useLifecycle,
      prefix,
      stylesDefinitions,
      defaultProps,
    )
  Component.withDefaultProps = (_defaultProps) =>
    extendComponent(
      render,
      useLifecycle,
      prefix,
      stylesDefinitions,
      _defaultProps,
    )

  return Component
}

export function buildComponent<Props extends {} = {}>(): Factory<Props> {
  return extendComponent<Props>(
    () => null,
    () => {},
    undefined,
    {},
    {},
  )
}
