import { Computed, Set, Thunk } from 'src/state/types'
import { computed, thunk } from 'easy-peasy'
import { set } from 'src/state/utils'

import * as Firestore from 'src/helpers/firestore'
import { firestore } from 'src/services/firebase'

export type DurationRecord<T> = Record<10 | 15 | 20 | 25 | 30, T>
export type LanguageRecord<T> = Record<'fr' | 'en', T>

export interface AudioTheme {
  _id: string
  slug: string
  short: LanguageRecord<string>
  title: LanguageRecord<string>
  description: LanguageRecord<string>
  image?: string
  cover?: string
  situations: {
    slug: string
    title: LanguageRecord<string>
  }
  tracks: LanguageRecord<DurationRecord<number>>
}

export interface AudioBackground {
  _id: string
  slug: string
  short: LanguageRecord<string>
  long: LanguageRecord<string>
  tracks: DurationRecord<number>
  mode?: 'music-only' | 'always'
  image?: string
}

export interface AudioBook {
  _id: string
  slug: string
  category: 'club' | 'book'
  language: 'fr' | 'en'
  title: { short: string; long: string }
  description: string
  image?: string
  cover?: string
  backgrounds?: string[]
  chapters: {
    _id: string
    title: string
    tracks: DurationRecord<number>
  }[]
}

export interface Favorite {
  theme?: string
  background: string
}

export interface AudioFavorite {
  theme?: AudioTheme
  background: AudioBackground
}

interface ModelAudio {
  // State
  _favorites: Favorite[]
  themes: Computed<this, AudioTheme[]>
  books: Computed<this, AudioBook[]>
  backgrounds: Computed<this, AudioBackground[]>
  favorites: Computed<this, AudioFavorite[]>
  isFavorite: Computed<
    this,
    (params: { theme?: AudioTheme; background: AudioBackground }) => boolean
  >

  // Actions
  setFavorites: Set<this, '_favorites'>

  // Thunks
  makeFavorite: Thunk<this, { theme?: AudioTheme; background: AudioBackground }>
  clearFavorite: Thunk<
    this,
    { theme?: AudioTheme; background: AudioBackground }
  >
  toggleFavorite: Thunk<
    this,
    { theme?: AudioTheme; background: AudioBackground }
  >
}

export const modelAudio: ModelAudio = {
  // State
  _favorites: [],
  themes: computed(
    [(_, store) => store.space.info?.subscription?.audio?.themes ?? []],
    (themes) => themes,
  ),
  books: computed(
    [(_, store) => store.space.info?.subscription?.audio?.books ?? []],
    (books) => books,
  ),
  backgrounds: computed(
    [(_, store) => store.space.info?.subscription?.audio?.backgrounds ?? []],
    (backgrounds) => backgrounds,
  ),
  favorites: computed(
    [
      (state) => state._favorites,
      (state) => state.backgrounds,
      (state) => state.themes,
    ],
    (favorites, backgrounds, themes) =>
      favorites
        .filter((favorite) => {
          const hasBackground = backgrounds.some(
            (b) => b.slug === favorite.background,
          )
          const hasTheme =
            !favorite.theme || themes.some((t) => t.slug === favorite.theme)

          return hasBackground && hasTheme
        })
        .map((favorite) => ({
          background: backgrounds.find(
            (b) => b.slug === favorite.background,
          ) as AudioBackground,
          theme: themes.find((t) => t.slug === favorite.theme),
        })),
  ),
  isFavorite: computed((state) => {
    return (params) =>
      state.favorites.some(
        (favorite) =>
          favorite.theme?._id === params.theme?._id &&
          favorite.background._id === params.background._id,
      )
  }),

  // Actions
  setFavorites: set('_favorites'),

  // Thunks
  makeFavorite: thunk(async (actions, payload) => {
    await Firestore.upsertDocument(Firestore.getDocumentReference('favorite'), {
      [payload.theme?.slug + ':' + payload.background.slug]: {
        theme: payload.theme?.slug ?? null,
        background: payload.background.slug,
      },
    })
  }),
  clearFavorite: thunk(async (actions, payload) => {
    await Firestore.getDocumentReference('favorite').update({
      [payload.theme?.slug + ':' + payload.background.slug]:
        firestore.FieldValue?.delete(),
    })
  }),
  toggleFavorite: thunk(async (actions, payload, helpers) => {
    const isFavorite = helpers.getState().isFavorite(payload)
    if (!isFavorite) {
      await actions.makeFavorite(payload)
    } else {
      await actions.clearFavorite(payload)
    }
  }),
}
