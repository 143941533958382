import { useCallback, useRef, useState } from 'react'
import { addMinutes } from 'date-fns'

import { Nap } from 'src/state/models/nap'

import { useStoreState } from './state'
import { useNowListener } from './use-now'

function diffArrays(a: unknown[], b: unknown[]) {
  if (a.length !== b.length) {
    return true
  }

  return a.some((element) => !b.includes(element))
}

export function useNaps() {
  const napsRef = useRef<Nap[]>([])
  const nowRef = useRef(new Date())

  const [past, setPast] = useState<Nap[]>([])
  const [upcoming, setUpcoming] = useState<Nap[]>([])
  const [current, setCurrent] = useState<Nap | null>(null)

  const update = useCallback(() => {
    const naps = napsRef.current
    const now = nowRef.current
    const inFiveMinutes = addMinutes(now, 5)

    // Compute past naps
    const updatedPast = naps
      .filter((nap) => nap.finished || nap.time.end < now.toISOString())
      .sort((a, b) => {
        return (
          b.time.start.localeCompare(a.time.start) ||
          b.time.end.localeCompare(a.time.end)
        )
      })

    const updatedUpcoming = naps
      .filter((nap) => nap.time.end >= now.toISOString() && !nap.finished)
      .sort((a, b) => {
        return (
          a.time.start.localeCompare(b.time.start) ||
          a.time.end.localeCompare(b.time.end)
        )
      })

    const updatedCurrent =
      updatedUpcoming.find(
        (nap) =>
          nap.time.end >= now.toISOString() &&
          nap.time.start <= inFiveMinutes.toISOString(),
      ) ?? null

    if (diffArrays(past, updatedPast)) {
      setPast(updatedPast)
    }
    if (diffArrays(upcoming, updatedUpcoming)) {
      setUpcoming(updatedUpcoming)
    }
    if (updatedCurrent !== current) {
      setCurrent(updatedCurrent)
    }
  }, [past, upcoming, current])

  useNowListener((now) => {
    nowRef.current = now
    update()
  })
  useStoreState((store) => {
    napsRef.current = store.nap.list
    update()
    return true
  })

  return {
    past,
    upcoming,
    current,
  }
}
