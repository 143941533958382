import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FlatList, Platform } from 'react-native'

import { fonts } from 'src/theme'
import PickerGradient from 'src/resources/backgrounds/picker-gradient.webp'

import { buildComponent } from 'src/components/factory'
import { useWebOnScroll } from 'src/hooks/use-web-scroll'
import { useStableCallback } from 'src/hooks/use-stable-callback'

import * as UI from 'native-base'
import * as Semantics from 'src/components/semantics'

export const HourPicker = buildComponent<{
  values: number[]
  value: number
  onValueChange(v: number): void
}>()
  .withLocale('screens.connected.book.components.hour-picker')
  .withLifecycle(({ props }) => {
    const elementSize = 48

    const [boxWidth, setBoxWidth] = useState(0)
    const [scrolling, setScrolling] = useState(false)
    const offset = useRef(0)
    const list = useRef<FlatList>(null)

    const { listWidth, listOffset } = useMemo(() => {
      const nbItems =
        Math.floor(boxWidth / elementSize) +
        ((Math.floor(boxWidth / elementSize) % 2) - 1)

      return {
        listWidth: nbItems * elementSize,
        listOffset: Math.floor(nbItems / 2) * elementSize,
      }
    }, [boxWidth])

    const handleScroll = useStableCallback((e: any) => {
      offset.current = e.nativeEvent.contentOffset.x

      if (Platform.OS !== 'android') {
        setScrolling(true)
      }
    })
    const handleScrollEnd = useStableCallback(() => {
      const index = Math.max(
        0,
        Math.min(
          props.values.length - 1,
          Math.round(offset.current / elementSize),
        ),
      )
      const value = props.values[index]
      if (props.value !== value && value) {
        props.onValueChange(value)
      }
      setScrolling(false)
    })

    const valueChangeRef = useRef(0)
    const handleValuePressStart = useStableCallback(() => {
      valueChangeRef.current = Date.now()
    })
    const handleValuePress = useStableCallback((value: number) => () => {
      if (!scrolling && Date.now() - valueChangeRef.current < 500) {
        props.onValueChange(value)
      }
    })

    const handleWebScroll = useWebOnScroll({
      onScroll: handleScroll,
      onScrollEnd: handleScrollEnd,
    })

    useEffect(() => {
      if (!scrolling) {
        const targetOffset = props.values.indexOf(props.value) * elementSize

        if (targetOffset !== offset.current) {
          list.current?.scrollToOffset({
            offset: props.values.indexOf(props.value) * elementSize,
          })
        }
      }
    }, [scrolling, props.values, props.value])

    return {
      list,
      listWidth,
      listOffset,
      elementSize,
      handleWebScroll,
      handleScroll,
      handleScrollEnd,
      handleValuePress,
      handleValuePressStart,
      setBoxWidth,
    }
  })
  .withRender(({ props, lifecycle, locale }) => {
    if (props.values.length < 1) {
      return null
    }

    return (
      <UI.Box
        width="100%"
        onLayout={(e) => {
          lifecycle.setBoxWidth(e.nativeEvent.layout.width)
        }}
      >
        <UI.Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          justifyContent="center"
          alignItems="center"
        >
          <UI.Box
            backgroundColor="secondary.500"
            borderRadius={4}
            width={lifecycle.elementSize}
            height={32}
          />
        </UI.Box>
        <UI.Box style={{ width: lifecycle.listWidth }} marginX="auto">
          <UI.FlatList
            ref={lifecycle.list}
            data={[-1, ...props.values, -2]}
            keyExtractor={(item) => item + 'h'}
            renderItem={(item) => {
              if (item.item < 0) {
                return <UI.Box width={lifecycle.listOffset} />
              }

              return (
                <UI.Pressable
                  paddingY={4}
                  width={lifecycle.elementSize}
                  onTouchStart={Platform.select({
                    web: undefined,
                    default: lifecycle.handleValuePressStart,
                  })}
                  // @ts-expect-error web support
                  onMouseDown={Platform.select({
                    web: lifecycle.handleValuePressStart,
                    default: undefined,
                  })}
                  onTouchEnd={Platform.select({
                    web: undefined,
                    default: lifecycle.handleValuePress(item.item),
                  })}
                  onMouseUp={Platform.select({
                    web: lifecycle.handleValuePress(item.item),
                    default: undefined,
                  })}
                >
                  <UI.Text
                    style={
                      props.value === item.item ? fonts.bold : fonts.normal
                    }
                    textAlign="center"
                  >
                    {item.item}
                    {locale('hour')}
                  </UI.Text>
                </UI.Pressable>
              )
            }}
            horizontal
            snapToInterval={lifecycle.elementSize}
            showsHorizontalScrollIndicator={false}
            onMomentumScrollEnd={lifecycle.handleScrollEnd}
            onScroll={Platform.select({
              web: lifecycle.handleWebScroll,
              default: lifecycle.handleScroll,
            })}
          />
        </UI.Box>
        <UI.Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          justifyContent="center"
          alignItems="center"
          pointerEvents="none"
        >
          <Semantics.Image
            alt=" "
            accessibilityRole="none"
            accessibilityElementsHidden
            source={PickerGradient}
            resizeMode="stretch"
            width="100%"
            height="100%"
          />
        </UI.Box>
      </UI.Box>
    )
  })
