import { Preferences, User } from 'src/state/models/profile'

import * as Firestore from 'src/helpers/firestore'
import { graphql } from 'src/services/graphql'

import { useStore } from 'src/hooks/state'
import { useOnConnected } from 'src/hooks/use-on-connected'
import { useOnMount } from 'src/hooks/use-on-mount'
import { useOnUnmount } from 'src/hooks/use-on-unmount'

export function useDatasourceProfile() {
  const Store = useStore()

  const userDoc = Firestore.useDocumentReference('profile')
  const preferencesDoc = Firestore.useDocumentReference('preferences')

  // When connected, subscribe to API to get profile info and sync to Firestore
  useOnConnected(
    () =>
      graphql.subscribe(['user', 'nap'], async () => {
        const { user } = await graphql.queries
          .getProfile()
          .catch(() => ({ user: null }))

        if (user) {
          Firestore.upsertDocument(userDoc, {
            ...user,
            [`lastKnownDevice.${Store.getState().utils.device.platform}`]:
              Store.getState().utils.device.appVersion,
          })
        }
      }),
    [userDoc],
  )

  // On mount, subscribe to Firestore to get profile info
  useOnMount(() =>
    userDoc.onSnapshot((doc) => {
      const data = doc?.data()

      if (data) {
        Store.getActions().profile.setUser(data as User)
      }
    }),
  )

  // On mount, subscribe to Firestore to get user preferences
  useOnMount(() =>
    preferencesDoc.onSnapshot((doc) => {
      const data = doc?.data()

      if (data) {
        Store.getActions().profile.setPreferences(data as Preferences)
      }
    }),
  )

  // Clear store model when datasource unmounts
  useOnUnmount(() => {
    Store.getActions().profile.setUser(null)
    Store.getActions().profile.setPreferences(null)
  })
}
