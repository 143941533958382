import React, { useLayoutEffect } from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import {
  NavigationProp,
  StackActions,
  useIsFocused,
} from '@react-navigation/native'
import { useStore } from '../hooks/state'

const Path = () => null
export const ResetTo =
  (path: string) => (props: { navigation: NavigationProp<any> }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
      props.navigation.dispatch(StackActions.replace(path))
    }, [props.navigation])
    return null
  }
export const LogoutPath = () => {
  const Store = useStore()
  const isFocused = useIsFocused()

  useLayoutEffect(() => {
    // Forcefully disconnect any connected user
    if (isFocused) {
      Store.getActions().utils.session.disconnect()
    }
  }, [Store, isFocused])

  return null
}

// For Web version, register all routes during loading state so that direct
// link access works correctly. Reset some process to the root route when necessary
export function loadingScreens(Stack: ReturnType<typeof createStackNavigator>) {
  const createPath = (path: string) => (
    <Stack.Screen
      key={path}
      name={path}
      component={Path}
      options={{ title: 'Nap&Up' }}
    />
  )

  const createResetTo = (path: string, to: string) => (
    <Stack.Screen
      key={path}
      name={path}
      component={ResetTo(to)}
      options={{ title: 'Nap&Up' }}
    />
  )

  const createLogoutPath = (path: string) => (
    <Stack.Screen
      key={path}
      name={path}
      component={LogoutPath}
      options={{ title: 'Nap&Up' }}
    />
  )

  return [
    createPath('/'),
    createPath('/login'),
    createPath('/login/signup'),
    createPath('/login/signup/confirm'),
    createLogoutPath('/login/signup/complete'),
    createPath('/login/password'),
    createPath('/login/password/confirm'),
    createLogoutPath('/login/password/reset'),
    createLogoutPath('/login/password/complete'),
    createPath('/book'),
    createResetTo('/book/edit', '/'),
    createPath('/audio'),
    createResetTo('/audio/audiobooks', '/audio'),
    createResetTo('/audio/backgrounds', '/audio'),
    createResetTo('/audio/chapters', '/audio'),
    createResetTo('/audio/durations', '/audio'),
    createResetTo('/audio/favorites', '/audio'),
    createResetTo('/audio/sophrology', '/audio'),
    createResetTo('/player', '/'),
    createResetTo('/player/download', '/'),
    createResetTo('/player/evaluation', '/'),
    createPath('/account'),
    createPath('/account/spaces'),
    createPath('/account/contact'),
    createPath('/account/faq'),
    createPath('/account/legal'),
    createPath('/account/privacy'),
    createPath('/account/expired'),
    createPath('/account/password'),
  ]
}
