import Config from 'react-native-config'
import { storage } from 'src/services/firebase'

export const urlMap: Record<string, string> = new Proxy(
  {},
  {
    get(target: {}, p: string): string {
      return Config.REACT_APP_STATIC_URL + p
    },
  },
)

export async function preloadImage(path: string) {
  const url = await storage()
    .refFromURL(Config.REACT_APP_RESOURCE_BUCKET + path)
    .getDownloadURL()

  urlMap[path] = url
  return url
}
