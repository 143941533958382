import React from 'react'

import { buildComponent } from 'src/components/factory'

import * as UI from 'native-base'

import type { IImageProps } from 'native-base'
import type { ImageResizeMode } from 'react-native'

function toObjectFit(resizeMode: ImageResizeMode = 'contain') {
  return {
    contain: 'contain' as const,
    cover: 'cover' as const,
    stretch: 'fill' as const,
    repeat: 'none' as const,
    center: 'cover' as const,
  }[resizeMode]
}

export const Image = buildComponent<IImageProps>().withRender(({ props }) => (
  <UI.Box {...props}>
    <img
      alt={props.alt}
      src={props.source as unknown as string}
      width="100%"
      height="100%"
      style={{ objectFit: toObjectFit(props.resizeMode) }}
    />
  </UI.Box>
))
