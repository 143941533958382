import React, { useState } from 'react'

import { IInputProps } from 'native-base'
import * as UI from 'native-base'
import * as Icons from 'src/components/icons'

import { buildComponent } from 'src/components/factory'

import { Input } from './input'

export const FormControl = buildComponent<IInputProps & { label: string }>()
  .withStyles({
    label: {
      main: {
        color: 'white',
        textShadowColor: 'rgba(0, 0, 0, 0.5)',
        textShadowOffset: { width: -1, height: 1 },
        textShadowRadius: 5,
      },
    },
  })
  .withLifecycle(() => {
    const [show, setShow] = useState(false)

    const toggle = () => {
      setShow((s) => !s)
    }

    return {
      show,
      toggle,
    }
  })
  .withRender(({ props, styles, lifecycle }) => (
    <UI.FormControl marginTop={2}>
      <UI.FormControl.Label marginBottom={-1}>
        <UI.Text style={styles.label()}>{props.label}</UI.Text>
      </UI.FormControl.Label>
      <Input
        {...props}
        variant="unstyled"
        accessibilityLabel={props.label}
        secureTextEntry={props.type === 'password' && !lifecycle.show}
        InputRightElement={
          props.type === 'password' ? (
            <UI.Button
              marginRight={2}
              variant="ghost"
              onPress={lifecycle.toggle}
            >
              <Icons.FontAwesomeIcon
                icon={
                  lifecycle.show ? Icons.Solid.faEyeSlash : Icons.Solid.faEye
                }
              />
            </UI.Button>
          ) : undefined
        }
      />
    </UI.FormControl>
  ))
