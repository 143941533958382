import * as Firestore from 'src/helpers/firestore'

import { useStore } from 'src/hooks/state'
import { useOnMount } from 'src/hooks/use-on-mount'
import { useOnUnmount } from 'src/hooks/use-on-unmount'

export function useDatasourceFavorites() {
  const Store = useStore()

  // Keep a reference to the naps document
  const favoritesDoc = Firestore.useDocumentReference('favorite')

  // On mount, subscribe to Firestore to get spaces
  useOnMount(() => {
    return favoritesDoc.onSnapshot(async (doc) => {
      const data = doc?.data()

      if (data) {
        Store.getActions().audio.setFavorites(Object.values(data))
      }
    })
  })

  // On unmount, clean-up favorites
  useOnUnmount(() => {
    return Store.getActions().audio.setFavorites([])
  })
}
