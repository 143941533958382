import { buildComponent } from 'src/components/factory'

import { usePendingRedirect } from 'src/hooks/use-pending-redirect'
import { useStoreState } from 'src/hooks/state'
import { useNow } from 'src/hooks/use-now'
import { useRoute } from '@react-navigation/native'

export const ExpiredSpaceDetection = buildComponent().withLifecycle(() => {
  const route = useRoute()
  const now = useNow()
  const loaded = useStoreState((store) => store.space.loaded)
  const expiration = useStoreState(
    (store) => store.space.expiration[store.space.id],
  )
  const expired = !!expiration && expiration < now.valueOf()

  usePendingRedirect(
    !route.name.startsWith('/account') && loaded && expired,
    '/account/expired',
    {},
    true,
  )
})
