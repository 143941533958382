import { useDatasourceSpace } from './datasources/space'
import { useDatasourceProfile } from './datasources/profile'
import { useDatasourceNap } from './datasources/nap'
import { useDatasourceFavorites } from './datasources/favorites'
import { useDatasourceDynamicContent } from './datasources/dynamic-content'

export function useDatasources() {
  useDatasourceProfile()
  useDatasourceSpace()
  useDatasourceNap()
  useDatasourceFavorites()
  useDatasourceDynamicContent()
}
