import React from 'react'
import { useWindowDimensions } from 'react-native'

import { buildComponent } from 'src/components/factory'

import * as UI from 'native-base'

const TOP_BAR_OFFSET = 88

export const PaddedScrollView = buildComponent<
  UI.IScrollViewProps & { centered?: boolean }
>()
  .withStyles({
    contentContainer: {
      main: {
        paddingBottom: 64,
      },
      custom: {
        centered: {
          justifyContent: 'center',
        },
      },
    },
  })
  .withLifecycle(() => {
    const { height } = useWindowDimensions()

    return {
      height: height - TOP_BAR_OFFSET,
    }
  })
  .withRender(({ props, styles, lifecycle, children }) => (
    <UI.ScrollView
      scrollIndicatorInsets={{ right: 1 }}
      _contentContainerStyle={{
        minHeight: lifecycle.height,
      }}
      {...props}
      mx={0}
      maxWidth={undefined}
    >
      <UI.View
        minHeight="100%"
        maxWidth={props.maxWidth}
        mx={props.mx}
        style={styles.contentContainer({ centered: props.centered })}
      >
        {children}
      </UI.View>
    </UI.ScrollView>
  ))
