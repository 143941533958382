import Bugsnag from '@bugsnag/js'
import BugsnagReact, { BugsnagPluginReactResult } from '@bugsnag/plugin-react'
import type { ReactNativeConfig } from '@bugsnag/react-native'

export default {
  start(config: ReactNativeConfig) {
    Bugsnag.start({
      ...config,
      apiKey: '7ca711e940bd7eee51c08c81d3bc066b',
      plugins: [new BugsnagReact()],
    })
  },
  getPlugin(id: 'react'): BugsnagPluginReactResult | undefined {
    return Bugsnag.getPlugin(id)
  },
}
