import React from 'react'
import MarkdownDisplay from 'react-native-markdown-display'

import { colors, fonts } from 'src/theme'
import { buildComponent } from 'src/components/factory'

import * as UI from 'native-base'

export const Markdown = buildComponent<UI.ITextProps>().withRender(
  ({ props }) => (
    <MarkdownDisplay
      style={{
        paragraph: {
          marginTop: 3,
          marginBottom: 5,
        },
        heading2: { fontSize: 20 },
        table: {
          marginVertical: 8,
          borderColor: colors.primary['700'],
          borderBottomWidth: 0,
          borderRadius: 3,
        },
        tr: { borderColor: colors.primary['500'] },
        td: { padding: 8 },
        th: { padding: 8 },
      }}
      rules={{
        softbreak: () => ' ',
        paragraph: (node, children) => (
          <UI.Text key={node.key} {...props}>
            {children}
          </UI.Text>
        ),
        text: (node, children, parent, styles, inheritedStyles = {}) => (
          <UI.Text
            key={node.key}
            style={[inheritedStyles, styles.text]}
            fontSize="sm"
          >
            {node.content}
          </UI.Text>
        ),
        heading2: (node, children) => (
          <UI.View marginTop={4} key={node.key}>
            <UI.Text color="primary.900" style={fonts.bold}>
              {children}
            </UI.Text>
          </UI.View>
        ),

        strong: (node, children, parent, styles) => (
          <UI.Text key={node.key} style={[styles.strong, fonts.bold]}>
            {children}
          </UI.Text>
        ),
        em: (node, children, parent, styles) => (
          <UI.Text key={node.key} style={styles.em}>
            {children}
          </UI.Text>
        ),
        s: (node, children, parent, styles) => (
          <UI.Text key={node.key} style={styles.s}>
            {children}
          </UI.Text>
        ),
      }}
    >
      {props.children}
    </MarkdownDisplay>
  ),
)
