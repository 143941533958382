export const FAQ = {
  fr: [
    {
      question:
        'Après avoir créé mon profil, comment je fais pour programmer ma sieste ?',
      answer: `Pour programmer votre sieste, rendez-vous sur votre page d'accueil pour sur l'icône "Réserver".

Vous pourrez alors programmer votre jour, heure et temps de sieste souhaités.`,
    },

    {
      question: 'À quoi servent les audios de micro-sieste ?',
      answer:
        'Les audios de micro-sieste ont été créés et étudiés par des sophrologues et hypnothérapeuthes pour ' +
        'vous aider à lâcher prise au cours des différents moments de votre journée de travail.',
    },

    {
      question:
        'Si mon entreprise dispose d’une offre premium, comment je fais pour accéder aux audios ?',
      answer: `L'offre premium permet d’accéder à plus de 200 audios de micro-sieste guidée différents.

Si votre entreprise a souscrit à l’offre, vous pouvez accéder à l’ensemble des audios en cliquant
sur le casque en bas de votre écran. Vous pourrez ainsi sélectionner votre audio en fonction de la thématique que vous souhaitez, et en variant les fonds sonores (mer, cigales, pluie, harpe etc.)`,
    },

    {
      question:
        'Est-ce qu’il est possible d’écouter un audio sans réserver de sieste ?',
      answer:
        'Oui. Si vous appréciez les audios et souhaitez les écouter dans les transports, chez vous ou ailleurs, ' +
        'vous pouvez tout à fait écouter tous les audios en appuyant sur le casque audio au milieu de votre écran.',
    },

    {
      question: 'Comment annuler/modifier ma sieste ?',
      answer: `Pour annuler ou modifier votre sieste, appuyez sur l’icône “réservations” du menu.
    
À ce moment-là apparaîtra un récapitulatif de votre sieste programmée.

Il vous suffira d’appuyer sur le bouton modifier et le tour est joué !"`,
    },

    {
      question: 'Est-il possible d’écouter un audio en mode avion ?',
      answer:
        'Oui bien sûr et nous vous le conseillons ! L’idée est que vous ne receviez aucune notification durant votre sieste pour ' +
        'ne pas être dérangé et pouvoir totalement déconnecter.',
    },

    {
      question:
        'Si je quitte la lecture de mon audio pour me rendre sur un autre endroit de l’application, que se passe-t-il ?',
      answer: `Vous pouvez mettre votre portable en mode avion et quitter l’application web, l’audio ne s'interrompra pas.
En revanche si vous choisissez de sortir de l’audio pour consulter une autre page de l’application Nap&Up, l’audio s’arrêtera.`,
    },

    {
      question:
        'Il y a plusieurs cocons dans mon espace de sieste comment je sais lequel j’ai réservé ?',
      answer: `Lorsque vous réservez une sieste, un nom de cocon vous est immédiatement attribué.
Vous retrouverez ces noms à proximité des cocons dans votre espace.`,
    },

    {
      question: 'Qu’est-ce que le "code d\'accès" ?',
      answer: `Le code d'accès vous permet de rejoindre un site de votre entreprise proposant les cocons Nap&Up.
Ce code vous est fourni par le site concerné.`,
    },

    {
      question: 'Comment ajouter un espace entreprise dans mon profil ?',
      answer:
        "Sélectionner l’icône “profil”. Une fois dessus cliquez sur “ajouter un espace” et rentrez le code d'accès.",
    },

    {
      question: 'Combien de siestes puis-je effectuer dans la journée ?',
      answer:
        'Les chiffres et les études sont clairs, 20 minutes de sieste suffisent pour pouvoir bénéficier d’un coup de boost et repartir travailler. Vous disposez d’un quota de siestes par jour, qui varie selon votre espace.',
    },

    {
      question:
        "Je n'arrive pas à réserver deux créneaux d'affilés, pourquoi ?",
      answer: `Il n'est pas possible de réserver deux siestes d'affilées, en revanche vous pouvez réserver une nouvelle sieste 30min
après votre première réservation.
N'oubliez pas... le principe de la micro-sieste est de pouvoir profiter d'une pause entre 10min et 25min !`,
    },

    {
      question: "Comment cela se passe-t-il au niveau de l'hygiène ?",
      answer: `Cela dépend du type de cocon dont vous disposez.
            
Pour certains, des tissus de protection se disposant sur toute la longueur du cocon (recouvrant du coussin jusqu’aux pieds)
sont mis à votre disposition dans l’espace de sieste. Ce tissu est individuel, et réutilisable.

Nous vous conseillons de le garder après utilisation et de le réutiliser pour vos prochaines siestes (plus écologiques).

Après 4 ou 5 utilisations vous pourrez le jeter dans une poubelle recyclable prévue à cet effet.
            
Les autres cocons sont revêtus de tissus épongeables, antibactériens et virucides,
ce qui permet une utilisation optimale dans le respect des contraintes sanitaires.

Vous trouverez à côté de votre cocon des lingettes ou un spray afin de nettoyer avant et après votre sieste.
            
De plus, nos housses de fauteuil et nos oreillers sont tous lavables à la machine, à froid.
            
Aucune excuse pour ne pas aller faire la sieste !`,
    },

    {
      question:
        'Je suis claustrophobe et l’idée de me retrouver enfermé dans le cocon à sieste m’inquiète... Ai-je raison ?',
      answer: `N’ayez crainte ! Nous avons déjà fait tester le cocon à des personnes claustrophobes et tout s’est très bien passé.

En effet, nous vous conseillons de tester en vous allongeant dedans car une fois à l’intérieur et la coque refermée,
vous découvrirez que l’espace dont vous disposez est grand !

De plus, la coque est fabriquée avec un tissu fin et ne se referme pas en entier,
ce qui permet de l’ouvrir facilement mais également de laisser passer la lueur du jour et de ne pas être dans le noir complet.`,
    },

    {
      question: 'J’ai une grande corpulence, puis-je utiliser le cocon ?',
      answer: `Que vous soyez long, large, court ou étroit, tout le monde peut dormir dans le cocon nous avons fait
des tests avec toutes les caractéristiques physiques possibles ;)`,
    },

    {
      question: 'Est-ce que je peux dormir sur le côté ?',
      answer: `Nous, en tout cas, on y arrive ! A vous de trouver la position adéquate et de bien vous positionner
mais il est tout à fait possible de passer d’agréables siestes en se mettant sur le côté.`,
    },

    {
      question:
        'Moi si je fais une sieste dans ce cocon, jamais je ne me réveillerai !',
      answer: `Vous êtes nombreux à le penser.. mais on vous promet que vous allez retourner travailler ;).
    
Un réveil est intégré à l’audio, et on vous aide à repartir d’attaque.

En dernier recours, c’est la personne ayant réservé la sieste suivante qui se chargera de vous réveiller...

À vous de choisir ce que vous préférez !`,
    },
  ],
  en: [
    {
      question: 'After creating my profile, how can I schedule my nap?',
      answer:
        'To schedule your nap, go to the home page and click on "Book". Then, you can schedule the day, the time and the duration of your nap.',
    },
    {
      question: 'What are the audio guides for?',
      answer:
        'The audio guides have been designed and developed by sophrologists and hypnotherapists to help you to relax along the different moments of your working day.',
    },
    {
      question:
        'If my company has a Premium account, how can I access the audio guides?',
      answer:
        'The Premium account gives you access to more than 200 different power nap audio guides. If your company signed up for a Premium account, you can access all audio guides by clicking on the headphones at the bottom of your screen. You will be able to select the audio guide according to the thematic of your choice, and with different background sounds (sea, cicadas, rain, harp, etc.)',
    },
    {
      question:
        'Is it possible to listen to an audio guide without booking a nap?',
      answer:
        'Yes, if you like the audio guides and you want to listen to them during commute, at home or anywhere else, you can listen to all audio guides by clicking on the headphones in the middle of your screen.',
    },
    {
      question: 'How can I cancel/modify my nap?',
      answer:
        'To cancel or modify your nap, click on the "Bookings" icon in the menu. You will see a recap of your scheduled naps. You will just have to click on the modify button and that\'s it!',
    },
    {
      question: 'Is it possible to listen to audio guides on Airplane mode?',
      answer:
        'Yes, absolutely and we advise you to do so! The idea is that you do not receive any notification during your nap to not be disturbed and to switch off completely.',
    },
    {
      question:
        "What happens if I quit the audio guide while playing, to go to another Nap&Up's app page?",
      answer:
        "If you turn your phone on airplane mode and quit the app, the audio won't stop. However if you choose to quit the audio guide to visit another app page, the audio will stop.",
    },
    {
      question:
        'There are various cocoons in my resting space, how can I know which one to book?',
      answer:
        'When you book a nap, a cocoon name will immediately be assigned to you. You will find all cocoons names next to the cocoons in the nap area.',
    },
    {
      question: 'What\'s an "access code"?',
      answer:
        'The access code allows you to access a resting space with cocoons, implemented by your company. This code is provided by your company.',
    },
    {
      question: 'How can I add a resting space in my profile?',
      answer:
        'Select the "profile" icon. Once in the profile section, click on "add a resting space" and enter the access code.',
    },
    {
      question: 'How many naps can I do in a day?',
      answer:
        'Studies and numbers are clear to say that 20 minutes napping are enough to get a boost and work again. You have a limited daily quota, which depends on your space.',
    },
    {
      question: "I'm not able to book two naps in a row, why is that?",
      answer:
        "It is not possible to book two naps in a row, there must be a gap of at least 30 minutes between your naps.\n Don't forget... micro-naps are there to offer you a break of 10 to 25 minutes!",
    },
    {
      question: 'How does it work it terms of hygiene?',
      answer:
        'It depends on the cocoon type you have. \n On some, protective fabrics that fit the entire cocoon length (covering from pillow to feet) are provided in the resting space. This fabric is personal and reusable. We advise you keep it after use and reuse it for your next naps. After 4 ou 5 uses, you can throw it in a recyclable bin. \n Newer cocoons are covered with spongeable, antibacterial and virucidal fabrics. This allows optimal use while respecting sanitary constraints. You will find wipes or a spray next to your cocoon to cleanse it before and after your nap. \n In addition, our chair covers and pillows are all machine washable, at cold temperature. \n No excuse left not to go napping!',
    },
    {
      question:
        "I'm claustrophobic and I'm a bit worried with the idea to be locked in the cocoon. Am I right?",
      answer:
        "No worries! Our cocoons have been tested by claustrophobic people and all went well. In fact, we advise that you try to lay down in it to see that once inside and the shell closed, the resting space is quite big! \n Moreover, the shell is manufactured with a thin fabric and does not close all the way down, and can therefore be easily opened and lets some daylight through, so you're not in complete darkness.",
    },
    {
      question: "I'm of strong build, can I use the cocoon?",
      answer:
        'No matter if you are of strong, medium or slim build, everyone can sleep in the cocoon. We have tested it with all possible physical characteristics ;)',
    },
    {
      question: 'Can I sleep on the side?',
      answer:
        "We managed to, so you can! It's up to you to find the position you prefer and you are more comfortable with but it is possible for sure to have great naps sleeping on the side.",
    },
    {
      question: 'I will never wake up if I take a nap in the cocoon!',
      answer:
        "You are many to think so... but we promise you will go back to work ;). An alarm clock is set up in the audio guides, and we'll help you feel up to the task. As a last resort, the person who booked the cocoon after you will wake you up... It's up to you to choose the option you prefer!",
    },
  ],
}
