import { useEffect, useState } from 'react'
import NetInfo, { useNetInfo } from '@react-native-community/netinfo'

import { auth } from 'src/services/firebase'
import { graphql } from 'src/services/graphql'

import { useStore, useStoreState } from './state'
import { useOnMount } from './use-on-mount'

export function useConnectionState() {
  const Store = useStore()

  const claims = useStoreState((store) => store.utils.session.claims)
  const spaces = useStoreState((store) => store.space.all)
  const space = useStoreState((store) => store.space.info)
  const [user, setUser] = useState(auth().currentUser)

  const [loading, setLoading] = useState(true)
  const [isConnected, setIsConnected] = useState(false)

  useOnMount(() => {
    return auth().onAuthStateChanged(setUser)
  })

  useEffect(() => {
    const checkSession = async () => {
      const updatedClaims = await Store.getActions().utils.session.restore()

      // If a user is connected to firebase, but different from latest
      // token, sign them out
      if (user && user.uid !== updatedClaims._id) {
        await auth().signOut()
      }

      // If no user is connected to firebase, and there is a token,
      // connect them to firebase
      if (!user && updatedClaims._id) {
        const netInfo = await NetInfo.fetch()

        if (netInfo.isConnected) {
          const { userFirebaseToken } = await graphql.query<{
            userFirebaseToken: string | null
          }>('{ userFirebaseToken }')

          if (userFirebaseToken) {
            await auth().signInWithCustomToken(userFirebaseToken)
          }
        }
      }

      // If no user is connected at all, then we are in disconnected state
      if (!user && !updatedClaims._id) {
        setIsConnected(false)
        setLoading(false)
      }

      // If a user is connected to firebase with latest token, then we are
      // in connected state
      if (user && updatedClaims._id === user.uid) {
        setIsConnected(true)
        setLoading(false)
      }
    }

    checkSession().catch(console.error)
  }, [Store, user, claims])
  useEffect(() => {
    if (!isConnected) {
      return
    }

    if (!spaces.length) {
      return
    }

    if (space?._id !== claims.space || !claims.space) {
      const candidate = spaces.find((s) => s._id === claims.space) ?? spaces[0]

      graphql.mutations.selectSpace(candidate._id).then((token) => {
        Store.getActions().space.setId(candidate._id)
        Store.getActions().utils.session.connectWithToken(token)
      })
    }
  }, [Store, isConnected, space?._id, claims.space, spaces])

  const netInfo = useNetInfo()

  useEffect(() => {
    Store.getActions().utils.device.setConnected(!!netInfo.isConnected)
  }, [Store, netInfo.isConnected])

  return {
    loading,
    isConnected,
  }
}
