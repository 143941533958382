import { useWindowDimensions, Platform } from 'react-native'

interface ResponsiveData {
  variant: 'mobile' | 'desktop'
  isWeb: boolean
  isMobile: boolean
  isDesktop: boolean
  isMobileWidth: boolean
  isDesktopWidth: boolean
  width: number
  height: number
}

export function useResponsive(): ResponsiveData {
  const { width, height } = useWindowDimensions()

  const isMobileWidth = width <= 620
  const isMobile = Platform.OS !== 'web' || isMobileWidth

  return {
    variant: isMobile ? 'mobile' : 'desktop',
    isWeb: Platform.OS === 'web',
    isMobile,
    isDesktop: !isMobile,
    isMobileWidth,
    isDesktopWidth: !isMobileWidth,
    width,
    height,
  }
}
