import { useCallback, useLayoutEffect, useRef, useState } from 'react'
import {
  StackActions,
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native'

export function usePendingRedirect(
  condition: boolean,
  path: string,
  params?: Record<string, any>,
  listenToCondition?: boolean,
) {
  const navigation = useNavigation()
  const state = useRoute().params
  const [loaded, setLoaded] = useState(false)
  const referencedCondition = useRef(condition)
  const referencedNavigationParams = useRef([
    path,
    { ...state, ...params },
  ] as const)

  const listenedCondition = listenToCondition ? condition : false

  useLayoutEffect(() => {
    referencedCondition.current = condition
  })

  useFocusEffect(
    useCallback(() => {
      if (referencedCondition.current || listenedCondition) {
        navigation.dispatch(
          StackActions.replace(...referencedNavigationParams.current),
        )
      } else {
        setLoaded(true)
      }
    }, [navigation, listenedCondition]),
  )

  return loaded
}
