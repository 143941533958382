import { createStore } from 'easy-peasy'

import { modelProfile } from './models/profile'
import { modelSpace } from './models/space'
import { modelNap } from './models/nap'
import { modelAudio } from './models/audio'
import { modelDynamicContent } from './models/dynamic-content'
import { modelUtils } from './models/utils'

export const model = {
  profile: modelProfile,
  space: modelSpace,
  nap: modelNap,
  audio: modelAudio,
  dynamicContent: modelDynamicContent,
  utils: modelUtils,
}

// Export our store
export const Store = createStore(model)
