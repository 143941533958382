import { Set } from 'src/state/types'
import { set } from 'src/state/utils'

export interface Nap {
  _id: string
  time: {
    start: string
    end: string
  }
  space: {
    _id: string
  }
  cocoon: {
    _id: string
    room?: { _id: string }
  }
  finished?: boolean
  rating?: {
    rating?: number
    comment?: string
  }
  audio?: {
    background?: string
    theme?: string
    book?: string
    bookChapter?: number
    locale: 'fr' | 'en'
    category: 'sophrology' | 'audiobooks' | 'music-only'
    duration: 10 | 15 | 20 | 25 | 30
  }
}

interface ModelNap {
  // State
  list: Nap[]

  // Actions
  setList: Set<this, 'list'>
}

export const modelNap: ModelNap = {
  // State
  list: [],

  // Actions
  setList: set('list'),
}
