import React from 'react'

import { colors } from 'src/theme'
import { buildComponent } from 'src/components/factory'

import * as UI from 'native-base'
import * as SVG from 'react-native-svg'

export const GamificationExperienceBar = buildComponent<{ percent: number }>()
  .withStyles({
    container: {
      main: {
        position: 'absolute',
        top: 8,
        left: 8,
        right: 8,
        bottom: 8,
      },
    },
    svg: {
      main: {
        transform: [{ rotate: '-90deg' }],
        color: colors.secondary['700'],
      },
    },
  })
  .withLifecycle(({ props }) => {
    const x = Math.cos((2 * Math.PI * props.percent) / 100)
    const y = Math.sin((2 * Math.PI * props.percent) / 100)
    const largeArcFlag = props.percent > 50 ? 1 : 0

    return {
      x,
      y,
      largeArcFlag,
    }
  })
  .withRender(({ styles, lifecycle }) => (
    <UI.Box style={styles.container()}>
      <SVG.Svg viewBox="-1.15 -1.15 2.30 2.30" style={styles.svg()}>
        <SVG.Circle
          stroke="#00000018"
          fill="transparent"
          strokeWidth="0.15"
          cx="0"
          cy="0"
          r="1"
        />
        <SVG.Path
          stroke="currentColor"
          fill="transparent"
          strokeWidth="0.15"
          strokeLinecap="round"
          d={`
            M1 0
            A 1 1 0 ${lifecycle.largeArcFlag} 1 ${lifecycle.x} ${lifecycle.y}
          `}
        />
      </SVG.Svg>
    </UI.Box>
  ))
