import { graphql } from 'src/services/graphql'
import * as Firestore from 'src/helpers/firestore'

import { useStore, useStoreState } from 'src/hooks/state'
import { useOnConnected } from 'src/hooks/use-on-connected'
import { useOnMount } from 'src/hooks/use-on-mount'
import { useOnUnmount } from 'src/hooks/use-on-unmount'

export function useDatasourceSpace() {
  const Store = useStore()
  const claims = useStoreState((store) => store.utils.session.claims)

  // Keep a reference to the space document
  const spaceDoc = Firestore.useDocumentReference('spaces')

  // When connected, subscribe to API to get spaces and sync to Firestore
  useOnConnected(() =>
    graphql.subscribe(
      ['user', 'space', 'audio-theme', 'audio-background', 'audio-book'],
      async () => {
        Store.getActions().space.setLoaded(false)

        const { user } = await graphql.queries
          .getSpaces()
          .catch(() => ({ user: null }))

        if (user) {
          await spaceDoc.set(user)
        }
      },
    ),
  )

  // When connected, subscribe to API to get booked slots - no need to sync
  // to Firestore because this data is only needed while online
  useOnConnected(() =>
    graphql.subscribe(['space', 'nap', 'cocoon'], async () => {
      const { user } = await graphql.queries
        .getBookedSlots()
        .catch(() => ({ user: null }))

      if (user) {
        Store.getActions().space.setBookedSlots(
          Object.fromEntries(
            user.spaces
              .find(({ _id }) => _id === claims.space)
              ?.cocoons?.map(({ _id, optimizedBookedSlots }) => [
                _id,
                optimizedBookedSlots,
              ]) ?? [],
          ),
        )
      }
    }),
  )

  // On mount, subscribe to Firestore to get spaces
  useOnMount(() => {
    return spaceDoc.onSnapshot((doc) => {
      const data = doc?.data()
      if (data) {
        Store.getActions().space.setAll(data.spaces ?? [])
        Store.getActions().space.setExpiration(data.spaceExpiration ?? {})
        Store.getActions().space.setId(claims.space)
        Store.getActions().space.setLoaded(true)
      }
    })
  })

  // Clear store model when datasource unmounts
  useOnUnmount(() => {
    Store.getActions().space.setId('')
    Store.getActions().space.setAll([])
    Store.getActions().space.setBookedSlots({})
    Store.getActions().space.setExpiration({})
    Store.getActions().space.setLoaded(false)
  })
}
