import React, { useCallback, useState } from 'react'
import { useFocusEffect } from '@react-navigation/native'

import { graphql } from 'src/services/graphql'

import { buildComponent } from 'src/components/factory'
import { useStore } from 'src/hooks/state'
import { useAppearance } from 'src/hooks/use-appearance'

import * as UI from 'native-base'
import * as Semantics from 'src/components/semantics'

export const ScreenDisconnectedLogin = buildComponent()
  .withStyles({
    container: {
      main: {
        flexGrow: 1,
      },
    },
    title: {
      main: {
        textShadowColor: 'rgba(0, 0, 0, 0.5)',
        textShadowOffset: { width: -1, height: 1 },
        textShadowRadius: 5,
      },
    },
  })
  .withLocale('screens.disconnected.login.screen')
  .withLifecycle(({ locale }) => {
    const Store = useStore()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [submitting, setSubmitting] = useState(false)
    const [error, setError] = useState<
      '' | 'user_not_found' | 'wrong_credentials' | 'internal_server_error'
    >('')

    useAppearance({
      title: locale('title'),
    })

    const handleSubmit = useCallback(async () => {
      setError('')
      setSubmitting(true)

      try {
        const { userLogin } = await graphql.queries.login(email, password)

        if (userLogin.token) {
          Store.getActions().utils.session.connectWithToken(userLogin.token)
        } else if (userLogin.error) {
          setError(userLogin.error)
          setSubmitting(false)
        }
      } catch (err) {
        setError('internal_server_error')
        setSubmitting(false)
      }
    }, [Store, email, password])

    useFocusEffect(
      useCallback(() => {
        setSubmitting(false)
      }, []),
    )

    return {
      handleSubmit,
      email,
      setEmail,
      password,
      setPassword,
      submitting: submitting,
      error,
    }
  })
  .withRender(({ styles, locale, lifecycle }) => (
    <UI.ScrollView
      scrollIndicatorInsets={{ right: 1 }}
      padding={5}
      contentContainerStyle={styles.container()}
      mx={0}
    >
      <UI.View
        width="100%"
        maxWidth={['100%', 480]}
        minHeight="100%"
        mx={[0, 'auto']}
      >
        <UI.Column
          flexGrow={1}
          justifyContent="center"
          paddingX={2}
          marginBottom={4}
        >
          <UI.Text
            color="white"
            fontSize="xl"
            textAlign="center"
            marginBottom={4}
            style={styles.title()}
          >
            {locale('title')}
          </UI.Text>

          <Semantics.FormControl
            label={locale('email.label')}
            placeholder={locale('email.placeholder')}
            autoComplete="email"
            autoCorrect={false}
            autoCapitalize="none"
            keyboardType="email-address"
            type="text"
            value={lifecycle.email}
            onChangeText={lifecycle.setEmail}
            isDisabled={lifecycle.submitting}
          />

          <Semantics.FormControl
            type="password"
            label={locale('password')}
            value={lifecycle.password}
            onChangeText={lifecycle.setPassword}
            isDisabled={lifecycle.submitting}
          />

          <UI.Row justifyContent="flex-end">
            <Semantics.LinkButton
              size="sm"
              colorScheme="dark"
              to="/login/password"
              marginTop={3}
              px={6}
              py={1.5}
            >
              {locale('forgotten')}
            </Semantics.LinkButton>
          </UI.Row>

          {!!lifecycle.error && (
            <UI.Alert status="error" variant="left-accent" marginTop={4}>
              <UI.Text color="red.900">
                {locale(`error.${lifecycle.error}`)}
              </UI.Text>
            </UI.Alert>
          )}
        </UI.Column>

        <UI.Column marginBottom={4}>
          <Semantics.Button
            onPress={lifecycle.handleSubmit}
            colorScheme="secondary"
            isDisabled={lifecycle.submitting}
          >
            {locale('logIn')}
          </Semantics.Button>
          <UI.Box marginX={6}>
            <Semantics.LinkButton
              marginTop={5}
              marginBottom={16}
              py={2}
              to="/login/signup"
              colorScheme="primary"
            >
              {locale('signUp')}
            </Semantics.LinkButton>
          </UI.Box>
        </UI.Column>
      </UI.View>
    </UI.ScrollView>
  ))
