import React from 'react'

import { colors } from 'src/theme'
import { buildComponent } from 'src/components/factory'

import * as UI from 'native-base'

export const TextArea = buildComponent<UI.ITextAreaProps>()
  .withStyles({
    container: {
      main: {
        position: 'relative',
        padding: 0,
        marginVertical: 8,
        marginHorizontal: 4,
      },
    },
    shadow: {
      main: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: -4,
        borderRadius: 16,
        backgroundColor: '#FFFFFFBB',
      },
      custom: {
        disabled: {
          opacity: 0.75,
        },
        primary: {
          backgroundColor: colors.primary['700'],
        },
        secondary: {
          backgroundColor: '#dbb136',
        },
      },
    },
  })
  .withLifecycle(({ props }) => {
    const inputProps: UI.ITextAreaProps = { ...props }
    const boxProps: Partial<UI.IBoxProps> = {}
    Object.keys(inputProps).forEach((prop) => {
      if (prop.startsWith('margin')) {
        boxProps[prop as keyof UI.IBoxProps] =
          inputProps[prop as keyof UI.ITextAreaProps]
        delete inputProps[prop as keyof UI.ITextAreaProps]
      }
      if (prop.startsWith('minHeight')) {
        boxProps[prop as keyof UI.IBoxProps] =
          inputProps[prop as keyof UI.ITextAreaProps]
      }
    })

    return {
      inputProps,
      boxProps,
    }
  })
  .withRender(({ props, styles, lifecycle }) => (
    <UI.Box {...lifecycle.boxProps} style={styles.container()}>
      <UI.Box style={styles.shadow({ disabled: props.isDisabled })} />
      <UI.TextArea
        autoCompleteType={props.autoComplete}
        {...lifecycle.inputProps}
        borderWidth={0}
      />
    </UI.Box>
  ))
