import React, { useCallback, useState } from 'react'
import { useWindowDimensions } from 'react-native'

import { graphql } from 'src/services/graphql'

import { fonts } from 'src/theme'
import { LEGAL_NOTICES } from 'src/resources/locale/legal-notices'
import { PRIVACY_POLICY } from 'src/resources/locale/privacy-policy'

import { buildComponent } from 'src/components/factory'
import { useStoreState } from 'src/hooks/state'
import { useAppearance } from 'src/hooks/use-appearance'
import {
  StackActions,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native'

import * as UI from 'native-base'
import * as Elements from 'src/components/elements'
import * as Semantics from 'src/components/semantics'

export const ScreenDisconnectedSignUp = buildComponent()
  .withStyles({
    container: {
      main: {
        flexGrow: 1,
      },
    },
    title: {
      main: {
        textShadowColor: 'rgba(0, 0, 0, 0.5)',
        textShadowOffset: { width: -1, height: 1 },
        textShadowRadius: 5,
      },
    },
  })
  .withLocale('screens.disconnected.signup.screen')
  .withLifecycle(({ locale }) => {
    useAppearance({
      title: locale('title'),
    })
    const navigation = useNavigation()
    const { width, height } = useWindowDimensions()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [accessCode, setAccessCode] = useState('')
    const [rulesAccepted, setRulesAccepted] = useState(false)

    const [submitting, setSubmitting] = useState(false)
    const [error, setError] = useState<
      | ''
      | 'email_taken'
      | 'password_too_short'
      | 'password_mismatch'
      | 'space_not_found'
      | 'space_no_license'
      | 'internal_server_error'
    >('')

    const [showLicenseAgreement, setShowLicenseAgreement] = useState(false)
    const [showPrivacyRules, setShowPrivacyRules] = useState(false)

    const legalNotices = useStoreState((store) => ({
      ...LEGAL_NOTICES,
      ...store.dynamicContent.legalNotices,
    }))
    const privacyPolicy = useStoreState((store) => ({
      ...PRIVACY_POLICY,
      ...store.dynamicContent.privacyPolicy,
    }))

    const canSubmit =
      email && password && passwordConfirm && accessCode && rulesAccepted

    const handleSubmit = useCallback(async () => {
      if (password.length < 6) {
        return setError('password_too_short')
      }

      if (password !== passwordConfirm) {
        return setError('password_mismatch')
      }

      setError('')
      setSubmitting(true)

      try {
        await graphql.mutations.signup(email, password, accessCode)
        navigation.dispatch(
          StackActions.push('/login/signup/confirm', {
            email,
          }),
        )
      } catch (err: any) {
        if (err?.message.includes('E11000 duplicate key')) {
          setError('email_taken')
        } else if (
          ['space_not_found', 'space_no_license'].includes(err?.message)
        ) {
          setError(err?.message)
        } else {
          setError('internal_server_error')
        }
        setSubmitting(false)
      }
    }, [navigation, email, password, passwordConfirm, accessCode])

    useFocusEffect(
      useCallback(() => {
        setSubmitting(false)
      }, []),
    )

    return {
      email,
      password,
      passwordConfirm,
      accessCode,
      rulesAccepted,
      setEmail,
      setPassword,
      setPasswordConfirm,
      setAccessCode,
      setRulesAccepted,
      canSubmit,
      legalNotices,
      privacyPolicy,

      showLicenseAgreement,
      showPrivacyRules,
      setShowLicenseAgreement,
      setShowPrivacyRules,
      width,
      height,

      submitting,
      error,
      handleSubmit,
    }
  })
  .withRender(({ styles, locale, lifecycle }) => (
    <UI.ScrollView
      scrollIndicatorInsets={{ right: 1 }}
      padding={5}
      contentContainerStyle={styles.container()}
      mx={0}
    >
      <UI.View
        width="100%"
        maxWidth={['100%', 480]}
        minHeight="100%"
        mx={[0, 'auto']}
      >
        <UI.Text
          color="white"
          fontSize="xl"
          textAlign="center"
          marginBottom={4}
          marginTop={16}
          style={styles.title()}
        >
          {locale('title')}
        </UI.Text>

        <Elements.Steps step={1} steps={3} />

        <UI.Column
          flexGrow={1}
          justifyContent="flex-start"
          paddingX={2}
          marginBottom={4}
        >
          <Semantics.FormControl
            label={locale('email.label')}
            placeholder={locale('email.placeholder')}
            autoComplete="email"
            autoCorrect={false}
            autoCapitalize="none"
            keyboardType="email-address"
            value={lifecycle.email}
            onChangeText={lifecycle.setEmail}
            isDisabled={lifecycle.submitting}
          />

          <Semantics.FormControl
            type="password"
            label={locale('password')}
            placeholder=""
            value={lifecycle.password}
            onChangeText={lifecycle.setPassword}
            isDisabled={lifecycle.submitting}
          />

          <UI.Input
            // This invisible input tricks iOS
            // into not providing the "strong password"
            // auto-fill, which is bugged in iOS 16+
            // TODO: remove this once a better fix with a working "strong password"
            // auto-fill has been found
            borderWidth={0}
            backgroundColor="transparent"
            height={0.01}
          />

          <Semantics.FormControl
            type="password"
            label={locale('passwordConfirm')}
            placeholder=""
            value={lifecycle.passwordConfirm}
            onChangeText={lifecycle.setPasswordConfirm}
            isDisabled={lifecycle.submitting}
          />

          <Semantics.FormControl
            label={locale('accessCode')}
            placeholder=""
            autoComplete="off"
            keyboardType="default"
            value={lifecycle.accessCode}
            onChangeText={lifecycle.setAccessCode}
            isDisabled={lifecycle.submitting}
          />

          <Semantics.Card marginTop={4}>
            <UI.Checkbox
              value="tos"
              aria-label={locale('conditions.label')}
              isChecked={lifecycle.rulesAccepted}
              onChange={() => lifecycle.setRulesAccepted((a) => !a)}
              isDisabled={lifecycle.submitting}
            >
              <UI.Text marginLeft={2} color="black" fontSize={14}>
                {locale('conditions.accept')}{' '}
                <UI.Pressable
                  onPress={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    lifecycle.setShowLicenseAgreement(true)
                  }}
                >
                  <UI.Text
                    color="primary.500"
                    style={fonts.bold}
                    _android={{ mb: -1 }}
                  >
                    {locale('conditions.eula')}
                  </UI.Text>
                </UI.Pressable>{' '}
                {locale('conditions.and')}{' '}
                <UI.Pressable
                  onPress={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    lifecycle.setShowPrivacyRules(true)
                  }}
                >
                  <UI.Text
                    color="primary.500"
                    style={fonts.bold}
                    _android={{ mb: -1 }}
                  >
                    {locale('conditions.privacy')}
                  </UI.Text>
                </UI.Pressable>
              </UI.Text>
            </UI.Checkbox>
          </Semantics.Card>

          {!!lifecycle.error && (
            <UI.Alert status="error" variant="left-accent" marginTop={4}>
              <UI.Text color="red.900">
                {locale(`error.${lifecycle.error}`)}
              </UI.Text>
            </UI.Alert>
          )}

          <Semantics.Button
            onPress={lifecycle.handleSubmit}
            isDisabled={!lifecycle.canSubmit || lifecycle.submitting}
            colorScheme="secondary"
            marginTop={8}
          >
            {locale('confirm')}
          </Semantics.Button>
        </UI.Column>

        <Elements.AlreadyHaveAccount />

        <Semantics.Alert
          isOpen={lifecycle.showLicenseAgreement}
          onAccept={() => lifecycle.setShowLicenseAgreement(false)}
          title={locale('licenseAgreement.title')}
          acceptLabel={locale('licenseAgreement.acceptLabel')}
          fullWidth
        >
          <UI.ScrollView
            width={lifecycle.width * 0.85}
            height={lifecycle.height * 0.75}
            maxHeight={lifecycle.height * 0.75}
            maxWidth={960}
            mx={-4}
            mb={-8}
            pb={8}
          >
            <Semantics.Markdown textAlign="left">
              {Semantics.Localized.localized(lifecycle.legalNotices)}
            </Semantics.Markdown>
          </UI.ScrollView>
        </Semantics.Alert>
        <Semantics.Alert
          isOpen={lifecycle.showPrivacyRules}
          onAccept={() => lifecycle.setShowPrivacyRules(false)}
          title={locale('privacyRules.title')}
          acceptLabel={locale('privacyRules.acceptLabel')}
          fullWidth
        >
          <UI.ScrollView
            width={lifecycle.width * 0.85}
            maxWidth={960}
            height={lifecycle.height * 0.75}
            maxHeight={lifecycle.height * 0.75}
            mx={-4}
            mb={-8}
            pb={8}
          >
            <Semantics.Markdown textAlign="left">
              {Semantics.Localized.localized(lifecycle.privacyPolicy)}
            </Semantics.Markdown>
          </UI.ScrollView>
        </Semantics.Alert>
      </UI.View>
    </UI.ScrollView>
  ))
