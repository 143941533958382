import React from 'react'

import { buildComponent } from 'src/components/factory'

import * as Icons from 'src/components/icons'
import * as Semantics from 'src/components/semantics'

export const BackAction = buildComponent().withRender(() => {
  return (
    <Semantics.LinkButton
      variant="ghost"
      back
      alignItems="center"
      borderLeftRadius={56}
      borderRightRadius={56}
      height={48}
      width={48}
      marginX={2}
    >
      <Icons.FontAwesomeIcon
        size="lg"
        color="white"
        icon={Icons.Solid.faArrowLeft}
      />
    </Semantics.LinkButton>
  )
})
