import React, { useCallback, useEffect, useState } from 'react'
import { useWindowDimensions } from 'react-native'
import { StackActions, useNavigation } from '@react-navigation/native'

import { buildComponent } from 'src/components/factory'

import { useAppearance } from 'src/hooks/use-appearance'
import { useAudioConstraints } from 'src/hooks/use-audio-constraints'
import { useAudioConfiguration } from 'src/hooks/use-audio-configuration'
import { useAudioFile } from 'src/hooks/use-audio-file'
import { useStore, useStoreState } from 'src/hooks/state'
import { useNaps } from 'src/hooks/use-naps'
import { useResponsive } from 'src/hooks/use-responsive'

import { StoreDownload } from 'src/screens/connected/store-download/store-download'

import * as Firestore from 'src/helpers/firestore'

import * as Semantics from 'src/components/semantics'
import * as Components from './components'

export const ScreenConnectedPlayerDownload = buildComponent()
  .withLocale('screens.connected.player-download.screen')
  .withLifecycle(({ locale }) => {
    const Store = useStore()
    const { height } = useWindowDimensions()
    const { nap, valid, wrongLocale, theme, background, book } =
      useAudioConstraints()
    const current = useNaps().current
    const later = !!nap && nap._id !== current?._id
    const active = !!nap && nap._id === current?._id
    const configuration = useAudioConfiguration()
    const file = useAudioFile()
    const navigation = useNavigation()
    const [ignoreLocale, setIgnoreLocale] = useState(false)
    const [ignoreStoreDownload, setIgnoreStoreDownload] = useState(false)
    const isWeb = useResponsive().isWeb
    const isFavorite = useStoreState(
      (store) => !!background && store.audio.isFavorite({ theme, background }),
    )
    const showFavorite = !book

    useAppearance({
      title: active ? locale('startNap') : locale('startAudio'),
      action: active ? Components.NapReadyIcon : 'back',
    })

    const reconfigure = useCallback(() => {
      navigation.dispatch(
        StackActions.replace('/audio', {
          napId: nap?._id,
        }),
      )
    }, [navigation, nap?._id])

    const makeFavorite = () => {
      if (background) {
        Store.getActions().audio.makeFavorite({
          theme,
          background,
        })
      }
    }

    useEffect(() => {
      if (nap && configuration) {
        Firestore.upsertDocument(Firestore.getDocumentReference('naps', true), {
          [`${nap._id}.audio`]: configuration,
        }).catch(console.error)
      }
    }, [nap, configuration])

    useEffect(() => {
      if (!valid) {
        reconfigure()
      }
    }, [valid, reconfigure])

    useEffect(() => {
      if (file.downloaded && !later && !file.downloadError) {
        navigation.dispatch(StackActions.popToTop())
        navigation.dispatch(
          StackActions.push('/player/play', {
            ...configuration,
            files: {
              main: file.mainCache,
              background: file.backgroundCache,
            },
          }),
        )
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file.downloaded, file.downloadError, navigation, later])

    return {
      height,
      later,
      download: file.downloadFiles,
      downloaded: file.mainCached && file.backgroundCached,
      downloading: file.downloading,
      downloadProgress: file.downloadProgress,
      downloadError: file.downloadError,
      active,
      wrongLocale: wrongLocale && !ignoreLocale,
      ignoreLocale: () => setIgnoreLocale(true),
      reconfigure,
      isFavorite,
      showFavorite,
      makeFavorite,
      isWeb: isWeb && !ignoreStoreDownload,
      ignoreStoreDownload: () => setIgnoreStoreDownload(true),
    }
  })
  .withRender(({ lifecycle }) => {
    if (lifecycle.isWeb) {
      return (
        <StoreDownload
          theme="night"
          audio
          continue={lifecycle.ignoreStoreDownload}
        />
      )
    }

    return (
      <Semantics.PaddedScrollView
        centered
        mx={[0, 'auto']}
        width="100%"
        maxWidth={['100%', 600]}
      >
        {lifecycle.downloading && (
          <Components.ViewDownloading progress={lifecycle.downloadProgress} />
        )}

        {!lifecycle.downloading && (
          <>
            <Components.ViewReady
              active={lifecycle.active}
              later={lifecycle.later}
              download={lifecycle.download}
              downloaded={lifecycle.downloaded}
              isFavorite={lifecycle.isFavorite}
              showFavorite={lifecycle.showFavorite}
              makeFavorite={lifecycle.makeFavorite}
            />

            <Components.AlertWrongLocale
              isOpen={lifecycle.wrongLocale}
              onAccept={lifecycle.reconfigure}
              onClose={lifecycle.ignoreLocale}
            />

            <Components.AlertError isOpen={lifecycle.downloadError} />
          </>
        )}
      </Semantics.PaddedScrollView>
    )
  })
