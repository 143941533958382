import React from 'react'
import { useStoreState } from 'src/hooks/state'
import { Store } from 'src/state'

export function Localized(props: {
  text?: string | { fr?: string; en?: string }
  children?: string | { fr?: string; en?: string }
  wrapper?: React.FunctionComponent
}) {
  const locale = useStoreState((store) => store.utils.device.locale)
  const source = props.text ?? props.children
  const value =
    typeof source === 'string' ? source : source?.[locale] ?? source?.fr

  if (!value) {
    return null
  }

  const Wrapper = props.wrapper ?? React.Fragment

  return <Wrapper>{value.replace(/ ([!?:])/, '\u00A0$1')}</Wrapper>
}

Localized.localized = <
  T extends unknown,
  U extends string | { fr?: T; en?: T },
>(
  text: U,
) => {
  const locale = Store.getState().utils.device.locale

  if (typeof text === 'string') {
    return text
  }

  const localized = text?.[locale]
  const fallback = text?.fr

  return localized ?? fallback
}
