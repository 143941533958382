import React from 'react'
import * as UI from 'native-base'

import DropShadow from 'src/resources/backgrounds/drop-shadow-bottom.webp'
import { fonts } from 'src/theme'

import { Card } from './card'
import { Button } from './button'
import { Image } from './image'

export function Alert(
  props: UI.IModalProps & {
    onAccept: () => void
    title: string | React.ReactElement
    cancelLabel?: string
    acceptLabel: string
    acceptColorScheme?: string
    isLoading?: boolean
    customBackground?: React.ReactElement
    fullWidth?: boolean
  },
) {
  const buttonProps = {
    borderTopRadius: 0,
    borderBottomRadius: 0,
    margin: 0,
    flex: 1,
    height: 46,
    isDisabled: props.isLoading,
    _pressed: {
      style: {
        transform: [{ translateY: 0 }],
        height: 48,
        marginBottom: -2,
      },
    },
  }

  return (
    <UI.Modal {...props} animationPreset="slide" data-open={props.isOpen}>
      <UI.Box
        maxWidth={props.fullWidth ? '100%' : ['100%', 400]}
        minWidth={[0, 400]}
        width="100%"
      >
        <Card opaque p={0} marginX={6}>
          <UI.Box py={4} px={8} alignItems="center">
            {props.customBackground && (
              <UI.Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                zIndex={-1}
                borderTopRadius={16}
                overflow="hidden"
              >
                {props.customBackground}
              </UI.Box>
            )}

            {typeof props.title === 'string' ? (
              <UI.Text px={8} style={fonts.bold} textAlign="center">
                {props.title}
              </UI.Text>
            ) : (
              props.title
            )}

            <UI.Box my={4} textAlign="center">
              {props.children}
            </UI.Box>
          </UI.Box>

          <UI.Row>
            {props.cancelLabel && (
              <Button
                {...buttonProps}
                colorScheme="grey"
                onPress={props.onClose}
                borderBottomLeftRadius={16}
              >
                {props.cancelLabel}
              </Button>
            )}
            <Button
              {...buttonProps}
              colorScheme={
                props.acceptColorScheme ||
                (props.cancelLabel ? 'secondary' : 'primary')
              }
              onPress={props.onAccept}
              borderBottomLeftRadius={props.cancelLabel ? 0 : 16}
              borderBottomRightRadius={16}
            >
              {props.acceptLabel}
            </Button>

            <UI.Row
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              pointerEvents="none"
            >
              <Image
                alt=" "
                accessibilityRole="none"
                accessibilityElementsHidden={true}
                source={DropShadow}
                resizeMethod="scale"
                resizeMode="stretch"
                height={4}
                width="100%"
              />
            </UI.Row>
          </UI.Row>
        </Card>
      </UI.Box>
    </UI.Modal>
  )
}
