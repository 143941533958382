import React from 'react'

import LogoWelcome from 'src/resources/images/logo-welcome.webp'
import { buildComponent } from 'src/components/factory'
import { useAppearance } from 'src/hooks/use-appearance'

import * as UI from 'native-base'
import * as Semantics from 'src/components/semantics'

export const ScreenDisconnectedWelcome = buildComponent()
  .withStyles({
    tagLine: {
      main: {
        textShadowColor: 'rgba(0, 0, 0, 0.5)',
        textShadowOffset: { width: -1, height: 1 },
        textShadowRadius: 5,
      },
    },
  })
  .withLocale('screens.disconnected.welcome.screen')
  .withLifecycle(() => {
    useAppearance({
      title: 'Nap&Up',
    })
  })
  .withRender(({ styles, locale }) => (
    <UI.Box flex={1} padding={5} width="100%">
      <UI.View flex={1} mx={[0, 'auto']} width="100%" maxWidth={['100%', 480]}>
        <UI.Column flex={1} justifyContent="center">
          <Semantics.Image
            alt="Nap&Up"
            height={192}
            marginBottom={4}
            resizeMode="contain"
            source={LogoWelcome}
          />

          <UI.Text
            color="white"
            fontSize="xl"
            textAlign="center"
            style={styles.tagLine()}
          >
            #InSiesteWeTrust
          </UI.Text>
        </UI.Column>

        <UI.Column marginBottom={20}>
          <Semantics.LinkButton to="/login/signup" colorScheme="primary">
            {locale('signUp')}
          </Semantics.LinkButton>
          <Semantics.LinkButton to="/login" colorScheme="secondary">
            {locale('logIn')}
          </Semantics.LinkButton>
        </UI.Column>
      </UI.View>
    </UI.Box>
  ))
