import i18next from 'i18next'
import {
  PrefixedLocaleFunction,
  TranslationPrefix,
} from 'src/resources/locale/locale'
import { useStoreState } from './state'

export function createLocaleHook<Prefix extends TranslationPrefix>(
  prefix: Prefix,
): () => PrefixedLocaleFunction<Prefix> {
  return () => {
    const locale = useStoreState((store) => store.utils.device.locale)

    return i18next.getFixedT(locale, undefined, prefix)
  }
}
