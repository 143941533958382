import i18next from 'i18next'

import localeFr from './fr.json'
import localeEn from './en.json'

type DotNotationKeys<T, P extends string = ''> = {
  [key in keyof T]: T[key] extends Record<string, unknown>
    ? DotNotationKeys<
        T[key],
        key extends string ? (P extends '' ? key : `${P}.${key}`) : ''
      >
    : key extends string
    ? P extends ''
      ? key
      : `${P}.${key}`
    : ''
}[keyof T] extends infer U
  ? U
  : never

type DotNotationPrefix<T, P extends string = ''> = {
  [key in keyof T]: T[key] extends Record<string, unknown>
    ?
        | (key extends string ? (P extends '' ? key : `${P}.${key}`) : '')
        | DotNotationPrefix<
            T[key],
            key extends string ? (P extends '' ? key : `${P}.${key}`) : ''
          >
    : never
}[keyof T] extends infer U
  ? U
  : never

type CheckMissingTranslation<T extends never> = [T] extends [never] ? null : T

export type PrefixedBy<Keys extends string, Prefix extends string> = {
  [key in Keys]: key extends `${Prefix}.${infer U}` ? U : never
}[Keys] extends infer U
  ? U
  : never

type EnKeys = DotNotationKeys<typeof localeEn>
type FrKeys = DotNotationKeys<typeof localeFr>
type EnPrefix = DotNotationPrefix<typeof localeEn>
type FrPrefix = DotNotationPrefix<typeof localeFr>

export type TranslationKeys = EnKeys | FrKeys
export type TranslationPrefix = EnPrefix | FrPrefix

export type PrefixedLocaleFunction<Prefix extends TranslationPrefix> = (
  key: PrefixedBy<TranslationKeys, Prefix> extends infer U ? U : never,
  options?: Record<string, unknown>,
) => string

export type MissingFrenchTranslation = CheckMissingTranslation<
  Exclude<EnKeys, FrKeys>
>
export type MissingEnglishTranslation = CheckMissingTranslation<
  Exclude<FrKeys, EnKeys>
>

i18next.init({
  lng: 'fr',
  fallbackLng: 'fr',
  compatibilityJSON: 'v3',
  resources: {
    en: { translation: localeEn },
    fr: { translation: localeFr },
  },
})
